import React from 'react'
import { Checkbox, FormControl, FormControlLabel, Icon, InputLabel, styled, TextField, Radio, RadioGroup } from '@mui/material';
import Text from './Text';
import Button from './Button';

const InputWrapper = styled('div')(() => ({
  display: 'flex',
  position: 'relative',
  '& .MuiFilledInput-root .adornment': {
    backgroundColor: '#00000017',
  }
}))
const StyledIcon = styled('div')(({theme}) => ({
  position: 'absolute',
  right: 0,
  bottom: 0,
  top: 0,
  display: 'flex',
  padding: '0 5px',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.primary.main,
  borderRadius: '0px 3px 3px 0px',
}))
const StyledRadio = styled(FormControlLabel)(({theme}) => ({
  position: 'relative',
  marginLeft: 0,
  marginRight: '8px',
  height: '32px',
  padding: '7px 25px',
  '&:last-of-type': {
    marginRight: 0
  },
  '& .MuiRadio-root': {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: '#fff',
    borderColor: theme.palette.primary.main,
    borderWidth: '1px',
    borderStyle: 'solid',
    color: '#808183',
    margin: 0,
    borderRadius: '20px'
  },
  '& .MuiRadio-root span': {
    opacity: 0
  },
  '& .MuiFormControlLabel-label': {
    color: '#808183',
    fontFamily: "'Montserrat', sans-serif",
    zIndex: 2
  },
  '& .MuiRadio-root.Mui-checked ': {
    backgroundColor: theme.palette.primary.main,
  },
  '& .MuiRadio-root.Mui-checked + .MuiFormControlLabel-label': {
    color: '#FFF'
  }
}))

const CustomCheckbox = (props) => {
  return (
    <Checkbox
      {...props}
      sx={{
        color: '#C9C9C9',
        padding: '9px'
      }}
      icon={<Icon baseClassName='fa-light' className='fa-square' />}
    />
  )
}

export default function Input({type, input, label, icon, iconClick, handleChange, value, ...props}) {
  if (type === 'Checkbox') {
    return (
      <FormControlLabel
        control={<CustomCheckbox onChange={iconClick} name={props.id} checked={input.isSelected} value={input.selectedValue || input.portfolioId} />}
        label={<Text fontFamily='ff1' fontSize='1rem' fontWeight='400' {...props.labelStyle}>{input.name}</Text>}
        sx={{marginBottom: 0, marginLeft: 0, alignItems: props.id === 'CASLoptin' ? 'flex-start' : 'center'}}
      />
    )
  }
  else if (type === 'Radio') {
    return (
      <FormControl component='fieldset'>
        <RadioGroup value={value} aria-label={props.aria} name={props.name} onChange={handleChange} sx={{flexDirection: 'row'}}>
          {props.options.map((option, index) => (
            <StyledRadio key={index} disabled={option.disabled} value={option.value} control={<Radio />} label={option.label} />
          ))}
        </RadioGroup>
      </FormControl>
    )
  }
  else {
    return (
      <FormControl fullWidth>
        {label && (
          <InputLabel><Text fontFamily='ff1' fontWeight={600} fontSize='1rem'>{label}</Text></InputLabel>
        )}
        <InputWrapper>
          <TextField
            type={type}
            value={value}
            variant='filled'
            size='small'
            {...props}
            sx={{"& .MuiFilledInput-root": {
              paddingTop: '5px',
              paddingBottom: '5px !important',
              backgroundColor: '#F3F3F3 !important',
              '&:hover': {
                backgroundColor: '#F3F3F3',
              }
            }}}
          />
          {icon && (
            <StyledIcon className='adornment'>
              <Button
                type='icon'
                iconProps={{
                  baseClassName: icon.base,
                  className: icon.class,
                  sx: {
                    fontSize: '1.125rem',
                    paddingTop: '1px',
                    color: 'white',
                    paddingLeft: '1px' }
                  }}
                handleClick={iconClick}
              />
            </StyledIcon>
          )}
        </InputWrapper>
      </FormControl>
    )
  }
}
