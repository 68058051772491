import { Icon } from '@mui/material'
import React from 'react'

export default function Loading() {
  return (
    <div className='loading-spinner-container'>
        <Icon baseClassName='fa-duotone' className='fa-spinner-third loading-spinner' />
    </div>
  )
}
