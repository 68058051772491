import React from 'react';
import { styled, Table as MUITable, TableBody, TableCell, TableContainer, TableHead, TableRow, Icon } from '@mui/material';
import Text from '../../atoms/Text';
import Link from '../../atoms/Link';

const StyledHeader = styled(TableHead)(() => ({
    '& th': {
      backgroundColor: '#E6E7E7',
      textTransform: 'uppercase',
    },
    '& tr.title th': {
      backgroundColor: '#AB9F60',
      textTransform: 'unset'
    },
    '&.two-cols th': {
      width: '50%'
    }
  }));

const StyledTableCell = styled(TableCell)(() => ({
    border: 'none',
    borderBottom: '1px solid #E6E7E8',
    padding: '12px 21px',
    verticalAlign: 'bottom'
}))

const StyledHeaderText = ({children}) => {
    return (
        <Text color='#808183' fontSize='1rem' fontWeight={700} fontFamily="ff1">{children}</Text>
    )
}

export default function Personnel({table}) {
    
    return (
        <TableContainer>
            <MUITable>
                <StyledHeader>
                <TableRow>
                    <TableCell>
                        <StyledHeaderText>Name</StyledHeaderText>
                    </TableCell>
                    <TableCell>
                        <StyledHeaderText>Job Title </StyledHeaderText>
                    </TableCell>
                    <TableCell>
                        <StyledHeaderText>Contact</StyledHeaderText>
                    </TableCell>
                </TableRow>
                </StyledHeader>
                <TableBody>
                    {table.map((row, index) => (
                    <TableRow key={`row-${index}`}>
                        <StyledTableCell key={`cell-1`}>
                            <Text>{row.fullName}</Text>
                        </StyledTableCell>
                        <StyledTableCell key={`cell-2`}>
                            <Text>{row.jobTitle}</Text>
                        </StyledTableCell>
                        <StyledTableCell key={`cell-3`}>
                            <div className='contact-icons'>
                                {row.linkedIn &&
                                    <Link type='icon' external openTab link={row.linkedIn}>
                                        <Icon sx={{color: '#2D70DA', fontSize: '24px !important'}} baseClassName='fa-brands' className='fa-linkedin' />
                                    </Link>
                                }
                                {row.email &&
                                    <Link type='icon' external openTab link={`mailto: ${row.email}`}>
                                        <Icon sx={{color: '#2D70DA', fontSize: '24px !important'}} baseClassName='fa-regular' className='fa-envelope' />
                                    </Link>
                                }
                            </div>
                        </StyledTableCell>
                    </TableRow>
                    ))}
                </TableBody>
            </MUITable>
        </TableContainer>
    )
}
