export const portfolioReducer = (state, action) => {
    switch(action.type) {
        case "SET_PORTFOLIO": 
            return {
                ...state,
                portfolio: action.payload
            }
        case "SET_COMPANY_LIST": 
            return {
                ...state,
                portfolio: {
                    ...state.portfolio,
                    savedCompanyList: action.payload
                }
            }
        case "SET_RESULTS_Folder":
            return {
                ...state,
                resultsFolder: action.payload
            }
        case "SET_RESULTS_Folder_Property":
            return {
                ...state,
                resultsFolderProperty: action.payload
            }
        case "SET_RESULTS_Folder_contact":
            return {
                ...state,
                resultsFolderContact: action.payload
            }
        case "SET_PROPERTY_LIST":
            return {
                ...state,
                portfolio: {
                    ...state.portfolio,
                    savedPropertyList: action.payload
                }
            }
        default:
            return state;
    }
}