export const formatFinancialTable = data => {

    const financialGroups = data.map(({financialGroup}) => financialGroup)  
    const filteredFG = financialGroups.filter((item, index) => financialGroups.indexOf(item) === index);
    
    const headers = [
        {title: "", key: "performanceType"},
        {title: "", key: 'lastReportedYear'},
        {title: "", key: 'previousReportedYear'}
    ];

    const itemsFG = filteredFG.map(fg => {
        return data.filter(item => item.financialGroup === fg);
    })

    let content = [];
    itemsFG.map(fg => {
        content.push({
            data: {
                performanceType: fg[0]['financialGroup'],
                lastReportedYear: fg[0]['lastReportedYear'],
                previousReportedYear: fg[0]['previousReportedYear'],
            },
            subheader: true
        });

        fg.map(item => {
            content.push({
                data: {
                    performanceType:
                        (item['performanceType'] !== null && item['performanceType'] !== 99999999999999.9) ?
                        item['performanceType'] :
                        '-',
                    lastReportedYear:
                        (item['lastReportedYearValue'] !== null && item['lastReportedYearValue'] !== 99999999999999.9) ?
                        item['lastReportedYearValue'] :
                        '-',
                    previousReportedYear: 
                        (item['previousReportedYearValue'] !== null && item['previousReportedYearValue'] !== 99999999999999.9) ?
                        item['previousReportedYearValue'] :
                        '-',
                },
                subheader: false
            })
            return true;
        })
        return true;
    })

    return { table:{ headers: headers, content: content } };
}

export const formatTable = (tableData, format) => {
    const data = tableData.map(result => {
      const structured = Object.fromEntries(Object.entries(result)
        .filter(([key]) => !key.includes('Id')));
        return structured
    });

    let headers;
    let content = data;

    if (format === 'reserves') {
        headers = [
            {
                title: 'Resource',
                key: 'resource'
            }, {
                title: 'P&P',
                key: 'ppValue'
            }, {
                title: 'M&I',
                key: 'miValue'
            }
        ];

        content = data.map(item => {
            return {
                resource: `${item.resource} (${item.ppUnit})`,
                ppValue: item.ppValue !== null ? Math.round(item.ppValue * 100) / 100 : null,
                miValue: item.miValue !== null ? Math.round(item.miValue * 100) / 100 : null
            }
        })
    }

    else {
        headers = Object.keys(data[0])
        .map(header => {
            let value = header;
            let headerObject = {};
          
            if (header.includes('Name')) {
                value = header.split('Name')[0];
            }
            value = value.split(/(?=[A-Z])/).join(' ');
    
            headerObject.title = value;
            headerObject.key = header;
    
            return headerObject;
        });
    }
    

    return { table:{ headers: headers, content: content } };
}