import React, { useEffect } from 'react';

import { useApp } from '../AppContext';
import useDocumentTitle from '../components/features/hooks/useDocumentTitle';
import { useProperty } from '../components/features/property/context';

import PropertyFilter from '../components/UI/features/filters/PropertyFilter';

import Loading from '../components/UI/atoms/Loading';
import Listings from '../components/UI/organisms/Listings';

import { emptyObject } from '../lib/emptyObject';

export default function PropertyListing() {
  const {
    query,
    filters,
    results,
    resultsFolder,
    setFilters,
    searchType,
    handleSearch,
    totalResults,
    selectedFilters,
    removeFilter,
    handlePageSize,
    handlePageNumber,
    handleSelectProperty,
    handleSearchSorted,
    handlePortfolioOnSearch,
    setFolders
  } = useProperty();
  const { isLoading } = useApp();

  useDocumentTitle('Property Search | TNM Marco Polo');

  useEffect(() => {
    if (Object.keys(filters).length === 0) {
      (async () => {
          setFilters();
          setFolders();
      })();
    }
    // eslint-disable-next-line
  }, [filters]);

  useEffect(() => {
      (async () => {
          handleSearch();
      })();

    // eslint-disable-next-line
  }, [searchType]);

  return (
    <div>
      {!emptyObject(filters) && (
        <PropertyFilter />
      )}
      { isLoading ? 
        <Loading />
      :
        <Listings
          sortSelected={query.sorting}
          addToPortfolio={handlePortfolioOnSearch}
          handleSort={handleSearchSorted}
          results={results}
          resultsFolder={resultsFolder}
          type='properties'
          searchType='properties'
          select={handleSelectProperty}
          handlePage={handlePageSize}
          pageSize={query.pageSize}
          filters={selectedFilters}
          handleDelete={removeFilter}
          pageNumber={query.pageNumber}
          handlePageNumber={handlePageNumber}
          totalResults={totalResults}
        />
      }
    </div>
  )
}
