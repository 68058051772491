import { getPeopleAutocomplete } from '../../../services/getPeopleAutocomplete';
import { getPeopleFilters } from '../../../services/getPeopleFilters';
import { postPeopleList } from '../../../services/postPeopleList';
import { getCompanyFolders } from "../../../services/getCompanyFolders"

export const serviceGetFilters = () => {
    return getPeopleFilters();
}

export const servicePostList = query => {
    return postPeopleList(query);
}

export const servicePeopleAutocomplete = name => {
    return getPeopleAutocomplete(name);
}

export const serviceGetFolders = (EntityId) => {
    return getCompanyFolders(EntityId);
}