import React from 'react';
import { Icon, styled, Table as MUITable, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Text from './Text';
import { formatTable } from '../../../lib/formatTable';
import { labels } from '../../../data/tableLabel.json';

const StyledHeader = styled(TableHead)(() => ({
  '& th': {
    backgroundColor: '#E6E7E7',
    textTransform: 'uppercase',
  },
  '& tr.title th': {
    backgroundColor: '#AB9F60',
    textTransform: 'unset'
  },
  '&.two-cols th': {
    width: '50%'
  }
}));

const StyledCell = styled(TableCell)(() => ({
  border: '1px solid #E6E7E8',
  '&.odd-label': {
    borderRight: 'none'
  },
  '&.odd-content': {
    borderLeft: 'none'
  }
}));

const StyledHeaderCell = styled(TableCell)(() => ({
  backgroundColor: '#E6E7E8',
  textTransform: 'uppercase',
  width: '25%',
  '& p': {
    textAlign: 'right'
  },
  '&:first-of-type': {
    width: 'auto'
  },
  '&:first-of-type p': {
    textAlign: 'left',
  }
}))

const StyledCellContent = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between'
}));

const StyledCaption = styled('caption')(({theme}) => ({
  backgroundColor: theme.palette.primary.main,
  color: '#fff !important',
  textTransform: 'uppercase',
  captionSide: 'top !important'
}))

const TitleCells = (headers) => {
  let titleCells = [];
  for (let cell = 1; cell < headers.length; cell++) {
    titleCells.push(<StyledCell key={`title-cell-${cell}`} />);
  }
  return titleCells;
}

export default function Table({table, borders, header = true, type, headerText, caption, format }, props) {

  if (type === 'financial') {
    return (
      <TableContainer>
        <MUITable>
          <StyledCaption><Text fontSize='1rem' fontWeight='500'>{caption}</Text></StyledCaption>
          <TableBody>
            {table.table.content.map((row, index) => (
              <TableRow key={`row-${index}`}>
                {Object.entries(row.data).map((item, i) => {
                  const currentKey = table.table.headers[i].key;
                  const value = row['data'][currentKey];
                  if (row.subheader) 
                  return (
                    <StyledHeaderCell variant='head' component='th' key={`cell-${index}${i}`} className='header' sx={{border: '1px solid #E6E7E8'}}>
                      <Text fontFamily='ff1' color='#808183' fontSize='1rem' fontWeight='700'>{value}</Text>
                    </StyledHeaderCell>
                  )
                  else return (
                  <TableCell key={`cell-${index}${i}`} className='borders' sx={{border: '1px solid #E6E7E8'}}>
                    {typeof value === 'number' ? (
                      <Text textAlign='right' fontFamily='ff2' color='#1E2326'>{(Math.round(value * 100) / 100).toLocaleString('en-US')}</Text>
                      ) : (
                        <Text fontFamily='ff2' textAlign={value === '-' ? 'right' : 'left'} color='#1E2326' fontSize='1rem'>{value}</Text>
                      )}
                  </TableCell>
                )})}
              </TableRow>
            ))}
          </TableBody>
        </MUITable>
      </TableContainer>
    )
  }
  else if (type === 'stock') {
    return (
      <TableContainer>
        <MUITable>
          {headerText && (
            <StyledHeader className='two-cols'>
              <TableRow>
                <StyledCell>
                  <Text fontFamily='ff1' color='#808183' fontSize='1rem' fontWeight='700'>{headerText}</Text>
                </StyledCell>
                {TitleCells(Object.keys(table[0]))}
              </TableRow>
            </StyledHeader>
          )}
          <TableBody>
            {table.map((row, index) => {
              const lastRowIndex = table.length - 1;
              const lastRow = table[lastRowIndex];
              const lastRowItems = lastRow.length;
              if (lastRowIndex === index && lastRowItems % 2 !== 0)
                return (
                  <TableRow key={`row-${index}`}>
                    {Object.entries(lastRow[0]).map(([k,v]) => (
                      <React.Fragment key='last-i'>
                        <StyledCell className='odd-label'>
                          <Text fontFamily='ff2' color='#808183' fontSize='1rem' fontWeight='500'>{labels[k]}</Text>  
                        </StyledCell>
                        <StyledCell className='odd-content'>
                          <Text fontFamily='ff2' color='#1E2326' fontSize='1rem' fontWeight='500' textAlign='right'>{v}</Text>  
                        </StyledCell>
                      </React.Fragment>
                    ))}
                  </TableRow>
                )
              else return (
              <TableRow key={`row-${index}`}>
                {row.map((cell, i) => (
                  <StyledCell key={`cell-${index}${i}`} className='borders'>
                    {Object.entries(cell).map(([k,v]) => (
                      <StyledCellContent key={`text-${k}`}>
                        <Text fontFamily='ff2' color='#808183' fontSize='1rem' fontWeight='500'>{labels[k]}</Text>
                        <Text fontFamily='ff2' color='#1E2326' fontSize='1rem' fontWeight='500'>{v}</Text>
                      </StyledCellContent>
                    ))}
                  </StyledCell>
                ))}
              </TableRow>
              )
            })}
          </TableBody>
        </MUITable>
      </TableContainer>
    )
  }
  else {
    const data = formatTable(table, format);
    return (
      <TableContainer>
        <MUITable>
          {header && (
            <StyledHeader>
              {data.title && (
                <TableRow className='title'>
                  <TableCell sx={{padding: '12px 21px'}}>
                    <Text fontFamily='ff1' color='#FFFFFF' fontSize='1rem' fontWeight='700'>{data.title}</Text>
                  </TableCell>
                  {TitleCells(data.table.headers)}
                </TableRow>
              )}
              <TableRow>
                {data.table.headers.map(header => (
                  <TableCell key={`header-${header.key}`} sx={{width: 100 / data.table.headers.length}}>
                    <Text fontFamily='ff1' color='#808183' fontSize='1rem' fontWeight='700'>{header.title}</Text>
                  </TableCell>
                ))}
              </TableRow>
            </StyledHeader>
          )}
          <TableBody>
            {data.table.content.map((row, index) => (
              <TableRow key={`row-${index}`}>
                {Object.entries(row).map((item, i) => {
                  const currentKey = data.table.headers[i].key;
                  const value = row[currentKey];
                  return (
                  <TableCell key={`cell-${index}${i}`} className='borders' sx={{border: borders ? '1px solid #E6E7E8' : 'none', borderBottom: '1px solid #E6E7E8', padding: '12px 21px'}}>
                    {typeof value === 'number' ? (
                      <Text className='format-number'>{value}</Text>
                      ) : currentKey ==='rowTitle' ? (
                        <Text fontFamily='ff2' color='#808183' fontSize='1rem'>{value}</Text>
                      ) : typeof value === 'boolean' ? (
                        <React.Fragment>
                          {value && <Icon baseClassName='fa-solid' className='fa-check' />}
                        </React.Fragment>
                      ) : (
                        <Text fontFamily='ff2'>{value}</Text>
                      )}
                  </TableCell>
                )})}
              </TableRow>
            ))}
          </TableBody>
        </MUITable>
      </TableContainer>
    )
  }
}
