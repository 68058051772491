import React, { useState } from "react";
import { authPost } from '../services/authMiddleware';
import Text from "../components/UI/atoms/Text";
import Card from "../components/UI/molecules/Card";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
function SuggestanEdit() {

    const [firstname, setCFirstName] = useState("");
    const [lastname, setCLastName] = useState("");
    const [email, setCEmail] = useState("");
    const [suggestion, setCSuggestion] = useState("SUGGESTION OR EDIT FOR");
    const [message, setCMessage] = useState("");
    const [errorCMessage, setCErrorMessage] = useState("");
    const [successCMessage, setCSuccessMessage] = useState("");

    const isValidEmail = (email) => {
        return /\S+@\S+\.\S+/.test(email);
    }

    const handleSubmitContactForm = async (event) => {
        event.preventDefault();
        setCErrorMessage("");
        setCSuccessMessage("");

        var subject = "Suggection or Edit - Marcopolo";

        if (firstname == "") {
            setCSuccessMessage("");
            setCErrorMessage("All fields are required with '*'.");
        }
        else if (lastname == "") {
            setCSuccessMessage("");
            setCErrorMessage("All fields are required with '*'.");
        }
        else if (email == "") {
            setCSuccessMessage("");
            setCErrorMessage("All fields are required with '*'.");
        }
        else if (!isValidEmail(email)) {
            setCSuccessMessage("");
            setCErrorMessage("Invalid email address");
        }
        else if (suggestion == "" || suggestion == "SUGGESTION OR EDIT FOR") {
            setCSuccessMessage("");
            setCErrorMessage("All fields are required with '*'.");
        }
        else if (message == "") {
            setCSuccessMessage("");
            setCErrorMessage("All fields are required with '*'.");
        }
        else {
            const response = await authPost('/api/company/contact-form', {
                subject,
                firstname,
                lastname,
                email,
                suggestion,
                message
            });

            if (response.status === 200) {
                setCErrorMessage("");
                setCSuccessMessage(response.message);
                setCFirstName("");
                setCLastName("");
                setCEmail("");
                setCSuggestion("SUGGESTION OR EDIT FOR");
                setCMessage("");
                setCSuccessMessage("Thank you for your feedback.");
            }
            else {
                setCSuccessMessage("");
                setCErrorMessage(response.message);
            }
            
        }
    }

  return (
    <>
      <Card>
        <Text fontFamily="ff1" fontSize="2.25rem" fontWeight="500">
          Contact us 
        </Text>
      </Card>
      
          <Card>
              <h3 style={{ marginBottom:'25px' }}>
                  Have questions about our products? We’re here to help you. Let’s talk.
              </h3>
              <Grid container spacing={2} component="form" noValidate onSubmit={handleSubmitContactForm}>
          <Grid item xs={12} md={6}>
            <input
              placeholder="FIRST NAME*"
              value={firstname}
              className="formControl"
              name="FIRST_NAME"
              autoComplete="FIRST_NAME"
              onChange={e => setCFirstName(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <input
              placeholder="LAST NAME*"
              value={lastname}
              className="formControl"
              name="LAST_NAME"
              autoComplete="LAST_NAME"
              onChange={e => setCLastName(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <input
              placeholder="EMAIL*"
              value={email}
              className="formControl"
              name="EMAIL"
              autoComplete="EMAIL"
              onChange={e => setCEmail(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={suggestion}
                          name="SUGGESTION_FOR"
                          className="formControl insightselect"
                          onChange={e => setCSuggestion(e.target.value)}
                          style={{paddingLeft:'0'} }
            >
              <MenuItem value="SUGGESTION OR EDIT FOR">
                SUGGESTION OR EDIT FOR*
              </MenuItem>
              <MenuItem value="Companies">Companies</MenuItem>
              <MenuItem value="Properties">Properties</MenuItem>
              <MenuItem value="Personnel ">Personnel</MenuItem>
              <MenuItem value="Other ">Other</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12}>
            <textarea
              label="MESSAGE"
              value={message}
              placeholder="MESSAGE*"
              className="formControl"
              name="MESSAGE"
              rows={5}
              onChange={e => setCMessage(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12}>
                      {errorCMessage && <Text type='small' id='errormsg' > <span className="errormessage" >{errorCMessage}</span> </Text>}
                      {successCMessage && <Text type='small' id='successmsg' ><span className="successmessage" > {successCMessage} </span></Text>}
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              disableElevation
              variant="contained"
              className="btn-gradient"
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Card>
    </>
  );
}

export default SuggestanEdit;
