import React, { useEffect, useState } from 'react'
import { Grid, Stack, Divider, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';
import { useParams } from 'react-router';

import { useApp } from '../AppContext';
import { useCompany } from '../components/features/company/context';
import useDocumentTitle from '../components/features/hooks/useDocumentTitle';
import { useProperty } from '../components/features/property/context';

import Link from '../components/UI/atoms/Link';
import Button from '../components/UI/atoms/Button';
import Loading from '../components/UI/atoms/Loading';
import RenderHtml from '../components/UI/atoms/RenderHtml';
import Text from '../components/UI/atoms/Text';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Card from '../components/UI/molecules/Card';
import List from '../components/UI/molecules/List';

import NewsReleases from '../components/UI/features/NewsReleases';
import { ComplexOwnership, Personnel, ReservesTable, SingleTables } from '../components/UI/features/propertySections';

import { emptyObject } from '../lib/emptyObject';
import { filterProduction } from '../lib/filterProduction';
import { mineComplexOwnership, propertiesMineComplex } from '../lib/formatMineComplex';
import { hasMineOffice } from '../lib/hasMineOffice';

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

const StyledLabel = ({ children }) => {
  return (
    <Text fontFamily='ff1' fontSize='1rem' fontWeight='500' textTransform='uppercase'>{children}</Text>
  )
}

const StyledInfo = ({ children }) => {
  return (
    <Text fontFamily='ff2' fontSize='0.875rem' color='#808183'>{children}</Text>
  )
}

export default function PropertyProfile() {
    const { handleProfile, profile, handleAddToPortfolio, setFolders, resultsFolder } = useProperty();
  const { handleSelectCompany } = useCompany();
  const { isLoading } = useApp();
  const { propertyId } = useParams();
  const [pageTitle, setPageTitle] = useState('TNM Marco Polo');

    const [opendi, setOpen] = React.useState(false);
    const [mfolderid, setmFolderId] = React.useState("");
    const [mprofileLink, setmProfileLink] = React.useState("");
    const [misPortfolioItem, setmIsPortfolio] = React.useState("");

  const theme = useTheme();
  const orientation = useMediaQuery(theme.breakpoints.down("md")) ? "horizontal" : "vertical";

  useEffect(() => {
    (async () => {
      handleProfile(propertyId);
      setFolders(2);
    })();
    // eslint-disable-next-line
  }, [propertyId]);

  useEffect(() => {
    if (!emptyObject(profile)) {
      setPageTitle(`${profile.displayName} | TNM Marco Polo`);
    }
  }, [profile]);

  useDocumentTitle(pageTitle);

  const generalProfile = { ...profile.keyFacts };
  delete generalProfile['activityStatus'];

    const handleClickOpen = async (mprofileLinkk, misPortfolioItemm) => {
        setmProfileLink(mprofileLinkk);
        setmIsPortfolio(misPortfolioItemm);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleAddToPortfolionew = () => {
        handleAddToPortfolio(mfolderid, mprofileLink, misPortfolioItem);
        handleClose();
    }

  const handlePrint = () => {
    window.print();
  }

  const handleReportChange = (url) => {
    const newTab = window.open(url, '_blank', 'noopener,noreferrer');
    if (newTab) newTab.opener = null;
  }
  
  return (
    <div className='profile property-profile print'>
      {(emptyObject(profile) || isLoading) ? (
        <Loading />
      ) : (
        <React.Fragment>
          <Card styles={{ '& .MuiCardContent-root': { display: 'flex', justifyContent: 'space-between' } }} className='title'>
            <Text fontFamily='ff1' fontSize='2.25rem' fontWeight='500'>{profile.displayName}</Text>
            <div className="profile-actions">
              {profile.isPortfolioItem ? (
                <Button type='iconText' iconProps={{ baseClassName: 'fa-solid', className: 'fa-bookmark' }} disabled>Added to my portfolio</Button>
              ) : (
                <Button handleClick={() => handleClickOpen(propertyId, profile.isPortfolioItem)} type='iconText' iconProps={{ baseClassName: 'fa-regular', className: 'fa-bookmark' }}>Add to my portfolio</Button>
              )}
              <Button handleClick={handlePrint} type='iconText' iconProps={{ baseClassName: 'fa-regular', className: 'fa-print' }}>Print</Button>
              <Button handleClick={() => handleReportChange("/contactus")} type='iconText' iconProps={{ baseClassName: 'fa-solid', className: 'fa-pencil' }}>Suggest an Edit</Button>
            </div>
          </Card>

            {
                resultsFolder &&
                <Dialog open={opendi} onClose={handleClose}>
                    <DialogTitle>Select Property List</DialogTitle>
                    <DialogContent>
                        <FormControl>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                            >
                                {resultsFolder.map((element, index) => {
                                    return (
                                        <FormControlLabel key={`element-${index}`} value={element.folderId} control={< Radio />} label={element.folderName} onClick={(e) => setmFolderId(e.target.value)} />
                                    )
                                })}
                            </RadioGroup>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button onClick={() => handleAddToPortfolionew()}>Save</Button>
                    </DialogActions>
                </Dialog>
            }

          <Stack direction={{ xs: 'column', sm: 'column', md: 'row' }} spacing={3}>
            <Card header='Key facts' styles={{ width: '100%', margin: 0, flex: { md: '0 0 60%' }, maxWidth: { md: '60%' } }}>
              <Grid container spacing={3}>
                <Grid item container spacing={3} xs={12} sm={6}>
                  <Grid item xs={12}>
                    <StyledLabel>Asset type</StyledLabel>
                    <StyledInfo>{profile.keyFacts.assetType}</StyledInfo>
                  </Grid>
                  <Grid item xs={12}>
                    <StyledLabel>Work type</StyledLabel>
                    <StyledInfo>{profile.operations.workType}</StyledInfo>
                  </Grid>
                  <Grid item xs={12}>
                    <StyledLabel>Development status</StyledLabel>
                    <StyledInfo>{profile.keyFacts.developmentStatus}</StyledInfo>
                  </Grid>
                </Grid>
                <Grid item container spacing={3} xs={12} sm={6}>
                  <Grid item xs={12}>
                    <StyledLabel>Location</StyledLabel>
                    <StyledInfo>{profile.keyFacts.location}</StyledInfo>
                  </Grid>
                  <Grid item xs={12}>
                    <StyledLabel>Lat / Long</StyledLabel>
                    <StyledInfo>{profile.keyFacts.latLong}</StyledInfo>
                  </Grid>
                  <Grid item xs={12}>
                    <StyledLabel>Nearest Landmark</StyledLabel>
                    <StyledInfo>{profile.keyFacts.nearestLandmark}</StyledInfo>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <StyledLabel>Commodities</StyledLabel>
                  <StyledInfo>{profile.keyFacts.commodities.replace(/,/g, ', ')}</StyledInfo>
                </Grid>
                <Grid item xs={12}>
                  <StyledLabel>Primary commodities</StyledLabel>
                  <StyledInfo>{profile.keyFacts.primaryCommodity}</StyledInfo>
                </Grid>
              </Grid>
            </Card>

            <Card header='Operations' style={{ width: '100%', margin: 0 }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <StyledLabel>Mine technology</StyledLabel>
                  {!!profile.operations.mineTechnology && (
                    profile.operations.mineTechnology.map(item => (
                      <StyledInfo key={`technology-${item}`}>{item}</StyledInfo>
                    ))
                  )}
                </Grid>
                <Grid item xs={12}>
                  <StyledLabel>Processing technology</StyledLabel>
                  {!!profile.operations.processingTechnology && (
                    profile.operations.processingTechnology.map(item => (
                      <StyledInfo key={`technology-${item}`}>{item}</StyledInfo>
                    ))
                  )}
                </Grid>
              </Grid>
            </Card>
          </Stack>

          {profile.propertyTypeId === 1 &&
            <Card header='Properties In Mine Complex' padded={false}>
              <List
                list={propertiesMineComplex(profile.children)}
                type='property'
                page='properties'
                removebookmark={true}
              />
            </Card>
          }

          {profile.propertyTypeId === 2 &&
            <Card header='Mine Complex Ownership' padded={false}>
              <ComplexOwnership
                content={mineComplexOwnership(profile.parent)}
              />
            </Card>
          }
          <Accordion className='mt-4'>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography><b>OWNERSHIP AND OPTIONS</b></Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                {(profile.ownershipAndOptionList.length !== 0 && profile.propertyId !== 2) &&
                  <Card  padded={false}>
                    <List
                      list={profile.ownershipAndOptionList}
                      type='company'
                      page='company'
                      select={handleSelectCompany}
                      removebookmark={true}
                    />
                  </Card>
                }
              </Typography>
            </AccordionDetails>
          </Accordion>


          <Stack direction={{ xs: 'column', sm: 'column', md: 'row' }} spacing={3}>
            {!!profile.reservesResources.length && (
              <Card
                header='Reserves/Resources'
                padded={false}
                styles={{
                  width: '100%',
                  margin: 0,
                  flex: { md: '0 0 60%' },
                  maxWidth: { md: '60%' }
                }}
              >
                <ReservesTable table={profile.reservesResources[0]} />
              </Card>
            )}

            {!!profile.productions.length && (
              <Card header='Production' padded={false} styles={{ width: '100%', margin: 0 }}>
                <SingleTables tables={filterProduction(profile.productions)} />
              </Card>
            )}
          </Stack>
          <Accordion className='mt-4'>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography><b>CONTACT INFORMATION</b></Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <Card>
                  <Stack direction={{ xs: 'column', sm: 'column', md: 'row' }} spacing={3} divider={<Divider orientation={orientation} flexItem />}>
                    {hasMineOffice(profile.keyFacts.developmentStatus) && (
                      <Grid container spacing={3} sx={{ marginTop: 0 }}>
                        <Grid item xs={12} sm={6} sx={{ paddingTop: { xs: '0 !important', sm: '0 !important', md: '24px' } }}>
                          <StyledLabel>Mine office</StyledLabel>
                          <StyledInfo>{profile.contactInformation.mineOffice}</StyledInfo>
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{ paddingTop: { sm: '0 !important', md: '24px' } }}>
                          <StyledLabel>Phone</StyledLabel>
                          <Link external link={`tel:${profile.contactInformation.mineOfficePhone}`} type='table contact'>{profile.contactInformation.mineOfficePhone}</Link>

                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <StyledLabel>Mine office location</StyledLabel>
                          <StyledInfo>
                            <RenderHtml string={profile.contactInformation.mineOfficeLocation} />
                          </StyledInfo>
                        </Grid>
                      </Grid>
                    )}

                    <Grid
                      container
                      spacing={3}
                      sx={{
                        marginLeft:
                          hasMineOffice(profile.keyFacts.developmentStatus) ? ({
                            xs: '-24px !important', sm: '-24px !important', md: '0 !important'
                          }) : ({
                            xs: '-24px !important', sm: '-24px !important', md: '-24 !important'
                          }),
                        marginTop:
                          !hasMineOffice(profile.keyFacts.developmentStatus) && 0
                      }}
                    >
                      <Grid item xs={12} sm={6} md={hasMineOffice(profile.keyFacts.developmentStatus) ? 6 : 3} sx={{ paddingTop: { xs: '0 !important', sm: '0 !important', md: '24px' } }}>
                        <StyledLabel>Owner or Operator</StyledLabel>
                        <StyledInfo>{profile.contactInformation.ownerOrOperator}</StyledInfo>
                      </Grid>
                      <Grid item xs={12} sm={6} md={hasMineOffice(profile.keyFacts.developmentStatus) ? 6 : 9} sx={{ paddingTop: { sm: '0 !important', md: '24px' } }}>
                        <StyledLabel>Web</StyledLabel>
                        {profile.contactInformation.website && (
                          <Link external link={profile.contactInformation.website} openTab type='table contact'>{profile.contactInformation.website}</Link>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6} md={hasMineOffice(profile.keyFacts.developmentStatus) ? 6 : 3}>
                        <StyledLabel>HQ Location / Mailing</StyledLabel>
                        <StyledInfo>
                          <RenderHtml string={profile.contactInformation.hqMailingLocation} />
                        </StyledInfo>
                      </Grid>
                      <Grid item xs={12} sm={6} md={hasMineOffice(profile.keyFacts.developmentStatus) ? 6 : 3}>
                        <StyledLabel>Phone</StyledLabel>
                        {profile.contactInformation.phone && (
                          <Link external link={`tel:${profile.contactInformation.phone}`} type='table contact'>{profile.contactInformation.phone}</Link>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6} md={hasMineOffice(profile.keyFacts.developmentStatus) ? 6 : 3}>
                        <StyledLabel>Email</StyledLabel>
                        {profile.contactInformation.email && (
                          <Link external link={`mailto:${profile.contactInformation.email}`} type='table contact'>{profile.contactInformation.email}</Link>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6} md={hasMineOffice(profile.keyFacts.developmentStatus) ? 6 : 3}>
                        <StyledLabel>Fax</StyledLabel>
                        {profile.contactInformation.fax && (
                          <StyledInfo>{profile.contactInformation.fax}</StyledInfo>
                        )}
                      </Grid>
                    </Grid>
                  </Stack>
                </Card>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion className='mt-4'>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography><b> NEWS RELEASES</b></Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
              {profile.latestInformation &&
            <Card  padded={false} styles={{ width: '100%', margin: 0 }}>
              <NewsReleases list={profile.latestInformation} />
            </Card>
          }
              </Typography>
            </AccordionDetails>
          </Accordion>
          {!!profile.personnel.length &&
            <Card padded={false}>
              <Personnel table={profile.personnel} />
            </Card>
          }

         
        </React.Fragment>
      )}
    </div>
  )
}
