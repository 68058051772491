import { getPropertyFilters } from '../../../services/getPropertyFilters';
import { getCompanyFolders } from "../../../services/getCompanyFolders"
import { postPropertyList } from '../../../services/postPropertyList';
import { getPropertyProfile } from '../../../services/getPropertyProfile';
import { getPropertyAutocomplete } from '../../../services/getPropertyAutocomplete';
import { postPropertyProvinces } from '../../../services/postPropertyProvinces';
import { addPropertyPortfolio } from '../../../services/addPropertyPortfolio';

export const serviceGetFilters = () => {
    return getPropertyFilters();
}

export const serviceGetFolders = (EntityId) => {
    return getCompanyFolders(EntityId);
}

export const servicePostList = (query) => {
    return postPropertyList(query);
}

export const serviceGetProfile = (id) => {
    return getPropertyProfile(id);
}

export const servicePropertyAutocomplete = (name) => {
    return getPropertyAutocomplete(name);
}

export const servicePropertyProvinces = (country) => {
    return postPropertyProvinces(country);
}

export const serviceAddPortfolio = id => {
    return addPropertyPortfolio(id);
}