import React from 'react';
import { createTheme, ThemeProvider } from "@mui/material";

const theme = createTheme({
    palette: {
        primary: {
            main: '#2D70DA',
            hover: '#1D53CC',
            light: '#5F9EE8'
        },
        info: {
            main: '#6B9D17',
            light: '#CDE286',
            dark: '#50820D',
            contrastText: '#000000'
        },
        secondary: {
            main: '#9B35B2',
            light: '#E1ACE9',
            dark: '#80229A',
            contrastText: '#000000'
        }
    },
    components: {
        MuiFilledInput: {
            styleOverrides: {
                root: {
                    borderRadius: 4,
                    paddingTop: '6px',
                    paddingBottom: '6px',
                    '&::before': {
                        content: 'none'
                    }
                },
                input: {
                    fontFamily: "'Montserrat', sans-serif",
                    fontWeight: 400,
                    fontSize: '1.125rem',
                    color: '#1E2326',
                    paddingTop: '2.5px',
                    paddingBottom: '2.5px',
                    "&::placeholder": {
                        fontFamily: "'Montserrat', sans-serif",
                        color: '#1E2326',
                        opacity: 1,
                        fontWeight: 400,
                        fontSize: '1.125rem',
                    },
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    padding: '12px 21px'
                }
            }
        }
    }
});

export default function Palette({children}) {
    return (
        <ThemeProvider theme={theme}>
            {children}
        </ThemeProvider>
    )
}