import React from 'react';

import { useMinePersonnel } from '../../../features/mine-personnel/context';

import Search from '../../molecules/Search';

export default function MinePersonnelFilter() {
    const {
    handleAutoComplete,
    autocomplete,
    minePersonnelName,
    handleSearchInput,
    handleMinePersonnelName,
    handleClearAutoComplete
    } = useMinePersonnel();

  return (
      <div className='search-filter' style={{ marginTop: '16px', marginBottom: '24px' }}>
      <Search
        page='personnel'
        search={handleSearchInput}
        inputValue={minePersonnelName}
        fetch={handleAutoComplete}
        autocomplete={autocomplete}
        handleInput={handleMinePersonnelName}
        clearOptions={handleClearAutoComplete}
      />
    </div>
  )
}
