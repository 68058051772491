import { getTopQueries } from "./getTopQueries"
import { getDataSet } from "./getDataSet"
import { getUserHistory } from "./getUserHistory"
import { getUserHistoryById } from "./getUserHistoryById"
import { postSearchResult } from "./postSearchResult"
import { deleteUserHistory } from "./deleteUserHistory"

export const serviceGetTopQueries = () => {
    return getTopQueries();
}

export const serviceGetDataSet = () => {
    return getDataSet();
}

export const serviceGetUserHistory = () => {
    return getUserHistory();
}

export const serviceGetUserHistoryById = (historyId) => {
    return getUserHistoryById(historyId);
}

export const servicePostSearchResult = (query) => {
    return postSearchResult(query);
}

export const serviceDeleteUserHistory = (id) => {
    return deleteUserHistory(id);
}