import React from "react";

import Text from "../atoms/Text";
import Chips from "../molecules/Chips";
import List from "../molecules/List";
import Pagination from "../molecules/Pagination";
import PageSize from "./PageSize";
import Button from "../atoms/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
export default function Listings(props) {
  return (
    <div className="listings-results">
      <div className="results-actions-wrapper">
        <Text marginBottom="5px">
          Total Results: {props.totalResults.toLocaleString("en-US")}
        </Text>
        <div className="results-actions">
          <Chips
            filters={props.filters}
            page={props.searchType}
            handleDelete={props.handleDelete}
          />
          {props.totalResults > props.pageSize && (
            <PageSize
              handlePage={props.handlePage}
              currentPageSize={props.pageSize}
            />
          )}
        </div>
      </div>
      {!props.results.length ? (
        <Text>No results</Text>
      ) : (
        <React.Fragment>
          <List
            addToPortfolio={props.addToPortfolio}
            searchType={props.searchType}
            sortSelected={props.sortSelected}
            handleSort={props.handleSort}
            list={props.results}
            listFolder={props.resultsFolder}
            type="search"
            page={props.type}
            select={props.select}
                          
          />
          {props.totalResults > props.pageSize && (
            <Pagination
              count={props.totalResults / props.pageSize}
              currentPage={props.pageNumber}
              handlePageNumber={props.handlePageNumber}
            />
          )}
        </React.Fragment>
      )}
    </div>
  );
}
