import React from 'react'
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TabButton from './assets/Button/TabButton';
import DrawerImg from './assets/Img/drawer.png'
export default function chatbar({ historyData, handleGetUserHistoryById, handleNewChat, handleSideBar, handleHistoryDelete, emptyHistory }) {
    
  return (
      <>
          <Grid style={{ background: '#fff', padding: '24px', borderRadius: '10px', position: 'relative' }} className='chatcon'>
            <Grid style={{ display: 'flex', justifyContent: 'space-between ' }}>

                  <Button variant="contained" fullWidth style={{ height: '50px', borderRadius: '10px', background: '#201E1E', marginRight: '10px' }} onClick={handleNewChat}><i className="fa-regular fa-square-plus" style={{fontSize:'22px'}}></i></Button>

                  <Button variant="contained" fullWidth style={{ height: '50px', borderRadius: '10px', background: '#201E1E' }} onClick={handleSideBar}>
            <img src={DrawerImg} alt="Costmine Intelligence" />
          </Button>

        </Grid>
        <Grid style={{ width:'100%', overflowY:'scroll', position:'absolute', left:'0', top:'100px'}} className='facnyscroll chathistory'>
        <Grid style={{padding:'24px', paddingTop:'0',}}>

        {historyData.length == 0 && emptyHistory && <Grid ><div style={{ marginBottom: '10px' }}>No history entries found.</div></Grid>}

        {historyData && historyData.today !== undefined && historyData.today.length > 0 && 
        <Grid >
            <div style={{ marginBottom: '10px' }}>Today GPT</div>
            {historyData.today.slice(0).reverse().map((dataHis) => {
                return (<Grid marginTop={2} key={"grid-" + dataHis.id}><TabButton key={dataHis.id} classNameVar='primary-btn' historyId={dataHis.id} hisoryInput={dataHis.input} handleHistoryDelete={handleHistoryDelete} handleGetUserHistoryById={handleGetUserHistoryById} /></Grid>)
            })}
        </Grid>
        }
                      

        
        {historyData && historyData.yesterday !== undefined && historyData.yesterday.length > 0 &&              
        <Grid marginTop={2}>
          <div style={{ marginBottom: '10px' }}>Yesterday GPT</div>
            {historyData.yesterday.slice(0).reverse().map((dataHis) => {
                return (<Grid marginTop={2} key={"grid-" + dataHis.id}><TabButton key={dataHis.id} classNameVar='secondary-btn' historyId={dataHis.id} hisoryInput={dataHis.input} handleHistoryDelete={handleHistoryDelete} handleGetUserHistoryById={handleGetUserHistoryById} /></Grid>)
            })}
        </Grid>
        }

        
        {historyData && historyData.sevenday !== undefined && historyData.sevenday.length > 0 && 
        <Grid marginTop={2}> 
          <div style={{ marginBottom: '10px' }}>Previous 7 Day GPT </div>
          {historyData.sevenday.slice(0).reverse().map((dataHis) => {
              return (<Grid marginTop={2} key={"grid-" + dataHis.id}><TabButton key={dataHis.id} classNameVar='secondary-btn' historyId={dataHis.id} hisoryInput={dataHis.input} handleHistoryDelete={handleHistoryDelete} handleGetUserHistoryById={handleGetUserHistoryById} /></Grid>)
            })}
        </Grid>
        }


        {historyData && historyData.thirtyday !== undefined && historyData.thirtyday.length > 0 && 
        <Grid marginTop={2}>
          <div style={{ marginBottom: '10px' }}>Previous 30 Day GPT</div>
          {historyData.thirtyday.slice(0).reverse().map((dataHis) => {
              return (<Grid marginTop={2} key={"grid-" + dataHis.id}><TabButton key={dataHis.id} classNameVar='secondary-btn' historyId={dataHis.id} hisoryInput={dataHis.input} handleHistoryDelete={handleHistoryDelete} handleGetUserHistoryById={handleGetUserHistoryById} /></Grid>)
            })}
                          </Grid>
        }


        </Grid>
      </Grid>
      </Grid>
    </>
  )
}
