import React from 'react';
import { Alert,  Container,  Snackbar, styled } from '@mui/material';
import { useLocation } from 'react-router';

import { useApp } from '../AppContext';

import AuthWrapper from './UI/organisms/AuthWrapper';
import Header from './UI/organisms/Header';
import Footer from './UI/organisms/Footer';
import SecondaryHeader from './UI/organisms/SecondaryHeader';

import Palette from './UI/theme/customTheme';
import images from '../images/index.js';

const StyledContainer = styled(Container)(({ theme }) => ({
    paddingTop: 24,
    zIndex: 1,
    position: 'relative',
    paddingBottom: 50,
    [theme.breakpoints.up('lg')]: {
      maxWidth: 1280,
    },
  
}))

const StyledWrapper = styled('div')(() => ({
  display: 'block',
  position: 'relative'
}))
const StyledImageWrapper = styled('div')(() => ({
  display: 'block',
  position: 'sticky',
  top: 0
}))
const StyledImage = styled('img')(() => ({
  width: '100%',
  position: 'absolute'
}))

export default function Layout({ children}) {
  const { errorMessage } = useApp();
  const location = useLocation();

  const currentPage = location.pathname;
  const pageType = currentPage === '/' || currentPage.includes('company') ? 'company' :
    currentPage.includes('properties') ? 'property' :
            'default';
   
  return (
    <StyledWrapper >
      <Palette>
        <React.Fragment>
          <SecondaryHeader />
          <StyledImageWrapper>
            <StyledImage src={images[pageType]} />
          </StyledImageWrapper>
          <Header />
          <AuthWrapper>
          <StyledContainer sx={{maxWidth: {sm: '720px', md: '960px', lg: '1260px'} , position:'relative', zIndex:'0'} } >
              {children}
            </StyledContainer>
          </AuthWrapper>
          <Footer />

          <Snackbar open={Boolean(errorMessage)} autoHideDuration={6000}>
            <Alert severity='error'>
              {errorMessage}
            </Alert>
          </Snackbar>
        </React.Fragment>
      </Palette>
    </StyledWrapper>
  )
}