import React from 'react'
import Button from "@mui/material/Button";
import Tooltip from '@mui/material/Tooltip';

const TabButton = ({ historyId, hisoryInput, handleHistoryDelete, handleGetUserHistoryById, classNameVar }) => {
    
        return (
            <div style={{ position: 'relative' }}>

                <Button title={hisoryInput} variant="contained" fullWidth style={{ height: '50px', borderRadius: '10px', textTransform: 'inherit', textAlign: 'left', lineHeight: '16px', paddingRight: '40px', display: 'block' }} className={classNameVar} onClick={() => handleGetUserHistoryById(historyId, hisoryInput)}>
                    {hisoryInput !== undefined ? hisoryInput.substring(0, 23) + "..." : ""}
                </Button>
                <i className="fa-solid fa-trash" onClick={() => handleHistoryDelete(historyId)} style={{ position: 'absolute', top: '17px', right: '15px', color: '#fff', cursor: 'pointer' }} ></i>


            </div>
        )
    
}

export default TabButton
