import React from 'react';
import { Stack } from '@mui/material';
import Button from '../atoms/Button'
import Text from '../atoms/Text'
import ResponsiveLayout from '../atoms/ResponsiveLayout';

const pageSizeList = [ 50, 100 ];

export default function ({handlePage, currentPageSize}) {

    return (
        <ResponsiveLayout className='page-size' container>
            <ResponsiveLayout item xs={12} sm={12} contained='md'>
                <Text marginRight='10px'>Items per page: </Text>
            </ResponsiveLayout>
            <ResponsiveLayout item xs={12} sm={12} contained='md'>
                <Stack direction={'row'} spacing={1}>
                    {pageSizeList.map((option, index) => (
                        <Button 
                            key={index} 
                            type='rounded' 
                            id={option} 
                            handleClick={handlePage}
                            boolean={currentPageSize === option}
                        >
                            {option}
                        </Button>
                    ))}
                </Stack>
            </ResponsiveLayout>
        </ResponsiveLayout>
    )
}
