import React from "react";
import Button from "../components/UI/atoms/Button";
import { membershipUrl } from "../lib/membershipUrl";

export default function LogIn() {

    const handleLogin = () => {
        window.location.href = membershipUrl() + 'login';
    };

    return (
        <div>
            <div>
                <h1>Welcome!</h1>
                <p>Click on the button below to continue to login:</p>
                <Button handleClick={() => handleLogin()}>Log In</Button>
            </div>
            <div>
                <p>© TNM Group</p>
            </div>
        </div>
    );
}
