import React from 'react';
import { styled, Table as MUITable, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Text from '../../atoms/Text';

const StyledHeader = styled(TableHead)(() => ({
    '& th': {
      backgroundColor: '#E6E7E7',
      textTransform: 'uppercase',
    },
    '& tr.title th': {
      backgroundColor: '#AB9F60',
      textTransform: 'unset'
    },
    '&.two-cols th': {
      width: '50%'
    }
  }));

const StyledTableCell = styled(TableCell)(() => ({
    border: 'none',
    borderBottom: '1px solid #E6E7E8',
    padding: '12px 21px',
    verticalAlign: 'bottom'
}))

const StyledHeaderText = ({children, first}) => {
    return (
        <Text textAlign={first ? 'left' : 'center'} color='#808183' fontSize='1rem' fontWeight={700} fontFamily="ff1">{children}</Text>
    )
}

export default function ReservesTable({table}) {
    const formatNumber = value => {
        return Math.round(value * 100) / 100;
    }
    return (
        <TableContainer>
            <MUITable>
                <StyledHeader>
                <TableRow>
                    <TableCell>
                        <StyledHeaderText first>Resource</StyledHeaderText>
                    </TableCell>
                    <TableCell>
                        <StyledHeaderText>P&P</StyledHeaderText>
                    </TableCell>
                    <TableCell>
                        <StyledHeaderText>M&I</StyledHeaderText>
                    </TableCell>
                    <TableCell>
                        <StyledHeaderText>INF</StyledHeaderText>
                    </TableCell>
                </TableRow>
                </StyledHeader>
                <TableBody>
                    <TableRow>
                        <StyledTableCell>
                            <Text fontFamily='ff2'>Ore tonnage ({table.oreTonnage.ppOreTonnageUnit || table.oreTonnage.infOreTonnageUnit})</Text>
                        </StyledTableCell>
                        <StyledTableCell>
                            <Text textAlign='right'>{formatNumber(table.oreTonnage.ppOreTonnage)}</Text>
                        </StyledTableCell>
                        <StyledTableCell>
                            <Text textAlign='right'>{formatNumber(table.oreTonnage.miOreTonnage)}</Text>
                        </StyledTableCell>
                        <StyledTableCell>
                            <Text textAlign='right'>{formatNumber(table.oreTonnage.infOreTonnage)}</Text>
                        </StyledTableCell>
                    </TableRow>
                    {table.commodityItems.map((row, index) => (
                    <TableRow key={`row-${index}`}>
                        <StyledTableCell key={`cell-1`}>
                            <Text fontFamily='ff2'>{row.commodity}</Text>
                            <Text>Grade ({row.miGradeUnits || row.infGradeUnits})</Text>
                            <Text>Metal ({row.miContainedMetalUnit || row.infContainedMetalUnit})</Text>
                        </StyledTableCell>
                        <StyledTableCell key={`cell-2`}>
                            <Text textAlign='right'>{formatNumber(row.ppGrade)}</Text>
                            <Text textAlign='right'>{formatNumber(row.ppContainedMetal)}</Text>
                        </StyledTableCell>
                        <StyledTableCell key={`cell-3`}>
                            <Text textAlign='right'>{formatNumber(row.miGrade)}</Text>
                            <Text textAlign='right'>{formatNumber(row.miContainedMetal)}</Text>
                        </StyledTableCell>
                        <StyledTableCell key={`cell-4`}>
                            <Text textAlign='right'>{formatNumber(row.infGrade)}</Text>
                            <Text textAlign='right'>{formatNumber(row.infContainedMetal)}</Text>
                        </StyledTableCell>
                    </TableRow>
                    ))}
                </TableBody>
            </MUITable>
        </TableContainer>
    )
}
