import React from 'react'
import Grid from "@mui/material/Grid";
export default function TopQueries({ topQueries, handleClickTocopy }) {
    return (
        <>
            {
                topQueries && topQueries !== "" && topQueries.map((topQueriesData, index) => {
                    if (topQueriesData !== "") {
                        return (
                            <Grid key={ "TQ-"+index} className="topquierieslink" onClick={() => handleClickTocopy(topQueriesData)} style={{ cursor: 'pointer' }}> {topQueriesData} <i className="fa-regular fa-copy"></i></Grid>
                        )
                    }
                })
            }
        </>
    )
    

}
