import React from 'react';
import { Link as MUILink, styled } from '@mui/material';
import { NavLink } from 'react-router-dom';

const StyledLink = styled(MUILink)(({theme}) => ({
  textDecoration: 'none',
  display: 'block',
  '&.release': {
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: 500
  },
  '&:hover': {
    textDecoration: 'none'
  },
  '&.contact': {
    fontSize: '0.875rem !important'
  },
  '&.hdr': {
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: 400,
    fontSize: '1rem',
    color: '#FFFFFF',
    textDecoration: 'none',
    textTransform: 'uppercase',
    padding: '4px',
    margin: '0 1px',
    cursor: 'pointer',
    lineHeight: 1,
    borderRadius: '3px',
    '&:hover': {
      color: '#FFFFFF',
      textDecoration: 'none',
      backgroundColor: '#5B5959',
    },
    '&.current-page': {
      color: '#FFFFFF',
      textDecoration: 'none',
      backgroundColor: '#5B5959'
    },
    '&.company, &.company:hover, &.company.current-page': {
      color: theme.palette.info.light
    },
    '&.property, &.property:hover, &.property.current-page': {
      color: theme.palette.secondary.light
    },
  },
  '&.menu': {
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: 400,
    fontSize: '1rem',
    color: '#413F3F',
    textDecoration: 'none',
    textTransform: 'uppercase',
    padding: '6px',
    cursor: 'pointer',
    borderRadius: '3px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    '&:hover': {
      color: '#413F3F',
      textDecoration: 'none',
      backgroundColor: '#ecebeb',
    },
    '&.current-page': {
      color: '#413F3F',
      textDecoration: 'none',
      backgroundColor: '#ecebeb'
    },
    '&.company, &.company:hover, &.company.current-page': {
      color: theme.palette.info.main
    },
    '&.property, &.property:hover, &.property.current-page': {
      color: theme.palette.secondary.main
    },
  },
  '&.icon': {
    color: '#808183',
    cursor: 'pointer',
    padding: 0,
    '& .MuiIcon-root': {
      fontSize: '1.4rem'
    },
    '&:hover': {
      color: '#231F20',
      backgroundColor: 'transparent'
    }
  },
  '&.table': {
    fontFamily: 'Roboto, sans-serif',
    color: '#707070',
    fontSize: '1rem',
    fontWeight: 400,
    padding: '0',
    '&:hover': {
      color: '#231F20',
      backgroundColor: 'transparent'
    },
    '&.company': {
      color: theme.palette.info.main
    },
    '&.properties': {
      color: theme.palette.secondary.main
    }
  },
  '&.footer': {
    fontFamily: "'Montserrat', sans-serif",
    padding: 0,
    color: '#fff',
    fontWeight: 400,
    '&:hover': {
      backgroundColor: 'transparent',
      color: '#fff'
    }
  },
  '&.img': {
    backgroundColor: 'transparent',
    height: '100%',
    '&.hd': {
      padding: '8px 0',
      "& img": {
        display: 'block'
      }
    }
  },
  '&.wall-contained': {
    backgroundColor: '#2d70da',
    color: '#fff',
    textTransform: 'uppercase',
    fontSize: '0.875rem',
    padding: '10px 17px',
    fontWeight: 500,
    boxShadow: '#0000003d 0px 2px 2px',
    fontFamily: 'Roboto',
    borderRadius: '3px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
    maxWidth: '400px',
  },
  '&.icon-hdr': {
    padding: '6px',
    borderRadius: '3px',
    lineHeight: '13px',
    '& .MuiIcon-root': {
      fontSize: '1.5rem',
      color: '#FFF'
    },
    '&:hover': {
      backgroundColor: '#5B5959'
    }
  },
  '&.icon-hdr-mb': {
    padding: '6px',
    borderRadius: '3px',
    lineHeight: '13px',
    backgroundColor: '#2D70DA',
    '& .MuiIcon-root': {
      fontSize: '1.5rem',
      color: '#FFF'
    },
    '&:hover': {
      backgroundColor: '#1f4e98'
    }
  }
}))

const Link = React.forwardRef(({children, link, type, openTab, external, exact, sx, ...props}, ref) => {

  if (external) {
    return (
      <StyledLink {...props} ref={ref} sx={{...sx}} href={link} className={type} target={openTab ? '_blank' : '' } rel="noreferrer noopener">{children}</StyledLink>
    )
  }
  else {
    return (
      <StyledLink sx={{...sx}} exact component={NavLink} activeClassName='current-page' className={type} to={link} target={openTab ? '_blank' : '' } rel="noreferrer noopener">{children}</StyledLink>
    )
  }
});

export default Link;