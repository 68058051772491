import React, { createContext, useContext, useReducer } from 'react';
import { useHistory } from 'react-router';
import { useApp } from '../../../AppContext';
// import { defineQueryFilters } from '../../../lib/defineQueryFilters';
import { handleFiltersList } from '../../../lib/handleFiltersApplied';
import { handleNameInput } from '../../../lib/handleNameInput';
import { serviceMinePersonnelAutocomplete, servicePostList, serviceGetFolders } from './middleware';
import { deletePortfolioItem, postPortfolioItem } from '../middleware/middleware';
import { managePortfolioSearch } from '../../../lib/managePortfolioSearch';
import { minePersonnelReducer } from './reducer';


const MinePersonnelContext = createContext();

export const MinePersonnelProvider = ({ children }) => {
    const history = useHistory();
    const defaultSort = {fieldName: 'PersonFullName ', direction: 0};

    const initialState = {
        filters: {},
        selectedFilters: [],
        minePersonnelName: '',
        query: {
            minePersonnelName: '',
            filter: {},
            option: 1,
            pageSize: 50,
            pageNumber: 1,
            isDefaultView: true,
            sorting: defaultSort
        },
        results: [],
        resultsFolder: [],
        totalResults: 0,
        searchType: 0,
        autocomplete: [],
        emtyFilters: true
    }

    const { handleLoading, handleError } = useApp();
    const [ state, dispatch ] = useReducer(minePersonnelReducer, initialState);

    const handleMinePersonnelName = e => {
        dispatch({type: 'SET_MINEPERSONNEL_NAME', payload: e});
    }

    const removeFilter = filter => {
        handleMinePersonnelName('');
        dispatch({ type: 'SET_OPTION', payload: 2 });
        dispatch({ type: 'SET_DEFAULT_VIEW', payload: handleNameInput(false) });
        const selectedFilters = handleFiltersList({ peopleName: '' });
        dispatch({ type: "SET_SELECTED_FILTERS", payload: selectedFilters });

        dispatch({ type: 'SET_PAGE_NUMBER', payload: 1 });
        dispatch({ type: 'SET_SORTING', payload: defaultSort });
    }

    const setFolders = () => {
        const EntityId = 3;
        serviceGetFolders(EntityId).then(data => {
            setTimeout(() => {
                const resultsFolder = data.data;
                dispatch({ type: 'SET_RESULTS_Folder', payload: resultsFolder });
            }, 500);
        }).catch(err => {
            console.error(err);
            handleError(err.response.headers.errormessage);
        });
    }

    const handlePageNumber = (e, value) => {
        dispatch({type: 'SET_PAGE_NUMBER', payload: value});
    }
    const handleSearch = () => {
        handleLoading(true);

        const body = state.query;
        servicePostList(body).then(data => {
            setTimeout(() => {
                const results = data.data.personnelSearchResultList;
                dispatch({type: 'SET_RESULTS', payload: results});
                dispatch({ type: 'SET_TOTAL_RESULTS', payload: data.data.totalResults });
                handleLoading(false);
            }, 500);
        }).catch(err => {
            handleLoading(false);
            handleError(err.response.headers.errormessage);
        })
    }
    const handleSearchInput = () => {
        dispatch({type: 'SET_OPTION', payload: 0 });
        dispatch({type: 'SET_DEFAULT_VIEW', payload: handleNameInput(state.minePersonnelName)});
        const selectedFilters = handleFiltersList({minePersonnelName: state.minePersonnelName});
        dispatch({type: 'SET_SELECTED_FILTERS', payload: selectedFilters});
        dispatch({type: "SET_PAGE_NUMBER", payload: 1});
        dispatch({type: 'SET_SORTING', payload: defaultSort});
    }
    const handleSearchSorted = (e) => {
        dispatch({type: "SET_PAGE_NUMBER", payload: 1});
        dispatch({type: 'SET_SORTING', payload: e});
    }

    const handlePageSize = e => {
        dispatch({type: 'SET_PAGE_SIZE', payload: parseInt(e.target.id) });
    }

    const handleSelectProperty = id => {
        dispatch({ type: 'SET_PROPERTY_ID', payload: id });
        history.push(`/properties/${id}`);
    }

    const handleAutoComplete = (name) => {
        handleLoading(true);

        serviceMinePersonnelAutocomplete(name).then(data => {
            const list = data.data.personnelSearchResultList;
            dispatch({ type: 'SET_AUTOCOMPLETE', payload: list });
            handleLoading(false);
        }).catch(err => {
            handleLoading(false);
            console.error(err);
            handleError(err.response.headers.errormessage);
        })
    }


    const handleClearAutoComplete = () => {
        dispatch({ type: 'SET_AUTOCOMPLETE', payload: [] })
    }

    const handlePortfolioOnSearch = (folderid, id, action) => {
        const entity = 3;
        const body = { entityId: id.toString(), entityTypeId: entity, folderId: folderid }
        if (!action) {
            postPortfolioItem(body).then(() => {
                const updatedResults = managePortfolioSearch(state.results, id);
                dispatch({ type: 'SET_RESULTS', payload: updatedResults });
            }).catch(err => {
                console.error(err);
                handleError(err.response.headers.errormessage);
            })
        } else {
            deletePortfolioItem(body).then(() => {
                const updatedResults = managePortfolioSearch(state.results, id);
                dispatch({ type: 'SET_RESULTS', payload: updatedResults });
            }).catch(err => {
                console.error(err);
                handleError(err.response.headers.errormessage);
            })
        }
    }

    const providerValue = {
        ...state,
        handleMinePersonnelName,
        handlePageNumber,
        handlePageSize,
        handleSearch,
        handleSearchInput,
        removeFilter,
        handleAutoComplete,
        handleClearAutoComplete,
        handleSelectProperty,
        handleSearchSorted,
        handlePortfolioOnSearch,
        setFolders
    }

    return (
        <MinePersonnelContext.Provider value={providerValue}>
            {children}
        </MinePersonnelContext.Provider>
    )
}

export const useMinePersonnel = () => useContext(MinePersonnelContext);