import React, { useState } from 'react';
import { Icon, Menu as MenuMUI, MenuItem, Tooltip } from '@mui/material';
import Button from '../atoms/Button';
import Link from '../atoms/Link';
import Text from '../atoms/Text';

const StyledMenuItem = (props) => {
    return (
      <MenuItem
      {...props}
      sx={{
        padding: '0',
        borderBottom: '1px solid #ECEBEB',
        ':last-of-type': {
          borderBottom: 'none'
        },
        display: 'flex',
        justifyContent: 'space-between',
        width: {
            xs: 'calc(100vw - 32px)',
            sm: '320px'
        },
        ':hover': {
            backgroundColor: '#ecebeb'
        },
        '&.disabled': {
            pointerEvents: 'none',
            cursor: 'default',
            '& *': {
                color: '#8b8b8b'
            }
        }
      }}
      >
        {props.children}
      </MenuItem>
    )
}

export default function Menu({options, menuIcon, tooltip = ''}) {
    const [ anchorEl, setAnchorEl ] = useState(null);
    const open = Boolean(anchorEl);

    const handleOpen = e => {
        setAnchorEl(e.currentTarget);
    }
    const handleClose = () => {
        setAnchorEl(null);
    }

    return (
        <div>
            <Tooltip title={tooltip}>
                <Button
                    type='icon'
                    placement={`${open && 'open-menu'} nav`}
                    handleClick={handleOpen}
                    iconProps={{
                        baseClassName: menuIcon.base,
                        className: menuIcon.className,
                        sx: { ...menuIcon.sx, fontSize: '1.5rem', color: menuIcon.color}
                    }}
                />
            </Tooltip>
            <MenuMUI
                id='switcher-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                sx={{
                    '.MuiBackdrop-root': {
                        transition: 'none !important'
                    }
                }}
                PaperProps={{
                    sx: {
                        boxShadow: '0px 3px 5px 0px #00000026',
                        marginTop: '1px',
                        borderRadius: '0px 0px 4px 4px',
                        transition: 'height 207ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 138ms !important'
                    }
                }}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                    sx: { padding: 0 }
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {options.map((option, index) => (
                    <StyledMenuItem className={option.type} key={index}>
                        {option.link ? (
                            <Link {...option} external={option.external} link={option.link} openTab={option.openTab !== undefined ? option.openTab : option.external} type={`${option.type} menu`}>
                                {option.text}
                                <Icon baseClassName={option.icon.base} className={option.icon.className} sx={{ ...option.sx, fontSize: '1rem' }} />
                            </Link>
                        ) : (
                            <div style={{width: '100%'}} onClick={() => {option.onClick()}}>
                                <Text padding='6px' fontWeight='400' textTransform='uppercase' fontFamily='ff1' width='100%' display='flex' justifyContent='space-between' alignItems='center'>
                                    {option.text}
                                    <Icon baseClassName={option.icon.base} className={option.icon.className} sx={{ ...option.sx, fontSize: '1rem' }} />
                                </Text>
                            </div>
                        )}
                    </StyledMenuItem>
                ))}
            </MenuMUI>
        </div>
    )
}
