import React from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import Text from '../../atoms/Text'

export default function SingleTables({tables}) {
  return (
    <React.Fragment>
        {tables.map((table) => (
            <TableContainer key={`table-${table[0]['productionYear']}`}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{backgroundColor: '#E6E7E8'}}>
                                <Text fontWeight='700' fontFamily='ff1' color='#808183'>{table[0]['productionYear']}</Text>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {table.map((commodity, index) => (
                            <TableRow key={`commodity=${index}`}>
                                <TableCell>
                                    <Text>{commodity.commodity} ({commodity.productionUnit}) {Math.round(commodity.productionValue * 100) / 100}</Text>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        ))}
    </React.Fragment>
  )
}
