import React, { createContext, useContext, useReducer } from 'react';
import { useApp } from '../../../AppContext';
import { checkboxSelect } from '../../../lib/checkbokSelect';
import { clearFilters } from '../../../lib/clearFilters';
import { defineQueryFilters } from '../../../lib/defineQueryFilters';
import { dropdownSelect } from '../../../lib/dropdownSelect';
import { handleFiltersList } from '../../../lib/handleFiltersApplied';
import { handleNameInput } from '../../../lib/handleNameInput';
import { serviceGetFilters, servicePeopleAutocomplete, servicePostList, serviceGetFolders } from './middleware';
import { deletePortfolioItem, postPortfolioItem } from '../middleware/middleware';
import { managePortfolioSearch } from '../../../lib/managePortfolioSearch';
import {peopleReducer} from './reducer';

const PeopleContext = createContext();

export const PeopleProvider = ({children}) => {
    const defaultSort = {fieldName: 'PersonFullName ', direction: 0};

    const initialState = {
        filters: {},
        selectedFilters: [],
        peopleName: '',
        query: {
            peopleName: '',
            filter: {},
            option: 1,
            pageSize: 50,
            pageNumber: 1,
            isDefaultView: true,
            sorting: defaultSort
        },
        results: [],
        resultsFolder: [],
        totalResults: 0,
        searchType: 0,
        autocomplete: [],
        emtyFilters: true
    }

    const { handleLoading, handleError } = useApp();
    const [ state, dispatch ] = useReducer(peopleReducer, initialState);

    const handleQuery = data => {
        const query = defineQueryFilters(state.query, data);
        dispatch({type: 'SET_QUERY', payload: query});
        dispatch({type: 'SET_EMPTY_FILTERS_FLAG', payload: query.isDefaultView});
    }
    const setFilters = () => { 
        serviceGetFilters().then(data => {
            const filters = data.data;
            handleQuery(filters);
            dispatch({type: 'SET_FILTER', payload: filters});
        }).catch(err => {
            console.error(err);
            handleError(err.response.headers.errormessage);
        })
    }

    const setFolders = () => {
        const EntityId = 3;
        serviceGetFolders(EntityId).then(data => {
            setTimeout(() => {
                const resultsFolder = data.data;
                dispatch({ type: 'SET_RESULTS_Folder', payload: resultsFolder });
            }, 500);
        }).catch(err => {
            console.error(err);
            handleError(err.response.headers.errormessage);
        });
    }

    const handleFilters = e => {
        const id = e.target.name || e.target.id;
        let payload;
        if (id.includes("position")) {
            payload = dropdownSelect(state.filters, e.target);
        }
        else {
            payload = checkboxSelect(state.filters, e.target);
        }
        dispatch({type: 'EDIT_FILTER', payload: payload});
        handleQuery(payload);
        return payload;
    }
    const handlePeopleName = e => {
        dispatch({type: 'SET_PEOPLE_NAME', payload: e});
    }
    const removeFilter = filter => {
        const id = filter.id;

        if (id.includes("Name")) {
            handlePeopleName('');
            dispatch({type: 'SET_OPTION', payload: 2});
            dispatch({type: 'SET_DEFAULT_VIEW', payload: handleNameInput(false)});
            const selectedFilters = handleFiltersList({peopleName: ''});
            dispatch({type: "SET_SELECTED_FILTERS", payload: selectedFilters});
        }
        else {
            const payload = handleFilters({target: {...filter}});
            const selectedFilters = handleFiltersList(payload);
            dispatch({type: "SET_SELECTED_FILTERS", payload: selectedFilters});
            dispatch({type: 'SET_OPTION', payload: 1});
        }
        dispatch({type: 'SET_PAGE_NUMBER', payload: 1});
        dispatch({type: 'SET_SORTING', payload: defaultSort});
    }
    const handleClearFilters = () => {
        const payload = clearFilters(state.filters);
        
        handlePeopleName('');
        dispatch({type: 'EDIT_FILTER', payload: payload});
        handleQuery(payload);
        dispatch({type: 'SET_OPTION', payload: 1});
        dispatch({type: "SET_SELECTED_FILTERS", payload: []});
        dispatch({type: 'SET_PAGE_NUMBER', payload: 1});
        dispatch({type: 'SET_SORTING', payload: defaultSort});
    }
    const handlePageNumber = (e, value) => {
        dispatch({type: 'SET_PAGE_NUMBER', payload: value});
    }
    const handleSearch = () => {
        handleLoading(true);

        const body = state.query;
        servicePostList(body).then(data => {
            setTimeout(() => {
                const results = data.data.peopleSearchResultList;
                dispatch({type: 'SET_RESULTS', payload: results});
                dispatch({type: 'SET_TOTAL_RESULTS', payload: data.data.totalResults});
                handleLoading(false);
            }, 500);
        }).catch(err => {
            handleLoading(false);
            handleError(err.response.headers.errormessage);
        })
    }
    const handleSearchFilter = () => {
        handlePeopleName('');
        dispatch({type: 'SET_OPTION', payload: 1 });
        const selectedFilters = handleFiltersList(state.filters);
        dispatch({type: 'SET_SELECTED_FILTERS', payload: selectedFilters});
        dispatch({type: "SET_PAGE_NUMBER", payload: 1});
        dispatch({type: 'SET_SORTING', payload: defaultSort});
    }
    const handleSearchInput = () => {
        dispatch({type: 'SET_OPTION', payload: 0 });
        dispatch({type: 'SET_DEFAULT_VIEW', payload: handleNameInput(state.peopleName)});
        const selectedFilters = handleFiltersList({peopleName: state.peopleName});
        dispatch({type: 'SET_SELECTED_FILTERS', payload: selectedFilters});
        dispatch({type: "SET_PAGE_NUMBER", payload: 1});
        dispatch({type: 'SET_SORTING', payload: defaultSort});
    }
    const handleSearchSorted = (e) => {
        dispatch({type: "SET_PAGE_NUMBER", payload: 1});
        dispatch({type: 'SET_SORTING', payload: e});
    }
    const handlePageSize = e => {
        dispatch({type: 'SET_PAGE_SIZE', payload: parseInt(e.target.id) });
    }
    const handleAutoComplete = (name) => {
        handleLoading(true);

        servicePeopleAutocomplete(name).then(data => {
            const list = data.data.peopleSearchResultList;
            dispatch({type: 'SET_AUTOCOMPLETE', payload: list});
            handleLoading(false);
        }).catch(err => {
            handleLoading(false);
            console.error(err);
            handleError(err.response.headers.errormessage);
        })
    }

    const handleClearAutoComplete = () => {
        dispatch({type: 'SET_AUTOCOMPLETE', payload: []})
    }

    const handlePortfolioOnSearch = (folderid, id, action) => {
        const entity = 3;
        const body = { entityId: id.toString(), entityTypeId: entity, folderId: folderid }
        if (!action) {
            postPortfolioItem(body).then(() => {
                const updatedResults = managePortfolioSearch(state.results, id);
                dispatch({ type: 'SET_RESULTS', payload: updatedResults });
            }).catch(err => {
                console.error(err);
                handleError(err.response.headers.errormessage);
            })
        } else {
            deletePortfolioItem(body).then(() => {
                const updatedResults = managePortfolioSearch(state.results, id);
                dispatch({ type: 'SET_RESULTS', payload: updatedResults });
            }).catch(err => {
                console.error(err);
                handleError(err.response.headers.errormessage);
            })
        }
    }

    const providerValue = {
        ...state,
        setFilters,
        handleFilters,
        handlePeopleName,
        handlePageNumber,
        handlePageSize,
        handleSearch,
        handleSearchInput,
        handleSearchFilter,
        handleAutoComplete,
        handleClearFilters,
        handleClearAutoComplete,
        removeFilter,
        handleSearchSorted,
        handlePortfolioOnSearch,
        setFolders
    }

    return (
        <PeopleContext.Provider value={providerValue}>
            {children}
        </PeopleContext.Provider>
    )
}

export const usePeople = () => useContext(PeopleContext);