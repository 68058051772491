import React from 'react';

import { usePeople } from '../../../features/people/context';

import Search from '../../molecules/Search';

export default function PeopleFilter() {
  const {
    handleAutoComplete,
    autocomplete,
    peopleName,
    handleSearchInput,
    handlePeopleName,
    handleClearAutoComplete } = usePeople();

  return (
      <div className='search-filter' style={{ marginTop: '16px', marginBottom: '24px' }}>
      <Search
        page='people'
        search={handleSearchInput}
        inputValue={peopleName}
        fetch={handleAutoComplete}
        autocomplete={autocomplete}
        handleInput={handlePeopleName}
        clearOptions={handleClearAutoComplete}
      />
    </div>
  )
}
