import React, { useEffect } from 'react';

import { useApp } from '../AppContext';
import { useCompany } from '../components/features/company/context';
import useDocumentTitle from '../components/features/hooks/useDocumentTitle';

import CompanyFilter from '../components/UI/features/filters/CompanyFilter';

import Loading from '../components/UI/atoms/Loading';
import AuthWrapper from '../components/UI/organisms/AuthWrapper';
import Listings from '../components/UI/organisms/Listings';

import { emptyObject } from '../lib/emptyObject';

export default function CompanyListing() {
  const {
    query,
    filters,
    results,
    resultsFolder,
    searchType,
    handleSearch,
    totalResults,
    selectedFilters,
    setFilters,
    removeFilter,
    handlePageSize,
    handlePageNumber,
    handleSelectCompany,
    handleSearchSorted,
    handlePortfolioOnSearch,
    setFolders
  } = useCompany();
  const { isLoading } = useApp();

  useDocumentTitle('Company Search | TNM Marco Polo');

  useEffect(() => {
    if (Object.keys(filters).length === 0) {
      (async () => {
        setFilters();
        setFolders();
      })();
    }
  // eslint-disable-next-line
  }, [filters]);

  useEffect(() => {
    (async () => {
      handleSearch();
    })();
    // eslint-disable-next-line
  }, [searchType]);
  
  return (
    <AuthWrapper>
      
      {!emptyObject(filters) && (
        
        <CompanyFilter />
      )}
      {
        isLoading ?
        <Loading />
        
        :
       
                  <Listings 
                      sortSelected={query.sorting}
                      addToPortfolio={handlePortfolioOnSearch}
                      handleSort={handleSearchSorted}
                      results={results}
                      resultsFolder={resultsFolder}
                      type='company'
                      searchType='company'
                      select={handleSelectCompany}
                      handlePage={handlePageSize}
                      pageSize={query.pageSize}
                      filters={selectedFilters}
                      handleDelete={removeFilter}
                      pageNumber={query.pageNumber}
                      handlePageNumber={handlePageNumber}
                      totalResults={totalResults}
                      
        />
        
      }
      
    </AuthWrapper>
   
  )
}
