import * as React from 'react';
import usePagination from '@mui/material/usePagination';
import { styled } from '@mui/material/styles';
import { Button, Icon } from '@mui/material';
import Text from '../atoms/Text';

const List = styled('ul')({
  listStyle: 'none',
  padding: 0,
  margin: 0,
  display: 'flex',
});

export default function Pagination({count, currentPage, handlePageNumber}) {
  const { items } = usePagination({
    count: Math.ceil(count),
    page: currentPage,
    onChange: handlePageNumber,
    siblingCount: 0,
  });

  return (
    <nav className='pagination-wrapper'>
      <List sx={{ justifyContent: 'center', marginTop: '50px'}}>
        {items.map(({ page, type, selected, ...item }, index) => {
          let children = null;

          if (type === 'start-ellipsis' || type === 'end-ellipsis') {
            children = <Text margin='0 10px'>...</Text>;
          } else if (type === 'page') {
            children = (
              <Button
                variant='outlined'
                sx={{
                  backgroundColor: selected ? 'primary.main' : '#FFF',
                  color: selected ? '#FFF' : 'primary.main',
                  minWidth: '36px',
                  padding: '10px',
                  borderRadius: 0,
                  lineHeight: '0.875rem',
                }}
                style={{
                  fontWeight: selected ? 'bold' : undefined,
                }}
                {...item}
              >
                {page}
              </Button>
            );
          } else {
            const icon = type === 'previous' ?
            <Icon sx={{fontSize: '0.875rem'}} baseClassName='fa-solid' className='fa-angle-left' /> : 
            <Icon sx={{fontSize: '0.875rem'}} baseClassName='fa-solid' className='fa-angle-right' />;
            children = (
              <Button
                variant='outlined'
                sx={{
                  minWidth: '36px',
                  padding: '10px',
                  borderRadius: 0,
                  backgroundColor: '#FFF',
                  lineHeight: '0.875rem'
                }}
                {...item}
              >
                {icon}
              </Button>
            );
          }

          return <li key={index} className='pagination-btn'>{children}</li>;
        })}
      </List>
    </nav>
  );
}