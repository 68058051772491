import React from 'react';
import { List, ListItem, styled } from '@mui/material';
import Link from '../atoms/Link';
import Menu from './Menu';
import Dialog from './Dialog';

const StyledListItem = styled(ListItem)(() => ({
  padding: '0px',
  width: 'fit-content',
  '&:first-of-type': {
    paddingLeft: 0
  }
}))

const StyledMobileNav = styled('div')(() => ({
  border: 'none'
}))

export default function NavMenum({mobile, guide, notification}) {

  if (mobile) {
    return (
      <StyledMobileNav className='nav-menu-mobile' sx={{display: {sm: 'block', md: 'none'}}}>
        <Menu
          menuIcon={{base: 'fa-thin', className: 'fa-bars header-nav', color: '#FFFFFF'}}
          options={[
            {link: '/', type: 'company', text: 'Companies', icon: {base: 'fa-solid', className: 'fa-building'}},
            {link: '/properties', type: 'property', text: 'Properties', icon: {base: 'fa-solid', className: 'fa-location-dot'}},
            { link: '/executives', text: 'Executives', icon: { base: 'fa-solid', className: 'fa-users' }, sx: { width: '20px' } },
            { link: '/personnel', text: 'Personnel', icon: { base: 'fa-solid', className: 'fa-users' }, sx: { width: '20px' } },
            {link: '/my-portfolio', text: 'My Portfolio', icon: {base: 'fa-solid', className: 'fa-folder-open'}, sx: {width: '18px'}},
             {link: '/chatbot', type: 'company', text: 'Chatbot', icon: {base: 'fa-solid',  className: 'fa-folder-open'}, sx: { width: '20px' }}
          ]}
        />
      </StyledMobileNav>
    )
  }
  else if (notification) {
    return (
      <div></div>
    )
  }
  else if (guide) {
    return (
      <Dialog
        tooltip='Help'
        menuIcon={{base: 'fa-thin', className: 'fa-square-question', color: '#FFFFFF', sx: {width: '24px'}}}
        options={[
          {link: 'http://marcopolo.tnm.global/wp-content/uploads/2022/09/2022-09-02-Marco-Polo-User-Guide.pdf', external: true, text: "User Guide", icon: {base: 'fa-solid', className: 'fa-file-lines'}, download: "Marco-Polo-User-Guide"},
        ]}
      />
    )
  }
  else {
    return (
      <StyledMobileNav className='nav-menu-desktop' sx={{display: {xs: 'none', sm: 'none', md: 'block'}}}>
        <nav className='mih-nav'>
          <List sx={{ display: 'flex', padding: 0 }}>
            <StyledListItem>
              <Link exact link='/' type='company hdr'>Companies</Link>
            </StyledListItem>
            <StyledListItem>
              <Link link='/properties' type='property hdr'>Properties</Link>
            </StyledListItem>
            <StyledListItem>
              <Link link='/executives' type='hdr'>Executives</Link>
            </StyledListItem>
            <StyledListItem>
              <Link link='/personnel' type='hdr'>Personnel</Link>
            </StyledListItem>
            <StyledListItem>
              <Link link='/my-portfolio' type='hdr'>My Portfolio</Link>
            </StyledListItem>
          </List>
        </nav>
      </StyledMobileNav>
    )
  }
}