import React from 'react';
import { styled } from '@mui/material';

import { useApp } from '../../../AppContext';

import Button from '../atoms/Button';
import NavMenum from '../molecules/NavMenu';
import UserMenu from '../molecules/UserMenu';
import AppSwitcher from './AppSwitcher';

import { membershipUrl } from '../../../lib/membershipUrl';

const StyledHeader = styled('nav')(({theme}) => ({
    display: 'flex',
    justifyContent: 'space-around',
    backgroundColor: theme.palette.primary.main,
    width: '100%'
}))

export default function SecondaryHeader() {
    const { user } = useApp();
    const handleLogin = () => {
        window.location.href = membershipUrl() + 'login?src=' + window.location.host;
    };

  return (
    <StyledHeader sx={{display: {sm: 'flex', md: 'none'}}}>
        <NavMenum notification />
        <NavMenum guide float />
        <AppSwitcher mobile />
        {user.authenticated ? (
            <UserMenu user={user} />
        ) : (
            <Button type='text' handleClick={() => handleLogin()} className='header-btn'>Log in</Button>
        )}
    </StyledHeader>
  )
}
