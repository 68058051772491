import { Grid } from '@mui/material'
import React from 'react'

export default function ResponsiveLayout({container, item, xs, sm, md, lg, contained, children, className}) {
    if (container) {
        return (
            <Grid container className={`resLayout-root ${className}`}>
                {children}
            </Grid>
        )
    } else {
        return (
            <Grid item xs={xs} sm={sm} md={md} lg={lg} className={`resLayout-item ${container && 'item-container'} contained-${contained} ${className}`}>
                {children}
            </Grid>
        )
    }
}
