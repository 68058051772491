import React from 'react';
import Grid from "@mui/material/Grid";
export default function Datasetbox({ dataSetResult, dataTitle }) {
  return (
      <>
          <Grid item xs={12} md={6} lg={4}>
              <Grid className='datasetboxhe'>
                  {dataTitle}
              </Grid>
              <Grid className='datasetitem' >
                  <Grid className="itembox">
                    {
                       dataSetResult.map((dataSetResultData, index) => {
                            if (dataSetResultData != "") {
                                return (
                                    <span key={"dt-" + index} >{dataSetResultData}</span>
                                    )
                            }
                        })
                    }
                  </Grid>
              </Grid>
              </Grid>


        </>

  )
}
