import { getPortfolio } from "../../../services/getPortfolio"
import { putCompanyPortfolio } from "../../../services/putCompanyPortfolio";
import { putPropertyPortfolio } from "../../../services/putPropertyPortfolio";
import { getCompanyFolders } from "../../../services/getCompanyFolders"
import { postFolderDelete } from "../../../services/deletePortfolioFolder";
import { postMovePortfolio } from "../../../services/postMovePortfolio";
import { deleteFolderIteam } from "../../../services/deleteFolderIteam";



export const serviceGetPortfolio = () => {
    return getPortfolio();
}

export const deletePortfolioFolder = (dataid) => {
    return postFolderDelete(dataid);
}

export const postPortfolioItemMove = (data) => {
    return postMovePortfolio(data);
}

export const deletePortfolioFolderItem = (dataid) => {
    return deleteFolderIteam(dataid);
}

export const serviceUpdateCompanyList = (id) => {
    return putCompanyPortfolio(id);
}

export const serviceGetFolders = (EntityId) => {
    return getCompanyFolders(EntityId);
}

export const serviceUpdatePropertyList = (id) => {
    return putPropertyPortfolio(id);
}