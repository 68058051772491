import React, { createContext, useContext, useReducer } from 'react';
import { useHistory } from 'react-router';

import { useApp } from '../../../AppContext';
import {propertyReducer} from './reducer';
import { serviceAddPortfolio, serviceGetFilters, serviceGetProfile, servicePostList, servicePropertyAutocomplete, servicePropertyProvinces, serviceGetFolders } from './middleware';

import { checkboxSelect } from '../../../lib/checkbokSelect';
import { clearFilters } from '../../../lib/clearFilters';
import { defineQueryFilters } from '../../../lib/defineQueryFilters';
import { dropdownSelect } from '../../../lib/dropdownSelect';
import { handleNameInput } from '../../../lib/handleNameInput';
import { handleFiltersList } from '../../../lib/handleFiltersApplied';
import { managePortfolioSearch } from '../../../lib/managePortfolioSearch';
import { deletePortfolioItem, postPortfolioItem } from '../middleware/middleware';
import { filterUndefined, reorderDropdowns } from '../../../lib/reorderDropdowns';

const PropertyContext = createContext();

export const PropertyProvider = ({children}) => {
    const history = useHistory();
    const defaultSort = {fieldName: "PDisplayName", direction: 0};

    const initialState = {
        filters: {},
        selectedFilters: [],
        propertyName: [],
        propertySearchValues: [],
        query: {
            propertyName: [],
            filter: {},
            option: 1,
            pageSize: 50,
            pageNumber: 1,
            isDefaultView: true,
            sorting: defaultSort
        },
        results: [],
        resultsFolder: [],
        totalResults: 0,
        selected: '',
        profile: {},
        searchType: 0,
        autocomplete: [],
        emptyFilters: true
    }

    const { handleLoading, handleError } = useApp();
    const [ state, dispatch ] = useReducer(propertyReducer, initialState);

    const handleQuery = data => {
        const query = defineQueryFilters(state.query, data);
        dispatch({type: 'SET_QUERY', payload: query});
        dispatch({type: 'SET_EMPTY_FILTERS_FLAG', payload: query.isDefaultView});
    }
    const setFilters = () => {
        serviceGetFilters().then(data => {
            dispatch({type: 'SET_PROFILE', payload: {}});
            const filters = {...data.data};
            filters.activityStatusList_Dropdown = reorderDropdowns(filters.activityStatusList_Dropdown, 'activity');
            filters.developmentStatusList_Dropdown = reorderDropdowns(filters.developmentStatusList_Dropdown, 'development');
            
            filters.provinceList_Dropdown = filterUndefined(filters.provinceList_Dropdown);
            handleQuery(filters);
            dispatch({ type: 'SET_FILTER', payload: filters });
            setFolders();
        }).catch(err => {
            console.error(err);
            handleError(err.response.headers.errormessage);
        });
    }

    const setFolders = () => {
        const EntityId = 2;
        serviceGetFolders(EntityId).then(data => {
                const resultsFolder = data.data;
                dispatch({ type: 'SET_RESULTS_Folder', payload: resultsFolder });
        }).catch(err => {
            console.error(err);
            handleError(err.response.headers.errormessage);
        });
    }

    const handleProvinces = (country) => {
        servicePropertyProvinces(country).then(data => {
            const provinces = filterUndefined(data.data);
            const currentProvince = state.query.filter.provinceList_Dropdown;
            const newState = {...state.filters, provinceList_Dropdown: provinces};
            if (currentProvince !== "") {
                const payload = dropdownSelect(newState, {id: 'provinceList_Dropdown', value: currentProvince});
                dispatch({type: 'EDIT_FILTER', payload: payload});
            }
            else {
                dispatch({type: 'SET_PROVINCES', payload: provinces});
            }
        }).catch(err => {
            console.lerror(err);
            handleError(err.response.headers.message);
        })
    }
    const handleFilters = e => {
        const id = e.target.name || e.target.id;
        let payload;
        if (id.includes('country')) {
            handleProvinces(e.target.value);
            payload = dropdownSelect(state.filters, e.target);
        }
        else if (id.includes("Checkbox")) {
            payload = checkboxSelect(state.filters, e.target);
        }
        else if (id.includes("Dropdown")) {
            payload = dropdownSelect(state.filters, e.target);
        }
        dispatch({type: 'EDIT_FILTER', payload: payload});
        handleQuery(payload);
        return payload;
    }
    const handlePropertyName = (fullOption, name) => {
        dispatch({type: 'SET_PROPERTY_NAME', payload: { fullOption, name }});
    }
    const removeFilter = filter => {
        const id = filter.id;

        if (id.includes("Name")) {
            const  filteredList = state.propertySearchValues.filter(value => value.propertyName !== filter.text);
            const filteredName = state.propertyName.filter(name => name !== filter.text);
            const isDefault = !filteredList.length;
            handlePropertyName(filteredList, filteredName);
            dispatch({type: 'SET_OPTION', payload: isDefault ? 2 : 0});
            dispatch({type: 'SET_DEFAULT_VIEW', payload: isDefault});
            const selectedFilters = handleFiltersList(filteredList, 'property');
            dispatch({type: "SET_SELECTED_FILTERS", payload: selectedFilters});
        }
        else {
            const payload = handleFilters({target: {...filter}});
            const selectedFilters = handleFiltersList(payload);
            dispatch({type: "SET_SELECTED_FILTERS", payload: selectedFilters});
            dispatch({type: 'SET_OPTION', payload: 1});
        }
        dispatch({type: 'SET_PAGE_NUMBER', payload: 1});
        dispatch({type: 'SET_SORTING', payload: defaultSort});
    }
    const handleClearFilters = () => {
        const payload = clearFilters(state.filters);

        handlePropertyName([]);
        dispatch({type: 'EDIT_FILTER', payload: payload});
        handleQuery(payload);
        dispatch({type: 'SET_OPTION', payload: 1});
        dispatch({type: 'SET_SELECTED_FILTERS', payload: []});
        dispatch({type: 'SET_PAGE_NUMBER', payload: 1});
        dispatch({type: 'SET_SORTING', payload: defaultSort});
    }
    const handlePageNumber = (e, value) => {
        dispatch({type: 'SET_PAGE_NUMBER', payload: value});
    }
    const handleSearch = () => {
        handleLoading(true);
        setFolders();

        const body = state.query;
        servicePostList(body).then(data => {
            setTimeout(() => {
                const results = data.data.propertySearchResultList;
                dispatch({type: 'SET_RESULTS', payload: results});
                dispatch({type: 'SET_TOTAL_RESULTS', payload: data.data.totalResults});
                handleLoading(false);
            }, 500);
        }).catch(err => {
            handleLoading(false);
            handleError(err.response.headers.errormessage);
        });
    }
    const handleSearchRequest = (filtersList) => {
        if (filtersList) {
            const selectedFilters = handleFiltersList(filtersList, 'property');
            dispatch({type: "SET_SELECTED_FILTERS", payload: selectedFilters});
        }
        dispatch({type: "SET_PAGE_NUMBER", payload: 1});
        dispatch({type: 'SET_SORTING', payload: defaultSort});
    }
    const handleSearchFilter = () => {
        handlePropertyName([]);
        dispatch({type: 'SET_OPTION', payload: 1});
        handleSearchRequest(state.filters)
    }
    const handleSearchInput = (name) => {
        dispatch({type: 'SET_OPTION', payload: 0});
        if (name) {
            handlePropertyName(name);
        }
        dispatch({type: 'SET_DEFAULT_VIEW', payload: handleNameInput(name || state.propertyName)});
        handleSearchRequest({propertyName: name || state.propertyName})
    }
    const handleSearchMultipleInput = (filters) => {
        dispatch({type: 'SET_OPTION', payload: 0});
        dispatch({type: 'SET_DEFAULT_VIEW', payload: Boolean(!filters.length)});
        handleSearchRequest(filters);
    }
    const handleSearchSorted = (e) => {
        dispatch({type: "SET_PAGE_NUMBER", payload: 1});
        dispatch({type: 'SET_SORTING', payload: e});
    }
    const handlePageSize = e => {
        dispatch({type: 'SET_PAGE_SIZE', payload: parseInt(e.target.id)});
    }
    const handleSelectProperty = id => {
        dispatch({type: 'SET_PROPERTY_ID', payload: id});
        history.push(`/properties/${id}`);
    }
    const handleProfile = (e) => {
        handleLoading(true);

        serviceGetProfile(e).then(data => {
            dispatch({type: 'SET_PROFILE', payload: data.data});
            handleLoading(false);
        }).catch(err => {
            handleLoading(false);
            console.error(err);
            handleError(err.response.headers.errormessage);
        });
    }
    const handleAutoComplete = (name) => {
        servicePropertyAutocomplete(name).then(data => {
            const list = data.data.propertySearchResultList;
            dispatch({type: 'SET_AUTOCOMPLETE', payload: list});
        }).catch(err => {
            console.error(err);
            handleError(err.response.headers.errormessage);
        });
    }
    const handleClearAutoComplete = () => {
        dispatch({type: 'SET_AUTOCOMPLETE', payload: []});
    }
    const handleAddToPortfolio = (folderid, id, action) => {
        /*serviceAddPortfolio(id).then(() => {
            dispatch({type: 'ADD_TO_PORTFOLIO'});
        }).catch(err => {
            console.error(err);
            handleError(err.response.headers.errormessage);
        })*/
        const entity = 2;
        const body = { entityId: id.toString(), entityTypeId: entity, folderId: folderid }
        postPortfolioItem(body).then(() => {
            dispatch({ type: 'ADD_TO_PORTFOLIO' });
        }).catch(err => {
            console.error(err);
            handleError(err.response.headers.errormessage);
        })
    }
    const handlePortfolioOnSearch = (folderid, id, action) => {
        const entity = 2;
        const body = { entityId: id.toString(), entityTypeId: entity, folderId: folderid }
        if (!action) {
            postPortfolioItem(body).then(() => {
                const updatedResults = managePortfolioSearch(state.results, id);
                dispatch({type: 'SET_RESULTS', payload: updatedResults});
            }).catch(err => {
                console.error(err);
                handleError(err.response.headers.errormessage);
            })
        } else {
            deletePortfolioItem(body).then(() => {
                const updatedResults = managePortfolioSearch(state.results, id);
                dispatch({type: 'SET_RESULTS', payload: updatedResults});
            }).catch(err => {
                console.error(err);
                handleError(err.response.headers.errormessage);
            })
        }
    }

    const providerValue = {
        ...state,
        setFilters,
        removeFilter,
        handleFilters,
        handlePropertyName,
        handlePageNumber,
        handlePageSize,
        handleSearch,
        handleSearchInput,
        handleClearFilters,
        handleSearchFilter,
        handleSelectProperty,
        handleProfile,
        handleAutoComplete,
        handleClearAutoComplete,
        handleAddToPortfolio,
        handleSearchSorted,
        handleSearchMultipleInput,
        handlePortfolioOnSearch,
        setFolders
    }

    return (
        <PropertyContext.Provider value={providerValue}>
            {children}
        </PropertyContext.Provider>
    )
}

export const useProperty = () => useContext(PropertyContext);