import React from 'react';
import { FormGroup, FormLabel } from '@mui/material';
import Dropdown from '../atoms/Dropdown';
import Input from '../atoms/Input';
import Text from '../atoms/Text';
import { data } from '../../../data/formLabel.json'
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

const marks = [
    {
        value: 0,
        label: '0',
    },
    
    {
        value: 500000,
        label: '$500000',
    },
];
function valuetext(value1) {
    return `${value1} USD`;
}
export default function GroupInput({type, list, id, page, input, value, handleInput, MarketCap_Slider_val}) {
    const { label, placeholder } = data[page][input];

    const [value1, setValue] = React.useState(MarketCap_Slider_val);
    
    const [rangestart, setRangeStart] = React.useState('From');
    const [rangeend, setRangeEnd] = React.useState('To');

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setRangeStart("$"+newValue[0]);
        setRangeEnd("$"+newValue[1]);
        handleInput(event);
    };

    React.useEffect(() => {
        if (MarketCap_Slider_val !== undefined) {
            setValue(MarketCap_Slider_val);
            if (MarketCap_Slider_val[0] === 0 && MarketCap_Slider_val[1] === 500000) {
                setRangeStart('From');
                setRangeEnd('To');
            }
        }
    }, [MarketCap_Slider_val]);

    return (
        <FormGroup sx={{marginBottom: id.includes("Country") ? '0px' : '24px', maxWidth: '238px'}} id={`${id}-group`}>
            {type === 'Dropdown' ? (
                <Dropdown
                    value={value}
                    options={list}
                    id={input}
                    label={label}
                    placeholder={placeholder}
                    handleInput={handleInput}
                />
            )
            : id === 'stockExchangeList' ?
                <Dropdown
                    id={input}
                    multiple
                    value={value}
                    options={list}
                    label={label}
                    placeholder={placeholder}
                    handleInput={handleInput}
                />
            : type === "Slider" ? 
                <React.Fragment>
                    <FormLabel sx={{textTransform: 'capitalize', marginBottom: '5px'}}>
                                <Text fontFamily='ff1' fontWeight='600' fontSize='1rem' color='#000000'>{label}</Text>
                                
                    </FormLabel>
                            <Box >
                                <div className='rangevalue'>
                                    <div className='rangefrom'>{rangestart}</div> -
                                    <div className='rangefrom rangeto'>{rangeend}</div>
                                    USD Millions
                                </div>
                        <Slider
                            id="MarketCap_Slider"
                            name="MarketCap_Slider"
                            getAriaLabel={() => 'Marketcap range'}
                            value={value1}
                            onChange={handleChange}
                            valueLabelDisplay="off"
                            getAriaValueText={valuetext}
                            min={0}
                            max={500000}
                            marks={marks}
                                    sx={{
                                        color:"#9e9e9e",
                                        '& .MuiSlider-thumb': {
                                            color: "#2D70DA",
                                            borderRadius:"4px",
                                        },
                                        '& .MuiSlider-track': {
                                            color: "#5F9EE8",
                                            height: "8px",
                                            borderRadius: "2px",
                                        },
                                        '& .MuiSlider-rail': {
                                            color: "#000",
                                            height: "8px",
                                            borderRadius: "2px",
                                        },
                                        '& .MuiSlider-active': {
                                            color: "#000",
                                            height: "8px",
                                            borderRadius: "2px",
                                        }
                                    }}
                                   
                        />
                                
                                
                    </Box>
                </React.Fragment>
            :(
                <React.Fragment>
                    <FormLabel sx={{textTransform: 'capitalize', marginBottom: '5px'}}>
                        <Text fontFamily='ff1' fontWeight='600' fontSize='1rem' color='#000000'>{label}</Text>
                    </FormLabel>
                    {list.map((single, index) => (
                        <Input
                            type={type}
                            id={input}
                            input={single}
                            key={`${id}-${index}`}
                            placeholder={placeholder}
                            iconClick={handleInput}
                            />
                    ))}
                </React.Fragment>
            )}
        </FormGroup>
    )
}
