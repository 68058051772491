export const appReducer = (state, action) => {
    switch (action.type) {
        case "SET_LOADING":
            return {
                ...state,
                isLoading: action.payload
            }
        case "SET_ERROR":
            return {
                ...state,
                isError: true,
                errorMessage: action.payload
            }
        case "SET_AUTH": 
            return {
                ...state,
                user: {
                    ...state.user,
                    ...action.payload
                },
            }
        case "SET_NAME": 
            return {
                ...state,
                user: {
                    ...state.user,
                    name: action.payload,
                },
            }
        case "SET_LOADING_AUTH":
            return {
                ...state,
                isLoadingAuth: action.payload
            }
        default:
            return state;
    }
}