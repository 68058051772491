import React, { useEffect, useState } from 'react';
import { Autocomplete, Checkbox, FormControl, ListItemText, Tooltip, MenuItem, Select, styled, TextField } from '@mui/material'
import Text from './Text';

const StyledSelect = styled(Select)(() => ({
    '& .MuiInputBase-root': {
        width: '100%'
    },
    '& legend': {
        width: 0
    }
}))

const StyledTextField = styled(TextField)(() => ({
    '& .MuiFilledInput-root': {
        paddingTop: 6,
        paddingBottom: '6px !important',
    }
}))

const liStyles = {
    color: '#1E2326',
    fontSize: '0.875rem',
    fontFamily: 'Roboto, sans-serif',
    fontWeight: 500,
    paddingTop: '6px',
    paddingBottom: '6px',
    paddingLeft: '15px',
}

function LabelStyled  ({children}) {
    return (
        <Text fontFamily='ff1' fontWeight={600} fontSize='1rem' marginBottom='10px' lineHeight='1' textTransform='capitalize'>{children}</Text>
    )
}

export default function Dropdown({ value, options, id, label, placeholder, multiple, handleInput}) {
    const [ multipleValue, setMultipleValue ] = useState([]);
    const [ valueAutocomplete, setValueAutocomplete ] = useState(null);
    const [openTooltip, setOpenTooltip ] = useState(false);

    const handleOpenTooltip = () => {
        if (valueAutocomplete) {
            setOpenTooltip(true);
        }
    }

    const handleCloseTooltip = () => {
        setOpenTooltip(false);
    }

    useEffect(() => {
        if (Array.isArray(value)) {
            const mappedValue = value.map(mapped => mapped.selectedValue);
            setMultipleValue(mappedValue);
        }
    }, [value]);

    useEffect(() => {
        if (value === '' ) {
            setValueAutocomplete(null);
        }
      }, [value]);

    if (options.length >= 3 && id !== 'stockExchangeList_Checkbox' && id !== 'industry') {
        return (
            <FormControl>
                <label>
                    <LabelStyled>{label}</LabelStyled>
                    <Tooltip title={valueAutocomplete ? valueAutocomplete.name : ''} placement='top' arrow open={openTooltip} onOpen={handleOpenTooltip} onClose={handleCloseTooltip}>
                        <Autocomplete
                            value={valueAutocomplete}
                            size="small"
                            onChange={(event, newValue) => {
                                setValueAutocomplete(newValue);
                            }}
                            onInputChange={(event, newInputValue) => {
                                handleInput({target: {id: id, value:newInputValue}})}
                            }
                            options={options}
                            getOptionLabel={(option) => option.name}
                            id="controllable-states-demo"
                            sx={{ width: 238 }}
                            ListboxProps={{ sx: {backgroundColor: '#FCFAFA', '& li': {...liStyles} }}}
                            renderInput={(params) => <StyledTextField {...params} placeholder={placeholder} variant="filled" hiddenLabel />}
                            renderOption={(props, option) => {
                                return (
                                  <li {...props} name={option.selectedValue}>
                                    <Text>
                                      {option.name}
                                    </Text>
                                  </li>
                                );
                              }}
                        />
                    </Tooltip>
                </label>
            </FormControl>
        )
    }
    if (multiple) {
        return (
            <FormControl sx={{ maxWidth: 238, '& .MuiInputBase-root': {width: '100%'} }} size="small" fullWidth variant="filled">
                <label>
                    <LabelStyled>{label}</LabelStyled>
                    <StyledSelect
                        value={multipleValue}
                        id={id}
                        displayEmpty
                        multiple
                        labelId={`&{id}-label`}
                        name={id}
                        onChange={(e) => handleInput(e)}
                        MenuProps={{PaperProps: {sx: {maxHeight: 265, '& ul' : { backgroundColor: '#FCFAFA'}}}}}
                        renderValue={(value) => (!value.length ? placeholder : value.join(', '))}
                        sx={{ '& .MuiSelect-filled': {color: '#1E2326'} }}
                    >
                        <MenuItem disabled value='' sx={{display: 'none'}}>{label}</MenuItem>
                        {options.map((option, index) => (
                            <MenuItem key={index} value={option.selectedValue}>
                                <Checkbox checked={multipleValue.indexOf(option.selectedValue) > -1} />
                                <ListItemText primary={option.selectedValue} />
                            </MenuItem>
                        ))}
                    </StyledSelect>
                </label>
            </FormControl>
        )
    }
    else {
        return (
            <FormControl sx={{ maxWidth: id === 'industry' ? '100%' : 238, '& .MuiInputBase-root': {width: '100%'} }} size="small" fullWidth variant="filled">
                <label>
                    <LabelStyled>{label}</LabelStyled>
                    <StyledSelect
                        value={typeof value === 'object' ? value.value : value}
                        id={id}
                        displayEmpty
                        labelId={`&{id}-label`}
                        name={id}
                        onChange={(e) => handleInput(e)}
                        MenuProps={{PaperProps: {sx: {maxHeight: 265, '& ul' : { backgroundColor: '#FCFAFA'}}}}}
                        renderValue={(val) => (val === '' ? placeholder : typeof value === 'object' ? value.label : value)}
                        sx={{ '& .MuiSelect-filled': {color: '#1E2326'} }}
                    >
                        <MenuItem disabled value='' sx={{display: 'none'}}>{label}</MenuItem>
                        {options.map((option, index) => (
                            <MenuItem value={option.selectedValue} sx={{...liStyles}} key={index}>{option.name}</MenuItem>
                        ))}
                    </StyledSelect>
                </label>
            </FormControl>
        )
    }
}
