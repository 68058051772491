import axios from "axios";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export const authGet = async (url) => {
    const token = cookies.get('token');
    return await axios.get(url, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    })
}
export const authPost = async (url, params) => {
    const token = cookies.get('token');
    return await axios.post(url, params, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    })
}
export const authPut = async (url, params) => {
    const token = cookies.get('token');
    return await axios.put(url, params, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    })
}
export const authDelete = async (url, params) => {
    const token = cookies.get('token');
    return await axios.delete(url, {
        data: params,
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    })
}