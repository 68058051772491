import { postMinePersonnelList } from '../../../services/postMinePersonnelList';
import { getMinePersonnelAutocomplete } from '../../../services/getMinePersonnelAutocomplete';
import { getCompanyFolders } from "../../../services/getCompanyFolders"

export const servicePostList = query => {
    return postMinePersonnelList(query);
}

export const serviceMinePersonnelAutocomplete = name => {
    return getMinePersonnelAutocomplete(name);
}

export const serviceGetFolders = (EntityId) => {
    return getCompanyFolders(EntityId);
}
