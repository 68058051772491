import React, { useEffect, useRef } from 'react';
import {content} from '../../../lib/adButler';
import postscribe from 'postscribe';
import { styled } from '@mui/material';

const StyledAdWrapper = styled('div')(() => ({
    backgroundColor: '#fff',
    padding: '5px',
    border: '1px solid #413F3F',
    '&.hidden': {
        display: 'none'
    }
}))

export default function Ad() {
    const adRef = useRef(null);
    const adWrapperRef = useRef(null);

    useEffect(() => {
        postscribe('#ad', content, {
            done: () => {
                const adChildren = Array.from(adRef.current.children);
                const findImage = adChildren.find(child => child.classList.contains('adb-placeholder-blank'));
                if (findImage) {
                    const adWrapper = adWrapperRef.current;
                    adWrapper.className += ' hidden';
                }
            },
            error: () => {
                    const adWrapper = adWrapperRef.current;
                    adWrapper.className += ' hidden';
                return;
            }
        });
    }, []);

    return (
        <StyledAdWrapper ref={adWrapperRef}>
            <div id="ad" ref={adRef}></div>
        </StyledAdWrapper>
    )
}
