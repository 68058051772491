import React from 'react'
import Button from '../atoms/Button'

export default function SortButton({selected, handleClick, field}) {
  return (
    <div className='sort-btns'>
        <Button
            type='icon'
            placement={`${selected === 0 && 'selected'} sort`}
            handleClick={() => handleClick({fieldName: field, direction: 0})}
            iconProps={{
              baseClassName: 'fa-solid',
              className: 'fa-caret-up',
              sx: {fontSize: '14px'}
            }}
        />
        <Button
            type='icon'
            placement={`${selected === 1 && 'selected'} sort sort-desc`}
            handleClick={() => handleClick({fieldName: field, direction: 1})}
            iconProps={{
              baseClassName: 'fa-solid',
              className: 'fa-caret-down',
              sx: {fontSize: '14px'}
            }}
        />
    </div>
  )
}
