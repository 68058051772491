import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { XAxis, YAxis, HorizontalGridLines, LineSeries, AreaSeries, FlexibleXYPlot } from 'react-vis';
import { format, subYears } from "date-fns";
import '../../../../node_modules/react-vis/dist/style.css';

import Dropdown from '../atoms/Dropdown';
import Input from '../atoms/Input';
import Loading from '../atoms/Loading';
import Table from '../atoms/Table';
import Text from '../atoms/Text';

import Card from './Card';

const initialData = {
    '1w': { data: [], ticks: 7, timeFormat: 'MMM dd', value: '1w', label: '1 wk'},
    '1m': { data: [], ticks: 10, timeFormat: 'MMM dd', value: '1m', label: '1 mo'},
    '3m': { data: [], ticks: 3, timeFormat: 'MMM, yyy', value: '3m', label: '3 mo'},
    '6m': { data: [], ticks: 6, timeFormat: 'MMM, yyy', value: '6m', label: '6 mo'},
    '1y': { data: [], ticks: 12, timeFormat: 'MMM, yyy', value: '1y', label: '1 yr'},
    '5y': { data: [], ticks: 5, timeFormat: 'MMM, yyy', value: '5y', label: '5 yr'},
    '10y': { data: [], ticks: 10, timeFormat: 'MMM, yyy', value: '10y', label: '10 yr'}
}
export default function Chart({ graphId, stocks, handleStock, priceTable, fundamentalTable, currency }) {
    const [ graphData, setGraphData ] = useState(initialData);
    const [ timeSelected, setTimeSelected ] = useState('10y');
    const [ dataLabel, setDataLabel ] = useState({oldest: '', newest: ''});
    const [ isLoading, setIsLoading ] = useState(true);
    
    useEffect(() => {
        if (graphId.value !== 0) {
            setIsLoading(true);

            const API_URL = `https://www.infomine.com/chartsanddata/graphfactory.ashx?g=${graphId.value}&dr=1w%7C1m%7C3m%7C6m%7C1y%7C5y%7C10y&z=json&c=true`;
            fetch(API_URL)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    }
                    else {
                        throw new Error ('something went wrong');
                    }
                })
                .then(response => {
    
                    let newData = {...graphData};
                    response.map((entry, index) => {
                        if (!entry['DataTables'].length) {
                            const key = Object.keys(graphData)[index];
                            newData[key].disabled = true; 
                            return entry;
                        }
                        else {
                            const results = entry["DataTables"][0]
                            .filter((r) => r.Value !== null && r.Value !== '1.7E+308' && r.Value !== 1.7e+308)
                            .map((d) => ({
                                x: new Date(d.Date),
                                y: d.Value  
                            }));
                            newData[entry.DateRangeShortForm].data = results;
    
                            const firstYear = results[0].x.getFullYear();
                            let ticks;
                            if (entry.DateRangeShortForm === '5y') {
                                const yearsAgo = subYears(new Date(), 5).getFullYear();
                                ticks = firstYear > yearsAgo ? 8 : 5;
                                newData[entry.DateRangeShortForm].ticks = ticks;
                            }
                            if (entry.DateRangeShortForm === '10y') {
                                const yearsAgo = subYears(new Date(), 10).getFullYear();
                                ticks = firstYear > yearsAgo ? 8 : 10;
                                newData[entry.DateRangeShortForm].ticks = ticks;
    
                                const oldestData = format(results[0].x, 'MMM dd, yyyy');
                                const newestData = format(new Date(entry['LatestPriceDate']), 'MMM dd, yyyy');
                                setDataLabel({oldest: oldestData, newest: newestData});
                            }
                            return entry;
                        }
                    });
                setGraphData(newData);
                setIsLoading(false);
                });
        }
        else {
            setIsLoading(false);
        }
        // eslint-disable-next-line
    }, [graphId]);
        
    const handleChange = (e) => {
        setTimeSelected(e.target.value);
    }

    return (
        <Card
            
            headerAction={
                <Dropdown
                    value={graphId}
                    options={stocks}
                    placeholder='Select a stock'
                    label='Stock'
                    id='select-stock-list'
                    handleInput={handleStock}
                />}
            >
            {graphId.value === 0 ? (
                <Text>No data to display</Text>
            ) : !isLoading ? (
                <React.Fragment>
                    <Grid container spacing={3} className='graph-actions'>
                        <Grid item md={12} lg={4}>
                            <Text fontWeight='400' fontFamily='ff2' color='#808183' fontSize='0.875rem'>Oldest data registered on {dataLabel.oldest}</Text>
                            <Text fontWeight='400' fontFamily='ff2' color='#808183' fontSize='0.875rem'>Latest data registered on {dataLabel.newest}</Text>
                            <Text fontWeight='400' fontFamily='ff2' color='#808183' fontSize='0.875rem'>Currency {currency.currency}</Text>
                        </Grid>
                        <Grid item md={12} lg={8} sx={{paddingTop: '16px', textAlign: {md: 'center', lg: 'right'} }}>
                            <Input
                                type='Radio'
                                value={timeSelected}
                                aria='time options'
                                name='time-options'
                                handleChange={handleChange}
                                options={Object.values(graphData)}
                            />
                        </Grid>
                    </Grid>
                    <hr />
                    <div style={{ width: '100%', height: '240px'}}>
                        <FlexibleXYPlot xType='time'>
                            <HorizontalGridLines style={{strokeDasharray: 4}} />
                            <LineSeries
                                curve={null}
                                data={graphData[timeSelected].data}
                                opacity={1}
                                strokeStyle="solid"
                                style={{strokeWidth: 2}}
                                color={'#A7D8F4'}
                                
                            />
                            <AreaSeries data={graphData[timeSelected].data} stroke='transparent' color={'#DBEFFB'} />
                            <XAxis
                                tickTotal={graphData[timeSelected].ticks}
                                tickFormat={(d, i) => {     
                                    return `${format(new Date(d), graphData[timeSelected].timeFormat)}`;        
                                }}
                            />
                            <YAxis
                                orientation='right'
                                tickPadding={-25}
                                tickSizeOuter={0}
                            />
                        </FlexibleXYPlot>
                    </div>

                    <div className='stock-tables'>
                        <Table table={priceTable} type='stock' headerText='Price Data' />
                        <Table table={fundamentalTable} type='stock' headerText='Fundamental Data' />
                    </div>
                </React.Fragment>
            ) : (
                <Loading />
            )}
        </Card>
    )
}