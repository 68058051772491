export const definePortfolioCheckbox = data => {
    let defined = {...data};
    Object.entries(data).map(([key, value]) => {
        let definedList = value.map(list => {
            let obj = {isSelected: false, ...list};
            delete obj.note;
            return obj;
        });
        return defined[key] = definedList;
    })
    return defined;
}