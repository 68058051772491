export const dropdownSelect = (filter, selected) => {
    const newState = filter;
    const id = selected.name || selected.id;
    newState[id].map(option => {
        let isSelected = option.selectedValue === selected.value;
        isSelected ? option.isSelected = !option.isSelected : option.isSelected = isSelected;

        return option;
    });
    return newState;
}