import React, { useEffect } from 'react';
import Chart from 'react-apexcharts'
import Button from '../atoms/Button'
function DountChart(props) {

    const [statusLabel, setStatusLabel] = React.useState([]);
    const [statusNumber, setStatusNumber] = React.useState([]);

    const [countryLabel, setCountryLabel] = React.useState([]);
    const [countryNumber, setCountryNumber] = React.useState([]);

    const [commodityLabel, setCommodityLabel] = React.useState([]);
    const [commodityNumber, setCommodityNumber] = React.useState([]);

    const [isLoading, setIsLoading] = React.useState(true);

    const [showResultsChart, setShowResultschart] = React.useState("chart_status")


    async function getItemsStatus(input) {
        var arr = input, obj = {};
        for (var i = 0; i < arr.length; i++) {
            if (!obj[arr[i].status]) {
                obj[arr[i].status] = 1;
            } else if (obj[arr[i].status]) {
                obj[arr[i].status] += 1;
            }
        }
        return obj;
    }

    async function getItemsCountry(input) {
        var arr = input, obj = {};
        for (var i = 0; i < arr.length; i++) {
            if (!obj[arr[i].country]) {
                obj[arr[i].country] = 1;
            } else if (obj[arr[i].country]) {
                obj[arr[i].country] += 1;
            }
        }
        return obj;
    }

    async function getItemsCommodity(input) {
        var arr = input, obj = {};
        for (var i = 0; i < arr.length; i++) {
            if (!obj[arr[i].commodity]) {
                obj[arr[i].commodity] = 1;
            } else if (obj[arr[i].commodity]) {
                obj[arr[i].commodity] += 1;
            }
        }
        return obj;
    }

    const fetchData = async () => {

        const statusData = await getItemsStatus(props.datalist);
        const commodityData = await getItemsCommodity(props.datalist);
        const countryData = await getItemsCountry(props.datalist);
        
        setStatusLabel(Object.keys(statusData));
        setStatusNumber(Object.keys(statusData).map((key) => statusData[key]));

        setCountryLabel(Object.keys(countryData));
        setCountryNumber(Object.keys(countryData).map((key) => countryData[key]));

        setCommodityLabel(Object.keys(commodityData));
        setCommodityNumber(Object.keys(commodityData).map((key) => commodityData[key]));

        setIsLoading(false);

    }
    
    useEffect(() => {
        fetchData();
    }, [])


  return (
    <React.Fragment>

          {!isLoading && statusLabel && showResultsChart == "chart_status" && <div id='chart_status'>
            
              <Chart
                  type="donut"
                  width={'100%'}
                  height={1000}
                  series={statusNumber}

                  options={{
                      labels: statusLabel, 

                      title: {
                          text: "Property Summary Report - Status"
                      },
                      legend: {
                          position: 'bottom',
                      },
                      dataLabels: {
                          formatter: function (val, opts) {
                              return opts.w.config.series[opts.seriesIndex]
                          },
                      },
                      plotOptions: {
                          pie: {
                              donut: {
                                  labels: {
                                      show: true,
                                      total: {
                                          show: true,
                                          showAlways: true,
                                          fontSize: 16,

                                      }
                                  }
                              }
                          }
                      }

                  }}                  

              />

          </div>}

          {!isLoading && commodityLabel && showResultsChart == "chart_commodity" && <div id='chart_commodity'>
              <Chart
                  type="donut"
                  width={'100%'}
                  height={1000}
                  series={commodityNumber}

                  options={{
                      labels: commodityLabel,

                      title: {
                          text: "Property Summary Report - Commodity"
                      },
                      legend: {
                          position: 'bottom',
                      },
                      dataLabels: {
                          formatter: function (val, opts) {
                              return opts.w.config.series[opts.seriesIndex]
                          },
                      },
                      plotOptions: {
                          pie: {
                              donut: {
                                  labels: {
                                      show: true,
                                      total: {
                                          show: true,
                                          showAlways: true,
                                          fontSize: 16,

                                      }
                                  }
                              }
                          }
                      }

                  }}

              />

          </div>}

          {!isLoading && countryLabel && showResultsChart == "chart_country" && <div id='chart_country'>
              <Chart
                  type="donut"
                  width={'100%'}
                  height={1000}
                  series={countryNumber}

                  options={{
                      labels: countryLabel,

                      title: {
                          text: "Property Summary Report - Country"
                      },
                      legend: {
                          position: 'bottom',
                      },
                      dataLabels: {
                          formatter: function (val, opts) {
                              return opts.w.config.series[opts.seriesIndex]
                          },
                      },
                      plotOptions: {
                          pie: {
                              donut: {
                                  labels: {
                                      show: true,
                                      total: {
                                          show: true,
                                          showAlways: true,
                                          fontSize: 16,

                                      }
                                  }
                              }
                          }
                      }

                  }}

              />

          </div>}

          <Button className='mt-2 mr-2' onClick={() => setShowResultschart("chart_status")}>Status</Button>
          <Button className='mt-2 mr-2' onClick={() => setShowResultschart("chart_commodity")}>Commodity</Button>
          <Button className='mt-2' onClick={() => setShowResultschart("chart_country")}>Country</Button>
</React.Fragment>

  )
}

DountChart.defaultProps = {
    datalist: { commodity :"Uranium", country:"Canada", interest:"100.0",propertyId:69825, propertyName:"Cable", province:"Saskatchewan", status:"Prospect" }
};

export default DountChart
