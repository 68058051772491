import React from 'react';
import { Icon, Paper, styled, Table as MUITable, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material'

import Button from '../atoms/Button';
import Input from '../atoms/Input';
import Link from '../atoms/Link';
import Text from '../atoms/Text';
import SortButton from './SortButton';

import { tableData } from '../../../lib/tableData';
import { addSpace } from '../../../lib/addSpace';
import data from '../../../data/sortableColumn.json';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { Alert } from '@mui/material';


const StyledTable = styled(TableContainer)(() => ({
  '& thead th': {
    backgroundColor: '#E6E7E8',
    textTransform: 'uppercase',
  }
}))
const StyledContainer = styled('div')(() => ({
  display: 'flex'
}));


export default function List({ addToPortfolio, list, listFolder, searchType, type, listId, select, page, sortSelected, handleSort, handlePortfolioFolder, handlePortfolioFolderItem, addToPortfolioMove, removebookmark }) {

    const { headers, content } = tableData(list, searchType);
    var currentData;
    
    const [opendi, setOpen] = React.useState(false);
    const [opendmove, setOpenMove] = React.useState(false);
    const [mfolderid, setmFolderId] = React.useState("");
    const [mfolderName, setmFolderName] = React.useState("");
    const [mprofileLink, setmProfileLink] = React.useState("");
    const [misPortfolioItem, setmIsPortfolio] = React.useState("");
    const [openAlertDelete, setOpenAlertDelete] = React.useState(false);
    const [openAlertDeleteItem, setOpenAlertDeleteItem] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");

    const [delfolderid, setDelFolderId] = React.useState("");
    const [delitemtype, setDelItemType] = React.useState("");
    const [delentityid, setDelEntiryId] = React.useState("");
    

    const handleClickOpen = async (mprofileLinkk, misPortfolioItemm) => {
        setmProfileLink(mprofileLinkk);
        setmIsPortfolio(misPortfolioItemm);
        setOpen(true);
    };

    const handleClickOpenMove = async (mprofileLinkk, mfolderName) => {
        setmProfileLink(mprofileLinkk);
        setmFolderName(mfolderName);
        setOpenMove(true);
    };

    const handleClose = () => {
        setOpen(false);
        setErrorMessage("");
    };

    const handleCloseMove = () => {
        setOpenMove(false);
        setErrorMessage("");
    };
    
    const handleAddToPortfolio = () => {
        if (mfolderid == "") {
            setErrorMessage("Please select folder");
        }
        else {
            addToPortfolio(mfolderid, mprofileLink, misPortfolioItem);
            handleClose();
        }
        
    }

    const handleAddToPortfolioMove = () => {
        if (mfolderid == "") {
            setErrorMessage("Please select folder");
        }
        else {
            addToPortfolioMove(mprofileLink, mfolderid);
            handleCloseMove();
        }

    }

    const handleFolderDelete = (folderid) => {
        setDelFolderId(folderid);
        setOpenAlertDelete(true);
    }

    const handleFolderDeleteFinal = () => {
        const data = {
            "folderId": parseInt(delfolderid)
        }

        handlePortfolioFolder(data);
        setOpenAlertDelete(false);

    }

    const handleFolderItemDelete = (itemtype, dentityId, dfolderId) => {

        setDelItemType(itemtype);
        setDelEntiryId(dentityId);
        setDelFolderId(dfolderId);
        setOpenAlertDeleteItem(true);
    }

    const handleFolderItemDeleteFinal = () => {
        const data = {
            "entityId": delentityid.toString(),
            "entityTypeId": delitemtype,
            "folderId": delfolderid
        }
        handlePortfolioFolderItem(data);
        setOpenAlertDeleteItem(false);
    }
 
  if (type === "portfolio") {

    const renderedValue = (row, value) => {
      let rendered = value;
      if (row === 'added') {
        rendered = new Date(value).toLocaleDateString('en-us', {month: 'short', day: 'numeric', year: 'numeric'})
      }
      if (row === 'commodity') {
        rendered = addSpace(value);
      }
      return rendered;
    }

    return (
        <>
            {openAlertDelete &&
                <Alert icon={false} severity="warning" className='alrt'>
                    <span>Are you sure you want to delete the folder?</span>
                    <span>
                        <Button variant="text" onClick={() => handleFolderDeleteFinal()}>Yes</Button>
                        <Button variant="text" onClick={() => setOpenAlertDelete(false)}>No</Button>
                    </span>

                </Alert>}

            {openAlertDeleteItem &&
                <Alert icon={false} severity="warning" className='alrt'>
                    <span>Are you sure you want to delete the item?</span>
                    <span>
                        <Button variant="text" onClick={() => handleFolderItemDeleteFinal()}>Yes</Button>
                        <Button variant="text" onClick={() => setOpenAlertDeleteItem(false)}>No</Button>
                    </span>

                </Alert>}    
        {listFolder.map((elementFolder, indexFolder) => {
            currentData = content.filter((element) => element.ids.folderId === elementFolder.folderId);
            return (
                <>
                    <div className='customefieldhe'>
                        <Text fontFamily='ff1' color='#fff' fontWeight='700' fontSize='1.15rem'><span style={{ lineHeight: "40px" }}>{elementFolder.folderName}</span></Text>
                        {elementFolder.folderId === 0 ? "" : <Button
                            type="icon"
                            tooltip="Remove Selected"
                            iconProps={{
                                sx: { color: "#fff" },
                                baseClassName: "fa-solid",
                                className: "fa-trash-can",
                            }}
                            handleClick={() => handleFolderDelete(elementFolder.folderId)}
                        />}
                    </div>
                    <StyledTable component={Paper} className={`${type}-page-table`}>
                        <MUITable aria-label="results-table" className='portfoliotableCompany'>
                  {currentData.length > 0 ? <TableHead>
                        <TableRow>
                          {headers.map((header) => {
                            if (header.value === 'is Selected') {
                                return (<TableCell key='checkbox'></TableCell>)
                            }
                            else if (header.value === 'portfolio Type') {
                            }
                            else if (header.value === 'folder') {
                                return (
                                    <TableCell key='delete'></TableCell>
                                )
                            }
                              else if (header.value !== "folder"){
                               return (
                                <TableCell key={`header-${header.key}`}>
                                  <Text textTransform='uppercase' fontFamily='ff1' color='#808183' fontWeight='700' fontSize='1rem'>{header.value}</Text>
                                </TableCell>
                          )}})}
                        </TableRow>
                      </TableHead> : ""}    
                  
                      <TableBody>
                        {currentData.length > 0 ? content.filter((element) => element.ids.folderId === elementFolder.folderId).map((element, index) => (
                          <TableRow key={`element-${index}`}>
                            {Object.entries(element.data).map(([key, value]) => {
                                const profileLink = element.ids['companyId'] || element.ids['propertyId'];
                                const itemtype = (element.ids['companyId'] ? 1 : 2);
                                if (key === 'isSelected') {
                                    return (<TableCell key={`checkbox-${index}`} style={{ paddingRight: '10px', }}><Tooltip title={'Move to Different Folder'}>
                                        <Button
                                            type='icon'
                                            iconProps={{
                                                color: 'primary',
                                                sx: { fontSize: '22px' },
                                                baseClassName: element.data.isPortfolioItem ? 'fa-solid' : 'fa-regular',
                                                className: 'fa-arrows-up-down'

                                            }}
                                            handleClick={() => handleClickOpenMove(element.ids['portfolioId'], element.data.folderName)}
                                        />
                                    </Tooltip></TableCell>)
                                }
                                else if (key === 'portfolioType') {

                                }
                                else if (key === 'folderName') {
                                    return (
                                        <TableCell key={`checkbox-${index}`} style={{ paddingRight: '10px', } }>
                                            <span style={{ display: 'flex', justifyContent: 'flex-end',  }}>
                                                <Button
                                                    type="icon"
                                                    tooltip="Remove Selected"
                                                    iconProps={{
                                                        sx: { color: "#193A42" },
                                                        baseClassName: "fa-solid",
                                                        className: "fa-trash-can",
                                                    }}
                                                    handleClick={() => handleFolderItemDelete(itemtype, profileLink, element.ids['folderId'])}
                                                />
                                            </span>                               
                                        </TableCell>
                                    )
                                }
                                else if (key !== 'folderName') {
                                    return (
                                        <TableCell key={`${key}-${value}`}>
                                            {(key.includes('companyName') || key.includes('propertyName')) ?
                                                <Link openTab link={`/${page}/${profileLink}`} type={`table ${page}`}>{value}</Link>
                                                : <Text fontSize='1rem'>{renderedValue(key, value)}</Text>}

                                        </TableCell>
                                    )
                                }
                            })}
                            </TableRow>
                        )) : <TableRow><TableCell><div style={{ padding: "12px 20px", textAlign: 'center' }}><Text>No data saved! Add them to your portfolio</Text></div></TableCell></TableRow>}
                      </TableBody>
                    </MUITable>
                </StyledTable>
            </>
                )
            }


            )}

            {
                listFolder &&
                <Dialog open={opendmove} onClose={handleCloseMove}>
                    <DialogTitle>Select Custom Field</DialogTitle>
                    <DialogContent>
                        {errorMessage && <Text><span className="errormessage">{errorMessage}</span></Text>}
                        <FormControl>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                            >
                                {listFolder.map((element, index) => {
                                    if (mfolderName !== element.folderName) {
                                        return (
                                            <FormControlLabel key={`element-${index}`} value={element.folderId} control={< Radio />} label={element.folderName} onClick={(e) => setmFolderId(e.target.value)} />
                                        )
                                    }
                                })}
                            </RadioGroup>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseMove}>Cancel</Button>
                        <Button onClick={() => handleAddToPortfolioMove()}>Save</Button>
                    </DialogActions>
                </Dialog>
            }
        </>
    );
  }
  else if (searchType === 'personnel') {
    return (
        <>
        <StyledTable component={Paper} className={`${type}-page-table`}>
      <MUITable aria-label="results-table">
        <TableHead>
          <TableRow>
              <TableCell sx={{padding: '12px'}}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <Text textTransform='uppercase' fontFamily='ff1'  color='#808183' fontWeight='700' display='inline-block'>Person</Text>
                  <SortButton selected={'PersonFullName ' === sortSelected.fieldName && sortSelected.direction} field='PersonFullName ' handleClick={handleSort} />
                </div>
              </TableCell>
              <TableCell sx={{padding: '12px'}}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <Text textTransform='uppercase' fontFamily='ff1'  color='#808183' fontWeight='700' display='inline-block'>Property</Text>
                  <SortButton selected={'PropertyDisplayName' === sortSelected.fieldName && sortSelected.direction} field='PropertyDisplayName' handleClick={handleSort} />
                </div>
              </TableCell>
              <TableCell sx={{padding: '12px'}}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <Text textTransform='uppercase' fontFamily='ff1'  color='#808183' fontWeight='700' display='inline-block'>Position</Text>
                  <SortButton selected={'JobTitle' === sortSelected.fieldName && sortSelected.direction} field='JobTitle' handleClick={handleSort} />
                </div>
              </TableCell>
              <TableCell sx={{padding: '12px'}}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <Text textTransform='uppercase' fontFamily='ff1'  color='#808183' fontWeight='700' display='inline-block'>Country</Text>
                  <SortButton selected={'CountryName' === sortSelected.fieldName && sortSelected.direction} field='CountryName' handleClick={handleSort} />
                </div>
              </TableCell>
              <TableCell sx={{padding: '12px'}}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <Text textTransform='uppercase' fontFamily='ff1'  color='#808183' fontWeight='700' display='inline-block'>Contact</Text>
                </div>
              </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {content.map((element, index) => (
            <TableRow key={`element-${index}`}>
                <TableCell sx={{textAlign: 'left', padding: '12px'}}>
                      <StyledContainer sx={{ alignItems: 'center' }}>
                      {/*addToPortfolio && element.data.isPortfolioItem ? (
                          <Button
                            type='icon'
                            iconProps={{
                              color: 'primary',
                              sx: { fontSize: '22px' },
                              baseClassName: element.data.isPortfolioItem ? 'fa-solid' : 'fa-regular',
                              className: 'fa-bookmark'
                            }}
                            disabled
                          />
                      ) : (
                      <Tooltip title={element.data.isPortfolioItem ? 'Remove from Portfolio' : 'Add to Portfolio'}>
                          <Button
                            type='icon'
                            iconProps={{
                              color: 'primary',
                              sx: { fontSize: '22px' },
                              baseClassName: element.data.isPortfolioItem ? 'fa-solid' : 'fa-regular',
                              className: 'fa-bookmark'
                            }}
                            handleClick={() => handleClickOpen(element.ids.propertyId, element.data.isPortfolioItem)}
                          />
                        </Tooltip>
                      )
                      */}
                          <Text fontFamily='ff2' fontSize='1rem'>{element.data.personName}</Text>
                      </StyledContainer>
                </TableCell>
                  <TableCell sx={{ textAlign: 'left', padding: '12px' }}>
                  <Link link={`/${page}/${element.ids.propertyId}`} openTab type={`table ${page}`}>{element.data.propertyName}</Link>
                </TableCell>
                <TableCell sx={{textAlign: 'left', padding: '12px'}}>
                  <Text fontFamily='ff2' fontSize='1rem'>{element.data.position}</Text>
                </TableCell>
                <TableCell sx={{textAlign: 'left', padding: '12px'}}>
                  <Text fontFamily='ff2' fontSize='1rem'>{element.data.countryName}</Text>
                </TableCell>
                <TableCell sx={{padding: '12px'}}>
                  <div className='contact-icons'>
                    {element.data.linkedin &&
                      <Link type='icon' external openTab link={element.data.linkedin}>
                        <Icon sx={{color: '#2D70DA', fontSize: '24px !important'}} baseClassName='fa-brands' className='fa-linkedin' />
                      </Link>
                    }
                    {element.data.personEmail &&
                      <Link type='icon' external openTab link={`mailto: ${element.data.personEmail}`}>
                        <Icon sx={{color: '#2D70DA', fontSize: '24px !important'}} baseClassName='fa-regular' className='fa-envelope' />
                      </Link>
                    }
                  </div>
                </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MUITable>
    </StyledTable>
    {
          addToPortfolio &&
          <Dialog open={opendi} onClose={handleClose}>
              <DialogTitle>Select Custom Field</DialogTitle>
              <DialogContent>
              {errorMessage && <Text><span className="errormessage">{errorMessage}</span></Text>}
                  <FormControl>
                      <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                      >
                          {listFolder.map((element, index) => {
                              return (
                                  <FormControlLabel key={`element-${index}`} value={element.folderId} control={< Radio />} label={element.folderName} onClick={(e) => setmFolderId(e.target.value)} />
                              )
                          })}
                      </RadioGroup>
                  </FormControl>
              </DialogContent>
              <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button onClick={() => handleAddToPortfolio()}>Save</Button>
              </DialogActions>
          </Dialog>
      }
    </>
    )
  }
  else if (searchType === 'people') {
      return (
          <>

              <StyledTable component={Paper} className={`${type}-page-table`}>
                  <MUITable aria-label="results-table">
                      <TableHead>
                          <TableRow>
                              {headers.map((header, index) => {
                                  let headerSort;
                                  if (searchType) {
                                      const pageFields = data.column[searchType];
                                      headerSort = pageFields.find(sort => sort.text === header.value);
                                  }
                                  return (
                                      <TableCell key={`header-${header.value}`} sx={{ padding: '12px' }}>
                                          <div style={{ display: 'flex', alignItems: 'center' }}>
                                              <Text textTransform='uppercase' fontFamily='ff1' color='#808183' fontWeight='700' display='inline-block'>{header.value}</Text>
                                              {searchType && headerSort && (
                                                  <SortButton selected={headerSort.sort === sortSelected.fieldName && sortSelected.direction} field={headerSort.sort} handleClick={handleSort} />
                                              )}
                                          </div>
                                      </TableCell>
                                  )
                              })}
                          </TableRow>
                      </TableHead>
                      <TableBody>
                          {content.map((element, index) => (
                              <TableRow key={`element-${index}`}>
                                  {Object.entries(element.data).filter(el => el[0] !== 'isPortfolioItem').map((el, i) => {
                                      const profileLink = element.ids['companyId'];
                                      const key = headers[i].key;
                                      const value = element.data[key];
                                      const linkedin = element.data.linkedin;
                                      return (
                                          <TableCell key={`${key}-${value}`} sx={{ textAlign: typeof value === 'number' || key === 'website' ? 'center' : 'left', padding: '12px' }}>
                                              {key.includes('linkedin') && linkedin !== undefined ?
                                                  <Link type='table' external openTab link={value}>Linkedin</Link>
                                                  : key === 'website' ?
                                                      <Link type='icon' openTab external link={value}>
                                                          <Icon baseClassName='fa-regular' className='fa-square-arrow-up-right' />
                                                      </Link>
                                                      : key.includes('companyName') && profileLink !== undefined ?
                                                              <Link link={`/${page}/${profileLink}`} openTab type={`table ${page}`}>{value}</Link>
                                                          : (key.includes('owners') && Array.isArray(value)) ?
                                                              <React.Fragment>
                                                                  {value.map((company, index) => {
                                                                      if (company.key === 0) {
                                                                          return (
                                                                              <Text key={index} fontFamily='ff2' fontSize='1rem'>{company.value}</Text>
                                                                          )
                                                                      }
                                                                      else {
                                                                          return (
                                                                              <Link key={index} link={`/company/${company.key}`} openTab type={`table company`}>{company.value}</Link>
                                                                          )
                                                                      }
                                                                  })}
                                                              </React.Fragment>
                                                              : key.includes('commodity') ?
                                                                  <Text fontFamily='ff2' fontSize='1rem'>{addSpace(value)}</Text>
                                                                  : key.includes('peopleName') ?  
                                                                        <StyledContainer sx={{ alignItems: 'center' }}>
                                                                          { /* addToPortfolio && element.data.isPortfolioItem ? (
                                                                                  <Button
                                                                                      type='icon'
                                                                                      iconProps={{
                                                                                          color: 'primary',
                                                                                          sx: { fontSize: '22px' },
                                                                                          baseClassName: element.data.isPortfolioItem ? 'fa-solid' : 'fa-regular',
                                                                                          className: 'fa-bookmark'
                                                                                      }}
                                                                                      disabled
                                                                                  />
                                                                          ) : (
                                                                              <Tooltip title={element.data.isPortfolioItem ? 'Remove from Portfolio' : 'Add to Portfolio'}>
                                                                                  <Button
                                                                                      type='icon'
                                                                                      iconProps={{
                                                                                          color: 'primary',
                                                                                          sx: { fontSize: '22px' },
                                                                                          baseClassName: element.data.isPortfolioItem ? 'fa-solid' : 'fa-regular',
                                                                                          className: 'fa-bookmark'
                                                                                      }}
                                                                                          handleClick={() => handleClickOpen(profileLink, element.data.isPortfolioItem)}
                                                                                  />
                                                                              </Tooltip>
                                                                          )
                                                                          */}
                                                                          <Text fontFamily='ff2' fontSize='1rem'>{value}</Text>
                                                                      </StyledContainer>
                                                                      : <Text fontFamily='ff2' fontSize='1rem'>{value}</Text>
                                                              }
                                          </TableCell>
                                      )
                                  })}
                              </TableRow>
                          ))}
                      </TableBody>
                  </MUITable>
              </StyledTable>
              {addToPortfolio &&
                  <Dialog open={opendi} onClose={handleClose}>
                      <DialogTitle>Select Custom Field</DialogTitle>
                      <DialogContent>
                      {errorMessage && <Text><span className="errormessage">{errorMessage}</span></Text>}
                          <FormControl>
                              <RadioGroup
                                  row
                                  aria-labelledby="demo-row-radio-buttons-group-label"
                                  name="row-radio-buttons-group"
                              >
                                  {listFolder.map((element, index) => {
                                      return (
                                          <FormControlLabel key={`element-${index}`} value={element.folderId} control={< Radio />} label={element.folderName} onClick={(e) => setmFolderId(e.target.value)} />
                                      )
                                  })}
                              </RadioGroup>
                          </FormControl>
                      </DialogContent>
                      <DialogActions>
                          <Button onClick={handleClose}>Cancel</Button>
                          <Button onClick={() => handleAddToPortfolio()}>Save</Button>
                      </DialogActions>
                  </Dialog>
              }
          </>
      )
  }
  else {
    return (
        <>
      
        <StyledTable component={Paper} className={`${type}-page-table`}>
      <MUITable aria-label="results-table">
        <TableHead>
          <TableRow>
            {headers.map((header, index) => {
              let headerSort;
              if (searchType) {
                const pageFields = data.column[searchType];
                headerSort = pageFields.find(sort => sort.text === header.value);
              }
              return (
              <TableCell key={`header-${header.value}`} sx={{padding: '12px'}}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <Text textTransform='uppercase' fontFamily='ff1'  color='#808183' fontWeight='700' display='inline-block'>{header.value}</Text>
                  {searchType && headerSort && (
                    <SortButton selected={headerSort.sort === sortSelected.fieldName && sortSelected.direction} field={headerSort.sort} handleClick={handleSort} />
                  )}
                </div>
              </TableCell>
              )})}
          </TableRow>
        </TableHead>
        <TableBody>
          {content.map((element, index) => (
            <TableRow key={`element-${index}`}>
                {Object.entries(element.data).filter(el => el[0] !== 'isPortfolioItem').map((el, i) => {
                const profileLink = element.ids['companyId'] || element.ids['propertyId'];
                const key = headers[i].key;
                const value = element.data[key];
                const linkedin = element.data.linkedin;
                return (
                <TableCell key={`${key}-${value}`} sx={{textAlign: typeof value === 'number' || key === 'website' ? 'center' : 'left', padding: '12px'}}>
                    {key.includes('linkedin') && linkedin !== undefined ?
                    <Link type='table' external openTab link={value}>Linkedin</Link>
                    : key === 'website' ?
                    <Link type='icon' openTab external link={value}>
                      <Icon baseClassName='fa-regular' className='fa-square-arrow-up-right' />
                    </Link>
                  : (key.includes('companyName') || key.includes('propertyName')) && profileLink !== undefined ?
                    <StyledContainer sx={{alignItems: 'center'}}>
                      {removebookmark ? "" : addToPortfolio && element.data.isPortfolioItem ? (
                          <Button
                            type='icon'
                            iconProps={{
                              color: 'primary',
                              sx: { fontSize: '22px' },
                              baseClassName: element.data.isPortfolioItem ? 'fa-solid' : 'fa-regular',
                              className: 'fa-bookmark'
                            }}
                            disabled
                          />
                      ) : (
                      <Tooltip title={element.data.isPortfolioItem ? 'Remove from Portfolio' : 'Add to Portfolio'}>
                          <Button
                            type='icon'
                            iconProps={{
                              color: 'primary',
                              sx: { fontSize: '22px' },
                              baseClassName: element.data.isPortfolioItem ? 'fa-solid' : 'fa-regular',
                              className: 'fa-bookmark'
                            }}
                            handleClick={() => handleClickOpen(profileLink, element.data.isPortfolioItem)}
                          />
                        </Tooltip>
                      )
                      }
                      <Link link={`/${page}/${profileLink}`} openTab type={`table ${page}`}>{value}</Link>
                    </StyledContainer>
                  : (key.includes('owners') && Array.isArray(value)) ? 
                    <React.Fragment>
                      {value.map((company, index) => {
                        if (company.key === 0) {
                          return (
                            <Text key={index} fontFamily='ff2' fontSize='1rem'>{company.value}</Text>
                          )
                        }
                        else {
                          return (
                            <Link key={index} link={`/company/${company.key}`} openTab type={`table company`}>{company.value}</Link>
                          )
                        }
                      })}
                    </React.Fragment>
                  : key.includes('commodity') ? 
                    <Text fontFamily='ff2' fontSize='1rem'>{addSpace(value)}</Text>
                  : <Text fontFamily='ff2' fontSize='1rem'>{value}</Text> }
                </TableCell>
              )})}
            </TableRow>
          ))}
        </TableBody>
      </MUITable>
    </StyledTable>
    {addToPortfolio && 
    <Dialog open={opendi} onClose={handleClose}>
    <DialogTitle>{searchType === "company" ? "Select Company List" : "Select Property List"}</DialogTitle>
    <DialogContent>
    {errorMessage && <Text><span className="errormessage">{errorMessage}</span></Text>}
        <FormControl>
        <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
        >
            {listFolder.map((element, index) => {
                return (
                    <FormControlLabel key={`element-${index}`} value={element.folderId} control={< Radio />} label={element.folderName} onClick={(e) => setmFolderId(e.target.value)} />
                    )
            })}
        </RadioGroup>
        </FormControl>
    </DialogContent>
    <DialogActions>
    <Button onClick={handleClose}>Cancel</Button>
    <Button onClick={() => handleAddToPortfolio()}>Save</Button>
    </DialogActions>
    </Dialog>
    }
    </>
    )
  }
}
