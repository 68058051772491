import React from 'react'
import GroupInput from './GroupInput';
import { layout } from '../../../data/filtersLayout.json';


export default function Form({filters, page, values, handleInput, MarketCap_Slider_val}) {

  
  

  return (
    <div className="search-filters">
      {layout[page].map((section, index) => {
        return (
          <div key={index}>
            {section.map(input => {
              const id = input.split('_')[0];
              const type = input.split('_')[1];
              return (
                <GroupInput
                      key={input}
                      type={type}
                      list={filters[input]}
                      id={id}
                      input={input}
                      page={page}
                      value={values[input]}
                      handleInput={handleInput}
                      MarketCap_Slider_val={MarketCap_Slider_val}
                />
              )
            })}
          </div>
        );
      })}
      
    </div>
  )
}
