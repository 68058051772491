import { getCompanyFilters } from "../../../services/getCompanyFilters"
import { getCompanyFolders } from "../../../services/getCompanyFolders"
import { getCompanyProfile } from '../../../services/getCompanyProfile';
import { postCompanyList } from '../../../services/postCompanyList';
import { getCompanyAutocomplete } from '../../../services/getCompanyAutocomplete';
import { postCompanyCities } from "../../../services/postCompanyCities";
import { addCompanyPortfolio } from "../../../services/addCompanyPortfolio";

export const serviceGetFilters = () => {
    return getCompanyFilters();
}

export const serviceGetFolders = (EntityId) => {
    return getCompanyFolders(EntityId);
}

export const servicePostList = (query) => {
    return postCompanyList(query);
}

export const serviceGetProfile = (id) => {
    return getCompanyProfile(id);
}

export const serviceCompanyAutocomplete = (name) => {
    return getCompanyAutocomplete(name);
}
export const serviceCompanyCities = (country) => {
    return postCompanyCities(country);
}
export const serviceAddPortfolio = (id) => {
    return addCompanyPortfolio(id);
}