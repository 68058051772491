export const propertyReducer = (state, action) => {
    switch (action.type) {
        case "SET_FILTER": 
            return {
                ...state, filters: action.payload}
        case "EDIT_FILTER": 
            return {...state, filters: action.payload}
        case "SET_QUERY": 
            return {...state, query: action.payload}
        case "SET_PAGE_NUMBER": 
            return {
                ...state,
                query: {
                    ...state.query,
                    pageNumber: action.payload
                },
                searchType: state.searchType + 1
            }
        case "SET_PAGE_SIZE": 
            return {
                ...state,
                query: {
                    ...state.query,
                    pageSize: action.payload
                },
                searchType: state.searchType + 1
            }
        case "SET_OPTION": 
            return {
                ...state,
                query: {
                    ...state.query,
                    option: action.payload
                },
                searchType: state.searchType + 1
            }
        case "SET_PROPERTY_NAME": 
            return {
                ...state, 
                propertyName: action.payload.name,
                propertySearchValues: action.payload.fullOption,
                query: {
                    ...state.query, 
                    propertyName: action.payload.name
                }
            }
        case "SET_PROPERTY_ID": 
            return {
                ...state,
                selected: action.payload
            }
        case "SET_RESULTS": 
            return {
                ...state,
                results: action.payload
            }
        case "SET_TOTAL_RESULTS": 
            return {
                ...state,
                totalResults: action.payload
            }

        case "SET_RESULTS_Folder":
            return {
                ...state,
                resultsFolder: action.payload
            }
        case "SET_PROFILE": 
            return {
                ...state,
                profile: action.payload
            }
        case "SET_AUTOCOMPLETE": 
            return {
                ...state,
                autocomplete: action.payload
            }
        case "SET_SELECTED_FILTERS": 
            return {
                ...state,
                selectedFilters: action.payload
            }
        case "SET_DEFAULT_VIEW": 
            return {
                ...state,
                query: {
                    ...state.query,
                    isDefaultView: action.payload
                }
            }
        case "SET_PROVINCES":
            return {
                ...state,
                filters: {
                    ...state.filters,
                    provinceList_Dropdown: action.payload
                }
            }
        case "SET_EMPTY_FILTERS_FLAG": {
            return {
                ...state,
                emptyFilters: action.payload
            }
        }
        case "ADD_TO_PORTFOLIO":
            return {
                ...state,
                profile: {
                    ...state.profile,
                    isPortfolioItem: true
                }
            }
        case "SET_SORTING":
            return {
                ...state,
                query: {
                    ...state.query,
                    sorting: action.payload,
                },
                searchType: state.searchType + 1
            }
        default:
            return state;
    }
}