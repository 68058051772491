import React from 'react';
import { styled } from '@mui/material';

import Table from '../../atoms/Table';
import Text from '../../atoms/Text';
import Card from '../../molecules/Card';

import { formatFinancialTable } from '../../../../lib/formatTable';

const StyledFinancialExtract = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '20px',
}));

const StyledFinancialData = styled('div')(() => ({
    border: '1px solid #E6E7E8',
    width: '100%',
    padding: '8px 27px'
}))
export default function Financial({table, endYear = 'December 31'}) {
    return (
        <Card>
            <StyledFinancialExtract className='financial-extract'>
                <StyledFinancialData>
                    <Text display='inline-block' fontSize='1rem' fontWeight='500' color='#808183' textTransform='uppercase'>Reporting Currency</Text>
                    <Text display='inline-block' fontSize='1rem' marginLeft='10%' fontWeight='500'>{table[0]['currency']}</Text>
                </StyledFinancialData>
                <StyledFinancialData>
                    <Text display='inline-block' fontSize='1rem' fontWeight='500' color='#808183' textTransform='uppercase'>Fiscal year-end</Text>
                    <Text display='inline-block' fontSize='1rem' marginLeft='10%' fontWeight='500'>{endYear}</Text>
                </StyledFinancialData>
            </StyledFinancialExtract>

            <Table type='financial' table={formatFinancialTable(table)} caption='Consolidated Financial Data, Dec 31 (in millions of dollars) (IFRS basis)' />
        </Card>
    )
}
