import React, { createContext, useContext, useReducer } from 'react';
import Cookies from 'universal-cookie';
import { appReducer } from './AppReducer';
import { serviceAuth } from './services/getUserAuth';
import { postLogout } from './services/postLogout';

const AppContext = createContext();
const cookies = new Cookies();

export const AppProvider = ({children}) => {
    const initialState = {
        isLoading: true,
        isLoadingAuth: true,
        isError: false,
        errorMessage: null,
        user: {
            authenticated: false,
            name: ''
        }
    }

    const [ state, dispatch ] = useReducer(appReducer, initialState);

    const handleLoading = loading => {
        dispatch({type: "SET_LOADING", payload: loading});
    }
    const handleCloseError = () => {
        dispatch({type: "SET_ERROR", payload: null});
    }
    const handleError = (message) => {
        dispatch({type: "SET_ERROR", payload: message});
        setTimeout(() => {
            handleCloseError();
        }, 6000);
    }
    const handleUserAuth = (auth) => {
        dispatch({type: 'SET_AUTH', payload: auth});
    }
    const handleUserName = (user) => {
        dispatch({type: 'SET_NAME', payload: user});
    }

    const handleUser = () => {
        serviceAuth().then((data) => {
            const user = data.data;
            dispatch({type: 'SET_AUTH', payload: user});
            dispatch({type: 'SET_LOADING_AUTH', payload: false});
        }).catch(err => {
            const user = err.response.data;
            user.message = err.response.headers.errormessage;
            dispatch({type: 'SET_AUTH', payload: user});
            dispatch({type: 'SET_LOADING_AUTH', payload: false});
        })
    }
    const handleLogout = () => {
        handleLoading(true);
        const returnUrl = 'https://marcopolo.tnm.global/';
        postLogout(returnUrl).then(data => {
            cookies.set('token', '');
            window.location.href = data.data;
        })
    } 

    const providerValue = {
        ...state,
        handleLoading,
        handleError,
        handleUserAuth,
        handleUserName,
        handleUser,
        handleLogout
    }

    return (
        <AppContext.Provider value={providerValue}>
            {children}
        </AppContext.Provider>
    )
}

export const useApp = () => useContext(AppContext);