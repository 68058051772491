export const mineComplexOwnership = list => {
    const formatted = list.complexOwnership.map((row, index) => {
        let formatRow = {};
        if (index === 0) {
            formatRow.complexName = list.complexName;
        } else {
            formatRow.complexName = '';
        }
        formatRow.companyName = row.companyName;
        formatRow.companyType = row.companyType;
        formatRow.ownership = row.ownership;
        formatRow.companyId = row.companyId;
        formatRow.complexId = list.complexId;

        return formatRow;
    });
    return formatted;
}

export const propertiesMineComplex = list => {
    const formatted = list.map(row => {
        let formatRow = {};
        
        formatRow.propertyId = row.propertyId;
        formatRow.propertyName = row.propertyName;
        formatRow.assetType = row.propertyAssetType;
        formatRow.status = row.propertyStatus;

        return formatRow;
    });
    return formatted;
}