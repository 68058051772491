import React, { useState, useEffect } from 'react';
import { useApp } from '../../../AppContext';
import Dialog from './Dialog';
import Menu from './Menu';
import { membershipUrl } from '../../../lib/membershipUrl';

const notEligibleForBilling = ['member', 'complimentary'];

const url = membershipUrl();

export default function UserMenu({user, type}) {
    const { handleLogout } = useApp();
    
    const links = [
        {link: `${url}account`, external: true, text: 'Account', icon: {base: 'fa-light', className: 'fa-user'}},
        {link: `${url}subscriptions`, external: true, text: 'Subscriptions', icon: {base: 'fa-light', className: 'fa-arrows-rotate'}},
        {link: `https://billing.stripe.com/p/login/test_dR602vcIt9wAdj2144`, external: true, text: 'Billing', icon: {base: 'fa-light', className: 'fa-wallet'}},
        {onClick: handleLogout, external: true, openTab: false, text: 'Log Out', icon: {base: 'fa-light', className: 'fa-arrow-right-from-bracket'}},
    ]
    const [ navLinks, setNavLinks ] = useState(links);

    user.role = 'member';

    useEffect(() => {
        if (notEligibleForBilling.includes(user.role)) {
            setNavLinks(navLinks.filter(link => link.text !== 'Billing'));
        }
        // eslint-disable-next-line
    }, [user]);

    if (type === 'menu') {
        return (
            <Menu
                tooltip='User Menu'
                menuIcon={{base: 'fa-thin', className: 'fa-circle-user header-nav', color: '#FFFFFF'}}
                options={navLinks}
            />
        )
    }
    else {
        return (
            <Dialog
                tooltip='User Menu'
                menuIcon={{base: 'fa-thin', className: 'fa-circle-user header-nav', color: '#FFFFFF'}}
                options={navLinks}
            />
        )
    }

}
