import React from "react";

import logo from "./assets/Img/TNMG-Logo.png";
import Grid from "@mui/material/Grid";
import { Container } from "@mui/material";
const Footer = () => {
  return (
    <div className="Appfooter">
        <Container maxWidth='false'> <Grid container spacing={3} > 
            <Grid item xs={6}>
            <img
            src={logo}         
           alt="logo"
         
            style={{ flex: "start" , height:'15px',}}
          />

            </Grid>
            <Grid item xs={6} style={{textAlign:'right', fontSize:'12px'}}>
          
                  © TNM Group | All Rights Reserved
            </Grid>
             </Grid>
             </Container>
   
    </div>
  );
};

export default Footer;
