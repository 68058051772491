const keys = {
    company: 'companyName',
    property: 'propertyName'
}

export const handleFiltersList = (filters, page) => {
    let selected = [];
    if (Array.isArray(filters)) {
        filters.map(option => {
            selected.push({text: option[keys[page]], id: keys[page]});
            return option;
        })
    } else {
        Object.entries(filters).map(([key, value]) => {
            if (Array.isArray(value)) {
                value.map(option => {
                    if (option.isSelected) {
                        if (option.name === 'Yes' || option.name === 'No'){
                            const name = key.split('_')[0].split(/(?=[A-Z])/).join(' ');
                            selected.push({text: `${name}: ${option.name}`, id: key, value: option.selectedValue});
                        }
                        else if (key === 'primaryCommodityList_Dropdown') {
                            selected.push({text: `Primary: ${option.name}`, id: key, value: option.selectedValue});
                        } else if (key === 'commodityExposureList_Dropdown') {
                            selected.push({text: `Exposure: ${option.name}`, id: key, value: option.selectedValue});
                        }
                        else {
                            selected.push({text: option.name, id: key, value: option.selectedValue});
                        }
                    }
                    return option;
                })
            }
            else if (value !== ''){
                selected.push({text: value, id: key, value: value});
            }
            return value;
        })
    }
    return selected;
}