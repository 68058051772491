import { Paper, styled, Table as MUITable, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React from 'react';
import Link from '../../atoms/Link';
import Text from '../../atoms/Text';

const StyledTable = styled(TableContainer)(() => ({
    '& thead th': {
      backgroundColor: '#E6E7E8',
      textTransform: 'uppercase',
    }
  }))

const HeaderText = ({children}) => {
    return (
        <Text textTransform='uppercase' fontFamily='ff1' color='#808183' fontWeight='700' display='inline-block'>{children}</Text>
    )
}

export default function ComplexOwnership({content}) {
  return (
    <StyledTable component={Paper} className={`company-page-table`}>
      <MUITable aria-label="results-table">
        <TableHead>
          <TableRow>
              <TableCell sx={{padding: '12px'}}>
                  <HeaderText>Complex Name</HeaderText>
              </TableCell>
              <TableCell sx={{padding: '12px'}}>
                  <HeaderText>Company</HeaderText>
              </TableCell>             
              <TableCell sx={{padding: '12px'}}>
                  <HeaderText>Company Type</HeaderText>
              </TableCell>
              <TableCell sx={{padding: '12px'}}>
                  <HeaderText>Ownership</HeaderText>
              </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
         
          {content.map((element, index) => {
            const linked = element.companyType === 'Public';
            return (
              
            <TableRow key={`element-${index}`}>
                <TableCell sx={{textAlign: 'left', padding: '12px'}}>
                    {linked ? (
                        <Link openTab link={`/properties/${element.complexId}`} type={`table properties`}>{element.complexName}</Link>
                    ) : (
                        <Text fontFamily='ff2' fontSize='1rem'>{element.complexName}</Text>
                    )}
                </TableCell>
                <TableCell sx={{textAlign: 'left', padding: '12px'}}>
                    {linked ? (
                        <Link openTab link={`/company/${element.companyId}`} type={`table company`}>{element.companyName}</Link>
                    ) : (
                        <Text fontFamily='ff2' fontSize='1rem'>{element.companyName}</Text>
                    )}
                </TableCell>
                <TableCell sx={{textAlign: 'left', padding: '12px'}}>
                    <Text fontFamily='ff2' fontSize='1rem'>{element.companyType}</Text>
                </TableCell>
                <TableCell sx={{textAlign: 'center', padding: '12px'}}>
                <Text fontFamily='ff2' fontSize='1rem'>{parseInt(element.ownership)}%</Text>
                </TableCell>
            </TableRow>
            
          )})}
             
        </TableBody>
      </MUITable>
    </StyledTable>
  )
}
