import * as React from "react";
import MuiAppBar from "@mui/material/AppBar";
import { styled } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import logo from "./assets/Img/Marco-Polo-Logo.png";
import Ai from "./assets/Img/ai.png";
import Link from '../UI/atoms/Link';


// import AccountCircle from "@mui/icons-material/AccountCircle";

const AppBar = styled(
  MuiAppBar,
  {}
)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
}));
function NavMenu() {
  return (
    <AppBar position="fixed" className='Appheader'>
      <Container maxWidth="false">
         
          <Grid container>
                  <Grid item xs={8} className="logolink">
                      <Link openTab exact link="/" >
                      <img
            src={logo}         
                              style={{ height: '20px', display: 'inline' }}
            alt="logo"
                      /></Link>
                      <img
                          src={Ai}
                          style={{ height: '30px' }}
                          alt="logo"
                      />   
            </Grid>
            <Grid item xs={4} style={{ display:'flex', justifyContent:'flex-end', alignItems:'center', }}>
                      
            
            </Grid>
          </Grid>
        
      </Container>
    </AppBar>
  );
}
export default NavMenu;
