import { styled } from "@mui/material";
import { authPost } from '../services/authMiddleware';
import React, { useEffect } from "react";
import useDocumentTitle from "../components/features/hooks/useDocumentTitle";
import { usePortfolio } from "../components/features/portfolio/context";
import Button from "../components/UI/atoms/Button";
import Text from "../components/UI/atoms/Text";
import Card from "../components/UI/molecules/Card";
import List from "../components/UI/molecules/List";
import ListNoData from "../components/UI/molecules/ListNoData";
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AddIcon from '@mui/icons-material/Add';
import { Alert, Snackbar } from '@mui/material';
const StyledWrapper = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
}));

export default function Portfolio() {

  const {
    portfolio,
    setPortfolio,
    resultsFolder,
    resultsFolderProperty,
    resultsFolderContact,
    handleSelected,
    updateCompanyList,
    updatePropertyList,
    setFolders,
    handlePortfolioFolder,
    handlePortfolioFolderItem,
    addToPortfolioMove
    } = usePortfolio();

    const [open, setOpen] = React.useState(false);
    const [folderName, setFolderName] = React.useState("");
    const [cFolderId, setcFolderId] = React.useState("");
    const [errorMessage, setErrorMessage] = React.useState("");
    const [openAlertNewUpload, setOpenAlertNewUpload] = React.useState(false);

    const handleClickOpen = (cfolderID) => {
        setcFolderId(cfolderID);
        setErrorMessage("");
        setOpen(true);
    };

    async function handleFolderSubmit(eId) {

        if (folderName == "") {
            setErrorMessage("Field name is required.");
        } else {
            const data = {
                "folderId": 0,
                "folderName": folderName,
                "entityTypeId": eId
            }
            const response = await createFolder(data);
            if (response.status === 200) {
                setOpenAlertNewUpload(true);
                setOpen(false);
                setFolders(1);
                setFolders(2);
                setFolders(3);
                setPortfolio();
            }
        }
    };

    const createFolder = async (data) => {
        return authPost('/api/portfolio/folder', data);
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenAlertNewUpload(false);
    };

  useDocumentTitle("My Portfolio | TNM Marco Polo");

  useEffect(() => {
    (async () => {
        setFolders(1);
        setFolders(2);
        setFolders(3);
        setPortfolio();
      
    })();
    // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
          <StyledWrapper>
              
        <Card styles={{ marginTop: 0, marginBottom: "24px" }}>
          <Text fontFamily="ff1" fontSize="2.25rem" fontWeight="500">
            My Portfolio
          </Text>
        </Card>

        <Card
          styles={{ marginTop: 0, marginBottom: "24px", padding:'24px' }}
          padded={false}
          header="Saved companies"
          headerAction={
              <Button variant="outlined" onClick={() => handleClickOpen(1)} style={{ alignSelf: 'flex-end' }}><AddIcon /></Button>
          }
        >
       
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add Custom Field</DialogTitle>
                      <DialogContent>
                          {errorMessage && <Text><span className="errormessage">{errorMessage}</span></Text>}  
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Custom field name"
            type="text"
            name="foldername"
            fullWidth
            variant="standard"
            onChange={(e) => setFolderName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={() => handleFolderSubmit(cFolderId)}>Save</Button>
        </DialogActions>
      </Dialog>
      
      {portfolio.savedCompanyList == null ||
          !portfolio.savedCompanyList.length ? (
            <ListNoData
              listFolder={resultsFolder}
              type="portfolio"
              handlePortfolioFolder = {handlePortfolioFolder}
            />
          ) : ( resultsFolder.length > 0 ? 
            <List
              list={portfolio.savedCompanyList}
              listFolder={resultsFolder}
              listId="savedCompanyList"
              select={handleSelected}
              type="portfolio"
              page="company"
              handlePortfolioFolder = {handlePortfolioFolder}
              handlePortfolioFolderItem = {handlePortfolioFolderItem} 
              addToPortfolioMove={addToPortfolioMove}
            /> : ""
          )}
        </Card>

        <Card
          styles={{ marginTop: 0, marginBottom: "24px", padding: '24px' }}
          padded={false}
          header="Saved properties"
          headerAction={
              <Button variant="outlined" onClick={() => handleClickOpen(2)} style={{ alignSelf: 'flex-end' }}><AddIcon /></Button>
          }
        >
          
          {portfolio.savedPropertyList == null ||
          !portfolio.savedPropertyList.length ? (
            <ListNoData
              listFolder={resultsFolderProperty}
              type="portfolio"
              handlePortfolioFolder = {handlePortfolioFolder}
            />
          ) : (
            <List
              list={portfolio.savedPropertyList}
              listFolder={resultsFolderProperty}
              listId="savedPropertyList"
              select={handleSelected}
              type="portfolio"
              page="properties"
              handlePortfolioFolder = {handlePortfolioFolder}
              handlePortfolioFolderItem = {handlePortfolioFolderItem}
              addToPortfolioMove={addToPortfolioMove}
            />
          )}
        </Card>
        
        {/*<Card*/}
        {/*  styles={{ marginTop: 0, marginBottom: "24px" }}*/}
        {/*  padded={false}*/}
        {/*  header="Saved Executives / Personnel"*/}
        {/*  headerAction={*/}
        {/*      <Button variant="outlined" onClick={() => handleClickOpen(3)} style={{ alignSelf: 'flex-end' }}><AddIcon /></Button>*/}
        {/*  }*/}
          
        {/*>*/}
          
        {/*  {portfolio.savedContactList == null ||*/}
        {/*  !portfolio.savedContactList.length ? (*/}
        {/*    <div style={{ padding: "12px 20px" }}>*/}
        {/*      <Text>No contacts saved! Add them to your portfolio</Text>*/}
        {/*    </div>*/}
        {/*  ) : (*/}
        {/*    <List*/}
        {/*      list={portfolio.savedContactList}*/}
        {/*      listFolder={resultsFolderContact}*/}
        {/*      listId="savedContactList"*/}
        {/*      select={handleSelected}*/}
        {/*      type="portfolio"*/}
        {/*      page="contact"*/}
        {/*    />*/}
        {/*  )}*/}
        {/*</Card>*/}

        {/*<Card*/}
        {/*  styles={{ marginTop: 0, marginBottom: "24px" }}*/}
        {/*  padded={false}*/}
        {/*  header="Saved Personnel"*/}
        {/*  headerAction={*/}
        {/*      <Button variant="outlined" onClick={() => handleClickOpen(3)} style={{ alignSelf: 'flex-end' }}><AddIcon /></Button>*/}
        {/*  }*/}
        {/*>*/}
        {/*  {portfolio.savedContactList == null ||*/}
        {/*  !portfolio.savedContactList.length ? (*/}
        {/*    <div style={{ padding: "12px 20px" }}>*/}
        {/*      <Text>No contacts saved! Add them to your portfolio</Text>*/}
        {/*    </div>*/}
        {/*  ) : (*/}
        {/*    <List*/}
        {/*      list={portfolio.savedContactList}*/}
        {/*      listFolder={resultsFolderContact}*/}
        {/*      listId="savedContactList"*/}
        {/*      select={handleSelected}*/}
        {/*      type="portfolio"*/}
        {/*      page="contact"*/}
        {/*    />*/}
        {/*  )}*/}
              {/*</Card>*/}
              <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={openAlertNewUpload} autoHideDuration={3000} onClose={handleCloseAlert} style={{ bottom:'60px'}}>
                  <Alert severity='success' >Folder Added Successfully.</Alert>
        </Snackbar>
      </StyledWrapper>
    </React.Fragment>
  );
}
