import React, { useEffect } from "react";
import { servicePostSearchResult, serviceGetUserHistoryById, serviceGetUserHistory, serviceDeleteUserHistory, serviceGetTopQueries, serviceGetDataSet } from '../services/middleware';
import Grid from "@mui/material/Grid";
import useDocumentTitle from '../components/features/hooks/useDocumentTitle';
import Container from '@mui/material/Container';
import Chat from '../components/chatbot/Chat';
import Button from "@mui/material/Button";
import TopButtons from '../components/chatbot/TopButtons'
import DrawerImg from '../components/chatbot/assets/Img/drawer.png'
import { useState } from "react";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import "../components/chatbot/styled-components.css";
import NavMenu from '../components/chatbot/NavMenu';
import Footer from '../components/chatbot/Footer';
function uniq(a) {
    return Array.from(new Set(a));
}
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ChatBot = () => {

    const [inputValue, setInputValue] = useState("");
    const [responseData, setResponseData] = useState([]);
    const [clearChat, setClearChat] = useState(false);
    const [emptyResponse, setEmptyResponse] = useState(false);
    const [loading, setLoading] = useState(false);
    const [hideSideBar, setHideSideBar] = useState(false);
    const [openError, setOpenError] = useState(false);
    const [openCopy, setOpenCopy] = useState(false);
    const [displayMessage, setDisplayMessage] = useState("Welcome to TNM Marcopolo AI - your trusted hub for mining industry insights!");
    const [resultHading, setResultHading] = useState("");
    const [emptyHistory, setEmptyHistory] = useState(false);

    const [open, setOpen] = useState(false);
    const [loadingHis, setLoadingHis] = useState(false);
    const [historyData, setHistoryData] = useState([]);
    const [openAlertDelete, setOpenAlertDelete] = useState(false);
    const [delHistoryId, setDelHistoryId] = useState("");

    const [topQueriesData, setTopQueriesData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [dataSetResultScope, setDataSetResultScope] = useState([]);

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
        setOpenAlertDelete(false);
        setOpenError(false);
        setOpenCopy(false);
    };

    const dateConverter = (timeEnd) => {
        const newStartDateTemp = new Date();
        const newEndDateTemp = new Date(timeEnd);
        const newStartDate = newStartDateTemp.toLocaleDateString();
        const newEndDate = newEndDateTemp.toLocaleDateString();
        var date1 = new Date(newStartDate);
        var date2 = new Date(newEndDate);
        var result = parseInt((date1 - date2) / (1000 * 60 * 60 * 24), 10);
        if (result < 0) { return 0 }
        return result
    }

    const setHistory = () => {
        let temphistoryToday = [];
        let temphistoryYesterday = [];
        let temphistorySevenDay = [];
        let temphistoryThirtyDay = [];

        setEmptyHistory(false);
        setLoadingHis(true);
        serviceGetUserHistory().then(data => {
            const resultsGistoryData = data.data;
            if (data.data.length > 0) {
                resultsGistoryData.map(hisdata => {
                    const res = dateConverter(hisdata.recordDate);
                    if (res === 0) {
                        temphistoryToday.push(hisdata);
                    }
                    if (res === 1) {
                        temphistoryYesterday.push(hisdata);
                    }
                    if (res > 1 && res < 8) {
                        temphistorySevenDay.push(hisdata);
                    }
                    if (res >= 8 && res < 31) {
                        temphistoryThirtyDay.push(hisdata);
                    }
                })
                const temphistoryAll = { 'today': uniq(temphistoryToday), 'yesterday': uniq(temphistoryYesterday), 'sevenday': uniq(temphistorySevenDay), 'thirtyday': uniq(temphistoryThirtyDay) };
                setHistoryData(temphistoryAll);
            }
            if (data.data.length == 0) {
                setEmptyHistory(true);
            }
            setLoadingHis(false);
        }).catch(err => {
            console.error(err);
            setLoadingHis(false);
        });
    }

    const handleHistoryDelete = (id) => {
        setDelHistoryId(id);
        setOpenAlertDelete(true);
    }

    const handleHistoryDeleteFinal = () => {
        if (delHistoryId != "") {
            serviceDeleteUserHistory(delHistoryId).then(data => {
                const resultsDelete = data;
                if (resultsDelete.status === 200) {
                    setOpenAlertDelete(false);
                    setOpen(true);
                    setHistory();
                    handleNewChat();
                }
            }).catch(err => {
                console.error(err);
            });
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (inputValue.length < 10) {
            setOpenError(true);
            return;
        }

        const queryData = {
            "UserInput": inputValue
        }
        setResponseData([]);
        setResultHading("");
        setLoading(true);
        setEmptyResponse(false);

        servicePostSearchResult(queryData).then(data => {
            const resultsSearchData = data.data;
            setResponseData(resultsSearchData);
            if (resultsSearchData.length == 0) {
                setEmptyResponse(true);
                setDisplayMessage("Sorry, no matches found. Check out our Top Queries for helpful search suggestions!");
            }
            if (resultsSearchData.length > 0) {
                setResultHading(inputValue);
            }
            setInputValue("");
            setHistory();
            setLoading(false);
        }).catch(err => {
            console.error(err);
            setLoading(false);
        });


    };

    const handleGetUserHistoryById = (historyId, hisoryInput) => {
        if (historyId !== undefined || historyId == "") {
            setResponseData([]);
            setLoading(true);
            setEmptyResponse(false);
            setResultHading("");
            serviceGetUserHistoryById(historyId).then(data => {
                const resultsSearchData = data.data;
                setResponseData(resultsSearchData);
                if (resultsSearchData.length == 0) {
                    setEmptyResponse(true);
                    setDisplayMessage("Sorry, no matches found. Check out our Top Queries for helpful search suggestions!");
                }
                if (resultsSearchData.length > 0) {
                    setResultHading(hisoryInput);
                }
                setInputValue("");
                setHistory();
                setLoading(false);
            }).catch(err => {
                console.error(err);
                setLoading(false);
            });
        }
    }

    const handleNewChat = () => {
        setLoading(true);
        setEmptyResponse(false);

        setTimeout(() => {
            setLoading(false);
            setInputValue("");
            setResultHading("");
            setResponseData([]);
            setDisplayMessage("Welcome to TNM Marcopolo AI - your trusted hub for mining industry insights!");
            setClearChat(true);
        });

    }

    const hideParentStyle = () => {
        var elements = document.getElementsByClassName("css-1bx5ylf")
        for (var i = 0; i < elements.length; i++) {
            elements[i].style.display = 'none';
        }
    }

    const handleSearchInput = (searchVal) => {
        setInputValue(searchVal);
    }

    const handleSideBar = () => {
        setHideSideBar(!hideSideBar);
    }

    const handleClickTocopy = (copyVal) => {
        navigator.clipboard.writeText(copyVal)
        setOpenCopy(true);
    }

    const setTopQueries = () => {
        setIsLoading(true);
        serviceGetTopQueries().then(data => {
            const resultsTopqueries = data.data;
            setTopQueriesData(resultsTopqueries);
            setIsLoading(false);
        }).catch(err => {
            console.error(err);
        });
    }

    const setDataSet = () => {
        setIsLoading(true);
        serviceGetDataSet().then(data => {
            const resultsDataSet = data.data;
            setDataSetResultScope(resultsDataSet);
            setIsLoading(false);
        }).catch(err => {
            console.error(err);
        });
    }

    useDocumentTitle('TNM Marco Polo AI');

    useEffect(() => {
        hideParentStyle();
        setTopQueries();
        setDataSet();
        setHistory();
    },[])


    return (
        <>

            <Snackbar open={openError} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert severity="error" sx={{ width: '100%' }} variant="standard">
                    <span>Please enter more Characters to help the search engine.</span>
                </Alert>
            </Snackbar>

            <Snackbar open={openCopy} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert severity="success" sx={{ width: '100%' }} variant="standard">
                    <span>Copied!</span>
                </Alert>
            </Snackbar>

            <Snackbar open={open} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert severity="success" sx={{ width: '100%' }} variant="standard">
                    Item Removed!
                </Alert>
            </Snackbar>

            <Snackbar open={openAlertDelete} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} className="stardardalert" >
                <Alert icon={false} severity="error" variant="standard">
                    <span>Are you sure you want to delete the item?</span>
                    <span>
                        <Button variant="contained" onClick={() => handleHistoryDeleteFinal()}><span>Yes</span></Button>
                        <Button variant="contained" onClick={() => setOpenAlertDelete(false)} style={{ marginLeft: '15px' }}><span>No</span></Button>
                    </span>

                </Alert>
            </Snackbar>
            <NavMenu/>
            <Container component="main" maxWidth="false">
            <Grid container spacing={3} style={{ justifyContent: 'flex-end', marginTop:'40px' }}>

                {hideSideBar &&
                    <Button style={{ position: 'fixed', top: '64px', left: '-10px', height: '50px', borderRadius: '0 10px 10px 0', background: '#201E1E', }} onClick={() => setHideSideBar(!hideSideBar)}>  <img src={DrawerImg} alt="Costmine Intelligence" /></Button>
                }

                {!hideSideBar &&
                    <Grid item xs={12} sm={4} md={3} lg={3} xl={2}  >
                        <Chat historyData={historyData} handleGetUserHistoryById={handleGetUserHistoryById} handleNewChat={handleNewChat} handleSideBar={handleSideBar} handleHistoryDelete={handleHistoryDelete} emptyHistory={emptyHistory} />
                    </Grid>
                }
                <Grid item xs={12} sm={hideSideBar ? 12 : 8} md={hideSideBar ? 12 : 9} lg={hideSideBar ? 12 : 9} xl={hideSideBar ? 12 : 10} style={{ position: 'relative', marginLeft: hideSideBar ? '45px' : '' }}>

                        <Grid className="whitebg mt-0 mb-4" style={{ padding: '10px',  }}>

                            {responseData &&
                                <form onSubmit={handleSubmit} style={styles.form}>
                                    <input
                                        type="text"
                                        value={inputValue}
                                        style={{ ...styles.input, ...styles.fullWidthInput }}
                                        onChange={(e) => handleSearchInput(e.target.value)}
                                        placeholder="Enter your input"
                                    />
                                    <button type="submit" style={styles.button}>
                                        <i className="fa-solid fa-arrow-right"></i>
                                    </button>
                                </form>
                            }
                        </Grid>

                        {!loading && responseData && <TopButtons searchResult={responseData} clearChat={clearChat} isLoadingDis={false} displayMessage={displayMessage} handleClickTocopy={handleClickTocopy} resultHading={resultHading} topQueriesData={topQueriesData} dataSetResultScope={dataSetResultScope} />}

                    {loading && (
                        <TopButtons searchResult={responseData} clearChat={clearChat} isLoadingDis={true} />
                    )}

                    
                </Grid>
            </Grid>
            </Container>
            <Footer/>                
        </>
    );
};

const styles = {
    form: {
        display: 'flex',
        alignItems: 'center',
    },
    input: {
        padding: '10px',
        border: 'none',
        borderRadius: '3px',
    },

    fullWidthInput: {
        width: '100%',
    },
    button: {
        padding: '10px',
        background: 'none',
        border: 'none',
        cursor: 'pointer',
    },
    loader: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '50px',
        fontWeight: 'bold',
        top: '50%',
        position: 'absolute',
        left: '50%',
        transform: 'translate(-50 %, -50 %)',
    },

};


export default ChatBot;
