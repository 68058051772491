import { Grid, Icon, styled } from '@mui/material'
import React from 'react'

import { useApp } from '../../../AppContext'
import Link from '../atoms/Link'
import Text from '../atoms/Text'

const Styledwrapper = styled('div')(() => ({
    maxWidth: '900px',
    margin: 'auto'
}))
const StyledBold = styled('span')(() => ({
    fontWeight: '600'
}))
const StyledCard = styled('div')(() => ({
    boxShadow: '#00000029 1px 1px 6px',
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '4px',
    marginTop: '6px',
    marginBottom: '20px'
}))
const StyledLogo = styled('img')(() => ({
    height: '40px'
}))

export default function AuthWall() {
    const { user } = useApp();

    if (user.message.includes('trial')) {
        return (
            <Styledwrapper sx={{flex: '1 1 auto'}}>
                <Text fontFamily='ff1' textTransform='uppercase' fontSize={{xs: '1.5rem', sm: '2rem'}} textAlign='center'>
                    Your TNM Marco Polo trial has expired
                    Unlock Full Access to 
                        <StyledBold> TNM Marco Polo</StyledBold> with a 
                        <StyledBold> NEWS+DATA</StyledBold> Membership
                    </Text>
                <StyledCard>
                    <Text fontFamily='ff1' fontSize='1.5rem' textAlign='center'>You'll also receive these great benefits across</Text>
                    <div className='wall-logos'>
                        <Link external type='img' openTab link='https://www.mining.com'>
                            <StyledLogo alt='Mining dot com logo' src='/images/MDC-Logo.png' />
                        </Link>
                        <Text fontFamily='ff1' fontSize='1.5rem' margin='0 20px'>and</Text>
                        <Link external type='img' openTab link='https://www.northernminer.com'>
                            <StyledLogo alt='The Northern Miner logo' src='/images/TNM-Logo.png' />
                        </Link>
                    </div>
                    <Grid container spacing={0} className='wall-features' sx={{flexWrap: {sm: 'wrap', md: 'nowrap'}}}>
                        <Grid item xs={12} sm={12} md={6} lg={5} className='feature'>
                            <Icon baseClassName='fa-solid' className='fa-newspaper' />
                            <Text fontFamily='ff1'>Unlimited News Stories</Text>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={5} className='feature ft-2'>
                            <Icon baseClassName='fa-solid' className='fa-paper-plane' />
                            <Text fontFamily='ff1'>Premium Specialty Digests</Text>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={2} className='feature'>
                            <Icon baseClassName='fa-solid' className='fa-star' />
                            <Text fontFamily='ff1'>And More</Text>
                        </Grid>
                    </Grid>
                    <Link type='wall-contained' sx={{margin: '30px auto'}} external link='https://membership.tnm.global/upgrade-subscription'>
                        Join Now for Unlimited Access
                        <Icon baseClassName='fa-regular' className='fa-unlock' />
                    </Link>
                    <Link type='sec-action' external link='https://membership-promo.tnm.global/home/' openTab>Learn More about TNM Memberships</Link>
                </StyledCard>
            </Styledwrapper>
        )
    }
    else {
        return (
            <Styledwrapper sx={{flex: '1 1 auto'}}>
                <Text fontFamily='ff1' textTransform='uppercase' fontSize={{xs: '1.5rem', sm: '2rem'}} textAlign='center'>Unlock Full Access to 
                    <StyledBold> TNM Marco Polo</StyledBold> with a 
                    <StyledBold> NEWS+DATA</StyledBold> Membership
                </Text>
                <StyledCard>
                    <Text fontFamily='ff1' fontSize='1.5rem' textAlign='center'>You'll also receive these great benefits across</Text>
                    <div className='wall-logos'>
                        <Link external type='img' openTab link='https://www.mining.com'>
                            <StyledLogo alt='Mining dot com logo' src='/images/MDC-Logo.png' />
                        </Link>
                        <Text fontFamily='ff1' fontSize='1.5rem' margin='0 20px'>and</Text>
                        <Link external type='img' openTab link='https://www.northernminer.com'>
                            <StyledLogo alt='The Northern Miner logo' src='/images/TNM-Logo.png' />
                        </Link>
                    </div>
                    <Grid container spacing={0} className='wall-features' sx={{flexWrap: {sm: 'wrap', md: 'nowrap'}}}>
                        <Grid item xs={12} sm={12} md={6} lg={5} className='feature'>
                            <Icon baseClassName='fa-solid' className='fa-newspaper' />
                            <Text fontFamily='ff1'>Unlimited News Stories</Text>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={5} className='feature ft-2'>
                            <Icon baseClassName='fa-solid' className='fa-paper-plane' />
                            <Text fontFamily='ff1'>Premium Specialty Digests</Text>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={2} className='feature'>
                            <Icon baseClassName='fa-solid' className='fa-star' />
                            <Text fontFamily='ff1'>And More</Text>
                        </Grid>
                    </Grid>
                    <div className='wall-actions'>
                        <Link type='text' external link='https://membership-promo.tnm.global/free-trial/' openTab>
                            Get Started with a 45-day free Trial
                            <Icon baseClassName='fa-regular' className='fa-hourglass-half' />
                        </Link>
                        <Text fontFamily='ff1' fontSize='1rem' margin='0 20px'>or</Text>
                        <Link type='contained' external link='https://membership.tnm.global/membership-plan' openTab>
                            Join Now for Unlimited Access
                            <Icon baseClassName='fa-regular' className='fa-unlock' />
                        </Link>
                    </div>
                    <Link type='sec-action' external link='https://membership-promo.tnm.global/home/' openTab>Learn More about TNM Memberships</Link>
                </StyledCard>
            </Styledwrapper>
        )
    }
}
