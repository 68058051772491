import React from 'react';
import { Icon, IconButton, styled } from '@mui/material';
import { Button as MUIButton } from '@mui/material';

const PrimaryButton = styled(MUIButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  borderRadius: '3px',
  boxShadow: '0px 2px 2px #0000003D',
  color: '#FFFFFF',
  textTransform: 'uppercase',
  padding: '10px 17px',
  minWidth: 'auto',
  lineHeight: 1,
  '&:hover': {
    backgroundColor: theme.palette.primary.hover,
      color: '#fff'
  },
  '&.Mui-disabled': {
    backgroundColor: theme.palette.primary.light
  },
  '&.subscribe': {
    height: 'fit-content',
    padding: '14px 17px',
    borderRadius: '0 4px 4px 0'
  }
}));

const TextButton = styled(MUIButton)(({ theme }) => ({
  color: '#1E2326',
  textTransform: 'uppercase',
  padding: '10px 17px',
  lineHeight: 1,
  '&.header-btn': {
    color:'#fff',
    fontSize: '1rem',
    alignSelf: 'center',
    fontWeight: 400,
    textTransform: 'none',
    '&:hover': {
      color: theme.palette.primary.main
    }
  }
}))

const RoundedButton = styled(MUIButton)(({ theme }) => ({
  borderColor: theme.palette.primary.main,
  border: '1px solid',
  borderRadius: 30,
  color: theme.palette.primary.main,
  lineHeight: 1,
  padding: '7px 25px',
  backgroundColor: '#FFFFFF',
  fontSize: 15,
  textTransform: 'uppercase',
  fontFamily: "'Montserrat', sans-serif",
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    color: '#FFFFFF',
    borderColor: theme.palette.primary.main
  },
  '&.selected' : {
    backgroundColor: theme.palette.primary.main,
    color: '#FFFFFF',
    borderColor: theme.palette.primary.main
  }
}))

const ArrowButton = styled(MUIButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  borderRadius: 3,
  textTransform: 'uppercase',
  padding: '5px 8px',
  paddingLeft: 14,
  lineHeight: 1,
  fontSize: 13,
  position: 'relative',
  marginLeft: '10px',
  color: '#FFFFFF',
  '&:focus': {
    outline: 'none'
  },
  '&::before' : {
    backgroundColor: theme.palette.primary.main,
    content: "''",
    position: 'absolute',
    bottom: 0,
    top: 4,
    left: -7,
    width: 15,
    height: 15,
    transform: 'rotate(45deg)',
  },
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    color: '#FFFFFF',
    textDecoration: 'none'
  }
}))

const StyledIcon = styled(IconButton, {
    shouldForwardProp: prop => prop !== 'tooltip',
  })(({ tooltip, theme }) => ({
    ...(tooltip && {
      '&::before': {
        content: `'${tooltip}'`,
        position: 'absolute',
        left: -110,
        opacity: 0,
        color: '#000000',
        fontSize: '0.875rem',
        whiteSpace: 'nowrap',
      },
      '&:hover::before': {
        opacity: 1
      }
    }),
    '&:hover .fa-trash-can': {
      color: '#D02906'
    },
    '&.nav': {
      borderRadius: '3px',
      padding: '6px'
    },
    '&.nav:hover, &.nav.open-menu': {
      backgroundColor: '#5B5959'
    },
    '&.float-btn': {
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark
      }
    },
    '&.sort': {
      width: 'fit-content',
      padding: 0,
      color: '#B5B4B4',
      '&:hover': {
        color: "#817F7F"
      },
      '&.sort-desc': {
        marginTop: '-6px'
      },
      '&.selected .MuiIcon-root': {
        color: theme.palette.primary.main
      }
    }
}))

const IconTextButton = styled(MUIButton)(({ theme }) => ({
  backgroundColor: '#FFF',
  display: 'flex',
  flexDirection: 'column',
  '& .btn-text': {
    margin: 0,
    fontSize: '0.875rem',
    fontWeight: 400,
    marginTop: 12,
    lineHeight: 1,
    color: '#231F20',
    textTransform: 'capitalize',
    fontFamily: "'Roboto', sans-serif"
  },
  '& #btn-icon': {
    paddingTop: 1
  },
  '&:disabled .btn-text': {
    color: '#7a7879'
  },
  '&:disabled .MuiIcon-root': {
    color: theme.palette.primary.light
  }
}))

const Button = React.forwardRef(( props, ref ) => {
  const { type = '', to, children, color, iconProps, handleClick, disabled = false, ...other } = props;

  if (type === '') {
    return (
      <PrimaryButton onClick={(e) => handleClick(e)} disabled={disabled} {...other}>{children}</PrimaryButton>
    )
  }
  if (type === 'text') {
    return (
      <TextButton variant="text" onClick={(e) => handleClick(e)} {...other}>{children}</TextButton>
    )
  }
  if (type === 'rounded') {
    return (
      <RoundedButton className={props.boolean ? 'selected' : ''} onClick={(e) => handleClick(e)} id={props.id}>
        {children}
      </RoundedButton>
    )
  }
  if (type === 'arrow') {
    return (
      <ArrowButton className='back-btn' onClick={(e) => handleClick(e)}>{children}</ArrowButton>
    )
  }
  if (type === 'icon') {
    return (
      <StyledIcon {...other} ref={ref} className={other.placement} tooltip={props.tooltip} disabled={disabled} onClick={(e) => handleClick(e)}>
        <Icon {...iconProps} />
      </StyledIcon>
    )
  }
  if (type === 'iconText') {
    return (
      <IconTextButton onClick={() => handleClick()} disabled={disabled}>
          <Icon {...iconProps} color='primary' id='btn-icon' />
          <p className='btn-text'>{children}</p>
      </IconTextButton>
    )
  }
})

export default Button;