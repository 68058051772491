import React, { useState, useEffect } from 'react';
import { Autocomplete, Chip, Icon } from '@mui/material';

import Input from '../atoms/Input';
import Text from '../atoms/Text';
import { label, clickableKey, clickableId } from "../../../data/searchComponent.json";

export default function MultipleSearch({ fetch, autocomplete, inputValue, handleInput, page, clearOptions, search }) {
    const [ value, setValue ] = useState([]);
    const [open, setOpen ] = useState(false);
    const [ inputHasValue, setInputHasValue ] = useState(false);

    const optionId = clickableId[page];
    const optionName = clickableKey[page];

    const handleValue = (newValue) => {
        setValue(newValue);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const handleInputChange = (value) => {
        setInputHasValue(Boolean(value));
      
        const charLimit = 3;
        if (value.length >= charLimit) {
            fetch(value);
            setOpen(true);
        }
        else {
            handleClose();
            clearOptions();
        }
    }

    const handleNameState = () => {
        const stringValue = value.map( val => val[optionName]);
        handleInput(value, stringValue);
    }

    useEffect(() => {
        setValue(inputValue);
    }, [inputValue]);

  return (
    <Autocomplete
      multiple
      filterOptions={(x) => x}
      autoComplete
      forcePopupIcon={false}
      clearOnBlur={false}
      filterSelectedOptions
      value={value}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          if (!inputHasValue) {
            handleNameState();
            search(value);
          }
        }
      }}
      size="small"
      sx={{
        '& .MuiAutocomplete-popupIndicatorOpen': {
          transform: 'rotate(0deg)'
        }
      }}
      options={autocomplete}
      onChange={(event, newValue) => handleValue(newValue)}
      onInputChange={(event, newInputValue) => handleInputChange(newInputValue)}
      renderInput={(params) => 
        <Input
          {...params}
          sx={{
            '& .MuiFilledInput-root': {
              paddingTop: '5px',
              paddingBottom: '5px !important',
            }
          }} 
          placeholder={!value.length ? label[page]: ''}
          icon={{base: 'fa-solid', class: 'fa-magnifying-glass'}}
          iconClick={() => { handleNameState(); search(value); }}
        />
      }
      open={open}
      onClose={handleClose}
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option[optionName]
      }
      renderOption={(props, option) => {
        return (
          <li {...props} id={option[optionId]}>
            <Text>
              {option[optionName]}
            </Text>
          </li>
        );
      }}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            color='primary'
            label={option[optionName]}
            {...getTagProps({ index })}
            deleteIcon={<Icon baseClassName='fa-solid' className='fa-circle-xmark' color='#FFF' />}
          />
        ))
      }
    />
  )
}
