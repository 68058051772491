import React, { useEffect, useState } from 'react';
import { Autocomplete } from '@mui/material';
import Input from '../atoms/Input';
import Text from '../atoms/Text';
import { label, clickableKey, clickableId } from "../../../data/searchComponent.json";

export default function Search({ fetch, autocomplete, inputValue, handleInput, page, clearOptions, search }) {
  const [ value, setValue ] = useState(null);
  const [open, setOpen ] = useState(false);

  const optionId = clickableId[page];
  const optionName = clickableKey[page];
  const handleValue = (newValue) => {
    setValue(newValue);
  }

  const handleClose = () => {
    setOpen(false);
  }

  useEffect(() => {
    if (inputValue === '' ) {
      setValue(null);
      handleInput('');
    }
    // eslint-disable-next-line
  }, [inputValue]);

  const handleInputChange = (value) => {
    const charLimit = (page === 'personnel' || page === 'people' ? 1000 : 3);

    handleInput(value);
    if (value.length >= charLimit && inputValue.length >= charLimit ) {
      fetch(value);
      setOpen(true);
    }
    else {
      handleClose();
      clearOptions();
    }
  }

  return (
    <Autocomplete
      filterOptions={(x) => x}
      autoComplete
      forcePopupIcon={false}
      clearOnBlur={false}
      inputValue={inputValue}
      filterSelectedOptions
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          search();
        }
      }}
      value={value}
      size="small"
      sx={{
        '& .MuiAutocomplete-popupIndicatorOpen': {
          transform: 'rotate(0deg)'
        }
      }}
      options={autocomplete}
      onChange={(event, newValue) => handleValue(newValue)}
      onInputChange={(event, newInputValue) => handleInputChange(newInputValue)}
      renderInput={(params) => 
        <Input
          {...params}
          sx={{
            '& .MuiFilledInput-root': {
              paddingTop: '5px',
              paddingBottom: '5px !important',
            }
          }} 
          placeholder={label[page]}
          icon={{base: 'fa-solid', class: 'fa-magnifying-glass'}}
          iconClick={() => search()}
        />
      }
      open={open}
      onClose={handleClose}
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option[optionName]
      }
      renderOption={(props, option) => {
        return (
          <li {...props} id={option[optionId]}>
            <Text>
              {option[optionName]}
            </Text>
          </li>
        );
      }}
    />
  )
}
