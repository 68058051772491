import React, { useEffect, useState } from "react";
import { Grid, Stack } from "@mui/material";
import { useParams } from "react-router";

import { useApp } from "../AppContext";
import { useCompany } from "../components/features/company/context";
import { useProperty } from "../components/features/property/context";
import useDocumentTitle from "../components/features/hooks/useDocumentTitle";

// import Chart from 'react-apexcharts';

import Button from "../components/UI/atoms/Button";
import Link from "../components/UI/atoms/Link";
import Loading from "../components/UI/atoms/Loading";
import RenderHtml from "../components/UI/atoms/RenderHtml";
import Table from "../components/UI/atoms/Table";
import Text from "../components/UI/atoms/Text";

import Card from "../components/UI/molecules/Card";
import Chart from "../components/UI/molecules/Chart";
import List from "../components/UI/molecules/List";
import DountChart from "../components/UI/molecules/DountChart";
import NewsReleases from "../components/UI/features/NewsReleases";
import Financial from "../components/UI/features/companySections/Financial";

import { emptyObject } from "../lib/emptyObject";
import { formatStockList } from "../lib/formatStockList";
import { filterStockTable } from "../lib/filterStockTable";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

const StyledLabel = ({ children }) => {
  return (
    <Text
      fontFamily="ff1"
      fontSize="1rem"
      fontWeight="500"
      textTransform="uppercase"
    >
      {children}
    </Text>
  );
};

const StyledInfo = ({ children }) => {
  return (
    <Text fontFamily="ff2" fontSize="0.875rem" color="#808183">
      {children}
    </Text>
  );
};

export default function CompanyProfile() {
  const {
    handleProfile,
    profile,
    graphId,
    handleGraphId,
    handleAddToPortfolio,
    setFolders,
    resultsFolder
  } = useCompany();
  const { handleSelectProperty } = useProperty();
  const { isLoading } = useApp();
  const { companyId } = useParams();
  const [pageTitle, setPageTitle] = useState("TNM Marco Polo");

    const [opendi, setOpen] = React.useState(false);
    const [mfolderid, setmFolderId] = React.useState("");
    const [mprofileLink, setmProfileLink] = React.useState("");
    const [misPortfolioItem, setmIsPortfolio] = React.useState("");


  useEffect(() => {
    (async () => {
      handleProfile(companyId);
      setFolders(1);
    })();
    // eslint-disable-next-line
  }, [companyId]);

  useEffect(() => {
    if (!emptyObject(profile)) {
      setPageTitle(`${profile.generalProfile.displayName} | TNM Marco Polo`);
    }
  }, [profile]);

  useDocumentTitle(pageTitle);

  const generalProfile = { ...profile.generalProfile };
  delete generalProfile["companyId"];
  delete generalProfile["displayName"];

    const handleClickOpen = async (mprofileLinkk, misPortfolioItemm) => {
        setmProfileLink(mprofileLinkk);
        setmIsPortfolio(misPortfolioItemm);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleAddToPortfolionew = () => {
        handleAddToPortfolio(mfolderid, mprofileLink, misPortfolioItem);
        handleClose();
    }

  const handlePrint = () => {
    window.print();
  };

  const handleReportChange = (url) => {
    const newTab = window.open(url, "_blank", "noopener,noreferrer");
    if (newTab) newTab.opener = null;
  };

  return (
    <div className="profile company-profile print">
      {emptyObject(profile) || isLoading ? (
        <Loading />
      ) : (
        <React.Fragment>
          <Card
            styles={{
              "& .MuiCardContent-root": {
                display: "flex",
                justifyContent: "space-between",
              },
            }}
            className="title"
          >
            <Text fontFamily="ff1" fontSize="2.25rem" fontWeight="500">
              {profile.generalProfile.displayName}
            </Text>
            <div className="profile-actions">
              {profile.isPortfolioItem ? (
                <Button
                  type="iconText"
                  iconProps={{
                    baseClassName: "fa-solid",
                    className: "fa-bookmark",
                  }}
                  disabled
                >
                  Added to my portfolio
                </Button>
              ) : (
                <Button
                  handleClick={() => handleClickOpen(companyId, profile.isPortfolioItem)}
                  type="iconText"
                  iconProps={{
                    baseClassName: "fa-regular",
                    className: "fa-bookmark",
                  }}
                >
                  Add to my Portfolio
                </Button>
              )}
              <Button
                handleClick={handlePrint}
                type="iconText"
                iconProps={{
                  baseClassName: "fa-regular",
                  className: "fa-print",
                }}
              >
                Print
              </Button>
              <Button
                handleClick={() => handleReportChange("/contactus")}
                type="iconText"
                iconProps={{
                  baseClassName: "fa-solid",
                  className: "fa-pencil",
                }}
              >
                Suggest an Edit
              </Button>
            </div>
          </Card>

            {
                resultsFolder &&
                <Dialog open={opendi} onClose={handleClose}>
                    <DialogTitle>Select Company List</DialogTitle>
                    <DialogContent>
                        <FormControl>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                            >
                                {resultsFolder.map((element, index) => {
                                    return (
                                        <FormControlLabel key={`element-${index}`} value={element.folderId} control={< Radio />} label={element.folderName} onClick={(e) => setmFolderId(e.target.value)} />
                                    )
                                })}
                            </RadioGroup>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button onClick={() => handleAddToPortfolionew()}>Save</Button>
                    </DialogActions>
                </Dialog>
            }
          <Stack
            direction={{ xs: "column", sm: "column", md: "row" }}
            spacing={3}
          >
            <Card header="Profile" styles={{ width: "100%", margin: 0 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                  <StyledLabel>Location / Mailing</StyledLabel>
                  <StyledInfo>
                    <RenderHtml string={generalProfile.location} />
                  </StyledInfo>
                </Grid>
                <Grid item container spacing={2} xs={12} sm={12} md={12} lg={6}>
                  <Grid item xs={12}>
                    <StyledLabel>Web</StyledLabel>
                    <Link
                      external
                      link={generalProfile.website}
                      openTab
                      type="table contact"
                    >
                      {generalProfile.website}
                    </Link>
                  </Grid>
                  <Grid item xs={12}>
                    {generalProfile.email && (
                      <React.Fragment>
                        <StyledLabel>Email</StyledLabel>
                        <Link
                          external
                          link={`mailto:${generalProfile.email}`}
                          type="table contact"
                        >
                          {generalProfile.email}
                        </Link>
                      </React.Fragment>
                    )}
                  </Grid>
                </Grid>
                <Grid item container spacing={2} xs={12} sm={12} md={12} lg={6}>
                  <Grid item xs={12} sm={6}>
                    <StyledLabel>Phone</StyledLabel>
                    <StyledInfo>{generalProfile.phone}</StyledInfo>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <StyledLabel>Fax</StyledLabel>
                    <StyledInfo>{generalProfile.fax}</StyledInfo>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StyledLabel>Toll-free</StyledLabel>
                  <StyledLabel>{generalProfile.tollFree}</StyledLabel>
                </Grid>
              </Grid>
            </Card>

            <Card header="Stock" styles={{ width: "100%" }} padded={false}>
              {profile.stockList.length ? (
                <Table table={filterStockTable(profile.stockList, "stock")} />
              ) : (
                <React.Fragment></React.Fragment>
              )}
            </Card>
          </Stack>
          <Accordion className="mt-4">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                <b>STOCK CHART</b>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                {graphId.value ? (
                  <Chart
                    graphId={graphId}
                    stocks={formatStockList(profile.stockList)}
                    handleStock={handleGraphId}
                    currency={profile.stockList.find(
                      (stock) => stock.graphId === graphId.value
                    )}
                    priceTable={filterStockTable(
                      profile.stockList,
                      "priceTable",
                      graphId
                    )}
                    fundamentalTable={filterStockTable(
                      profile.stockList,
                      "fundamentalTable",
                      graphId
                    )}
                  />
                ) : graphId.value === 0 ? (
                  <React.Fragment></React.Fragment>
                ) : (
                  <Loading />
                )}
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion className="mt-4">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                <b>OWNED PROPERTY SUMMARY</b>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Grid container spacing={3}>
                <Grid item xs={12} md={8} >
                  <Typography className="cptable">
                    {!!profile.ownedPropertySummaryList.length && (
                      <Card padded={false} >
                        <List
                          list={profile.ownedPropertySummaryList}
                          type="property"
                          page="properties"
                          select={handleSelectProperty}
                          removebookmark={true}
                        />
                      </Card>
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                {!!profile.ownedPropertySummaryList.length && (  
                <Card>
                    <DountChart datalist={profile.ownedPropertySummaryList} />
                  </Card>
                  )}
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>

          <Accordion className="mt-4">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                <b>OPTIONED PROPERTY SUMMARY</b>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                {!!profile.optionedPropertySummaryList.length && (
                  <Card>
                    <List
                      list={profile.optionedPropertySummaryList}
                      type="property"
                      page="properties"
                      select={handleSelectProperty}
                      removebookmark={true}
                    />
                  </Card>
                )}
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion className="mt-4">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                <b>FINANCIAL</b>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                {!!profile.financial.length && (
                  <Financial table={profile.financial} />
                )}
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion className="mt-4">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                <b>EXECUTIVES AND DIRECTORS</b>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                {!!profile.executiveAndDirectorList.length && (
                  <Card>
                    <Table table={profile.executiveAndDirectorList} />
                  </Card>
                )}
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion className="mt-4">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                <b>NEWS RELEASES</b>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                {profile.latestInformation && (
                  <Card padded={false} styles={{ width: "100%", margin: 0 }}>
                    <NewsReleases list={profile.latestInformation} />
                  </Card>
                )}
              </Typography>
            </AccordionDetails>
          </Accordion>
        </React.Fragment>
      )}
    </div>
  );
}
