import React, { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Table from './Table'
import Datasetbox from './Datasetbox'
import TopQueries from './TopQueries'
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Loading from '../../components/UI/atoms/Loading';
export default function TopButtons({ searchResult, clearChat, isLoadingDis, displayMessage, handleClickTocopy, resultHading, topQueriesData, dataSetResultScope }) {

    const [showComponent1, setShowComponent1] = useState(true);
    const [showComponent2, setShowComponent2] = useState(false);
    const [showComponent3, setShowComponent3] = useState(false);

    const [queryfilferDropDown, setQueryfilferDropDown] = useState("Commodity");

    

    const toggleComponent1 = () => {
        setShowComponent1(true);
        setShowComponent2(false);
        setShowComponent3(false);
    };

    const toggleComponent2 = () => {
        setShowComponent2(true);
        setShowComponent1(false);
        setShowComponent3(false);
    };

    const toggleComponent3 = () => {
        setShowComponent3(true);
        setShowComponent1(false);
        setShowComponent2(false);
    };

    

    useEffect(() => {
        if (searchResult.length > 0) {
            toggleComponent1();
        }
        if (clearChat === true) {
            toggleComponent1();
        }
        
    }, [clearChat])

    const Component1 = () => {
        if (searchResult.length > 0) {
            return <><span style={{ display: 'block', fontSize: '20px', marginBottom: '15px', color: '#747272', }}>{resultHading}</span> <Table searchResult={searchResult} /></>;
        }
        else {
            return <><Grid style={{ position: 'absolute', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '24px', color: '#B5B4B4', zIndex: '0' }}>{displayMessage}
            </Grid><Table searchResult={searchResult} /></>;
        }
    }

    const Component2 = () => {

        return (
           
            <Grid container spacing={3}>
                { dataSetResultScope &&
                    <>
                    <Datasetbox dataSetResult={dataSetResultScope.company} dataTitle="Company" />
                    <Datasetbox dataSetResult={dataSetResultScope.property} dataTitle="Property" />
                    <Datasetbox dataSetResult={dataSetResultScope.propertyReserves} dataTitle="Property Reserves" />
                    <Datasetbox dataSetResult={dataSetResultScope.commodity} dataTitle="Commodity" />
                </>
                }
                </Grid> 
        )
        
        
    }

    const Component3 = () => {
        if (queryfilferDropDown == "Property") {

        }
        return <div >
          
            <Grid style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Select
                    className='selectdropdonw'
                    name='author'
                    defaultValue="a268f3e7-621b-4a29-ae93-d150a6c91509"
                    label='Select'
                    value={queryfilferDropDown}
                    MenuProps={{
                        disableScrollLock: true,
                    }}
                    onChange={(e) => setQueryfilferDropDown(e.target.value)}
                    >
                    {topQueriesData && topQueriesData.commodity.length > 0 && <MenuItem value="Commodity" >Commodity</MenuItem>}
                    {topQueriesData && topQueriesData.company.length > 0 && <MenuItem value="Company" >Company</MenuItem>}
                    {topQueriesData && topQueriesData.property.length > 0 && <MenuItem value="Property" >Property</MenuItem>}
                    {topQueriesData && topQueriesData.marketCap.length > 0 && <MenuItem value="Market Cap" >Market cap</MenuItem>}
                    {topQueriesData && topQueriesData.propertyReserves.length > 0 && <MenuItem value="Property Reserves" >Property Reserves</MenuItem>}

                </Select>

            </Grid>

          
            { topQueriesData &&
                <TopQueries handleClickTocopy={handleClickTocopy} topQueries={queryfilferDropDown == "Company" ? topQueriesData.company : queryfilferDropDown == "Property" ? topQueriesData.property : queryfilferDropDown == "Drill Result" ? topQueriesData.drillResult : queryfilferDropDown == "Market Cap" ? topQueriesData.marketCap : queryfilferDropDown == "Commodity" ? topQueriesData.commodity : queryfilferDropDown == "Property Reserves" ? topQueriesData.propertyReserves : ""} />
                }
          
        </div>;
    }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <Button variant="contained" onClick={toggleComponent1} className={showComponent1 ? "Appheaderblue active" : "Appheaderblue"} fullWidth style={{ height: '70px', borderRadius: '10px' }}>
            CURRENT
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Button variant="contained" onClick={toggleComponent2} className={showComponent2 ? "Appheaderblue active" : "Appheaderblue"} fullWidth style={{ height: '70px', borderRadius: '10px' }}>
            CURATED DATASET
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Button variant="contained" onClick={toggleComponent3} className={showComponent3 ? "Appheaderblue active" : "Appheaderblue"} fullWidth style={{ height: '70px', borderRadius: '10px' }}>
            TOP QUERIES
          </Button>

        </Grid>


      </Grid>

          
      <Grid className="whitebg" > 
              <Grid style={{ minHeight: '59vh' , position:'relative', overflowY:'scroll'  }}  className='facnyscroll'>
                  
                  {isLoadingDis && <div style={styles.loader}><Loading color="#1976d2" size={30} /></div>}
        {showComponent1 && <Component1 />}
        {showComponent2 && <Component2 />}
        {showComponent3 && <Component3 />}
        </Grid> 
        </Grid>
    </>
  )
}

const styles = {
    loader: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '50px',
        fontWeight: 'bold',
        top: '50%',
        position: 'absolute',
        left: '50%',
        transform: 'translate(-50 %, -50 %)',
    },
};







