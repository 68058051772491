import React, { useState } from 'react';
import { Icon, Dialog as DialogMUI, List, ListItem, styled, Tooltip } from '@mui/material';
import Button from '../atoms/Button';
import Link from '../atoms/Link';
import Text from '../atoms/Text';

const StyledMenuItem = (props) => {
    return (
      <ListItem
      {...props}
      sx={{
        padding: '0',
        margin: 0,
        borderBottom: '1px solid #ECEBEB',
        ':last-of-type': {
          borderBottom: 'none'
        },
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        ':hover': {
            backgroundColor: '#ecebeb'
        },
        '&.disabled': {
            pointerEvents: 'none',
            cursor: 'default',
            '& *': {
                color: '#8b8b8b'
            }
        }
      }}
      >
        {props.children}
      </ListItem>
    )
}

const StyledDiv = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'flex-end'
}))

export default function Dialog({options, menuIcon, tooltip = ''}) {
    const [ open, setOpen ] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    }
    const handleClose = () => {
        setOpen(false);
    }

    return (
        <div>
            <Tooltip title={tooltip}>
                <Button
                    type='icon'
                    placement={`${open && 'open-menu'} nav float-btn`}
                    handleClick={handleOpen}
                    iconProps={{
                        baseClassName:menuIcon.base,
                        className:menuIcon.className,
                        sx: {...menuIcon.sx, color: menuIcon.color, fontSize: '1.5rem'}
                    }}
                />
            </Tooltip>
            <DialogMUI
                fullScreen
                open={open}
                onClose={handleClose}
            >
                <StyledDiv>
                    <Button
                        type='icon'
                        handleClick={handleClose}
                        iconProps={{
                            baseClassName: "fa-solid",
                            className: "fa-xmark",
                            sx: {color: '#413F3F', fontSize: '1.5rem'}
                        }}
                    />
                </StyledDiv>
                <List sx={{p: '20px'}}>
                    {options.map((option, index) => (
                        <StyledMenuItem button className={option.type} key={index}>
                            {option.link ? (
                                <Link {...option} external={option.external} link={option.link} openTab={option.external} type={`${option.type} menu`}>
                                    {option.text}
                                    <Icon baseClassName={option.icon.base} className={option.icon.className} sx={{ ...option.sx, fontSize: '1rem' }} />
                                </Link>
                            ) : (
                                <div style={{width: '100%'}} onClick={() => {option.onClick()}}>
                                    <Text padding='6px' fontWeight='400' textTransform='uppercase' fontFamily='ff1' width='100%' display='flex' justifyContent='space-between' alignItems='center'>
                                        {option.text}
                                        <Icon baseClassName={option.icon.base} className={option.icon.className} sx={{ ...option.sx, fontSize: '1rem' }} />
                                    </Text>
                                </div>
                            )}
                        </StyledMenuItem>
                    ))}
                </List>
            </DialogMUI>
        </div>
    )
}
