import React, { useRef, useEffect, useState } from 'react';

export default function RenderHtml({string}) {
  const spanRef = useRef(null);
  const [html,] = useState(string);

  useEffect(() => {
    if (spanRef.current) {
      spanRef.current.innerHTML = html;
    }
    // eslint-disable-next-line
  }, [spanRef.current, html]);

    return (
        <span ref={spanRef}></span>
    )
}