import React from 'react';
import { Chip } from '@mui/material';
import { styled } from '@mui/material';
import Text from '../atoms/Text';
import { chips } from '../../../data/filtersLayout.json';
import ResponsiveLayout from '../atoms/ResponsiveLayout';

const StyledChip = styled(Chip)(() => ({
    color: '#FFF',
    fontSize: 15,
    textTransform: 'uppercase',
    fontFamily: "'Montserrat', sans-serif",
    '& .MuiChip-deleteIcon': {
        color: '#FFF',
    }
}))

export default function Chips({ filters, handleDelete, page }) {

    const sortFilters = () => {
        if (page === 'company' || page === 'properties') {
            filters.sort((a, b) => chips[page].indexOf(a.id) - chips[page].indexOf(b.id));
        }
        return filters;
    }

    return (
        <ResponsiveLayout className='filters-selected' container>
            <ResponsiveLayout item xs={12} sm={12} contained='md'>
                {filters && filters.length !== 0 && (
                    <Text marginRight='10px'>Results for: </Text>
                )}
            </ResponsiveLayout>
            <ResponsiveLayout item container sm={12} contained='md'>
                {filters && sortFilters(filters).map(item => (
                    <ResponsiveLayout item key={item.text}>
                        <StyledChip color='primary' id={item.id} label={item.text} onDelete={() => handleDelete(item)} />
                    </ResponsiveLayout>
                ))}
            </ResponsiveLayout>
        </ResponsiveLayout>
    )
}