import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';


export default function StickyHeadTable({ searchResult }) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden', boxShadow:'none' }}>
          {searchResult.length > 0 &&
              <>
        <TableContainer  className='tableontainer'>
        <Table stickyHeader aria-label="sticky table" className='datatbale'>
          <TableHead >
            <TableRow>
            {Object.keys(searchResult[0]).map(key => (
          (key !== 'CompanyId' && key !== 'PropertyId') && (
            <TableCell key={key}>
              {key.replace(/([A-Z])/g, ' $1').trim()}
            </TableCell>
          )
        ))}
            </TableRow>
          </TableHead>
          <TableBody>
          {searchResult
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((item, index) => (
          <TableRow hover role="checkbox" tabIndex={-1} key={index}>
            {Object.entries(item).map(([key, value], i) => {
              if (key === 'CompanyId' || key === 'PropertyId') {
                // Skip rendering these columns
                return null;
              } else if (key === 'CompanyName') {
                // Render CompanyName with hyperlink
                return (
                  <TableCell key={i}>
                    <a href={window.location.origin +`/company/${item.CompanyId}`} target='_blank'>{value}</a>
                  </TableCell>
                );
              } else if (key === 'PropertyName') {
                // Render PropertyName with hyperlink
                return (
                  <TableCell key={i}>
                    <a href={window.location.origin +`/properties/${item.PropertyId}`} target='_blank'>{value}</a>
                  </TableCell>
                );
              } else {
                // Render other cells normally
                const isDate = !isNaN(Date.parse(value));
                if (value === null || value === undefined || value === "") {
                  return <TableCell key={i}>{value}</TableCell>;
                } else {
                  return (
                    <TableCell key={i}>
                      {isDate ? new Date(value).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })
                              : (value.toString().indexOf(',') > -1 ? value.replaceAll(',', ', ') : value)}
                    </TableCell>
                  );
                }
              }
            })}
          </TableRow>
        ))}
          </TableBody>
        </Table>
      </TableContainer>
     
      <TablePagination
        rowsPerPageOptions={[20, 40, 100]}
        component="div"
        count={searchResult.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}       
      />
      </>
       }
    </Paper>
  );
}