const development = [
    'Prospect',
    'Exploration',
    'Advanced Exploration',
    'Scoping',
    'Preliminary Economic Assessment',
    'Prefeasibility',
    'Feasibility',
    'Permitting',
    'Construction',
    'Commissioning',
    'Production',
    'Decommissioning',
    'Closed'
];

const activity = [
    'Active',
    'Temporary Suspension',
    'Care & Maintenance',
    'Stalled'
]

const market = [
    '< US$500 mi',
    'US$500 mi - 1 bi',
    '> US$1 bi'
];

const lists = {development: development, activity: activity, market: market};

export const reorderDropdowns = (dropdown, listType) => {
    let reordered = [];
    lists[listType].map(orderOption => {
        const fullOption = dropdown.find(option => option.name === orderOption);
        reordered.push(fullOption);
        return true;
    })
    return reordered;
}

export const filterUndefined = (dropdown) => {
    let filtered = dropdown.filter(option => option.name !== 'Undefined')
    return filtered;
}