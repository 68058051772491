import React from 'react';
import { Card as MUICard, CardHeader, CardContent, styled } from '@mui/material'
import Text from '../atoms/Text';

const StyledCardHeader = styled(CardHeader)(({theme}) => ({
  padding: '13px 15px 16px ',
  borderBottom: '1px solid #E6E7E8',
  '& .MuiCardHeader-title': {
    color: '#000000'
  }
}))
export default function Card({header, headerAction, styles, padded = true, className, children}) {
  return (
    <MUICard sx={{boxShadow: '1px 1px 6px #00000029;', margin: '12px 0', ...styles }} className={className}>
      {header && (
      <StyledCardHeader
        title={
          <Text fontFamily='ff1' fontSize='1.25rem' fontWeight='600' textTransform='uppercase'>{header}</Text>
        }
        action={headerAction}
      />
      )}
      <CardContent sx={{padding: padded ? '24px !important' : `0 !important`}}>
        {children}
      </CardContent>
    </MUICard>
  )
}
