import React from 'react';
import { Typography } from '@mui/material';

const fontFamilies = {
  'ff1': "'Montserrat', sans-serif",
  'ff2': 'Roboto, sans-serif',
  'ff3': "'Oswald', sans-serif"
}

export default function Text(props) {
  const { type, children, fontFamily, ...other } = props;

  return (
    <Typography variant={type} sx={{ fontFamily: fontFamilies[fontFamily], ...other}}>{children}</Typography>
  )
}
