import axios from 'axios';
import { authGet } from './authMiddleware';
import { membershipUrl } from '../lib/membershipUrl';

export const serviceAuth = async () => {
    return authGet('/api/auth');
}
export const getCachedToken = async token => {
    const url = membershipUrl() + 'api/get-cached-token?redis_key=' + token;
    return await axios.get(url, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}