export const checkboxSelect = (filters, selected) => {
    const newState = filters;
    const id = selected.name || selected.id; 
    newState[id].map(option => {
        if (Array.isArray(selected.value)) {
            const isSelected = selected.value.some(val => val === option.selectedValue);
            option.isSelected = isSelected;
        }else {
            if (option.selectedValue === selected.value || option.portfolioId === parseInt(selected.value)) {
                option.isSelected = !option.isSelected;
            }
        }

        return option;
    });
    return newState;
}