import React, { useEffect } from 'react';
import Cookies from 'universal-cookie';

import { useApp } from '../../../AppContext'
import { getCachedToken } from '../../../services/getUserAuth';
import useQuery from '../../features/hooks/useQuery';

import Loading from '../atoms/Loading';
import AuthWall from './AuthWall';
import { membershipUrl } from '../../../lib/membershipUrl';

const cookies = new Cookies();

export default function AuthWrapper({ children }) {

    const { user, isLoadingAuth, handleUser } = useApp();
    const token = cookies.get('token');
    const query = useQuery();
    const key = query.get('key');

    useEffect(() => {
        if (key) {
            getCachedToken(key).then(data => {
                const expires = new Date();
                expires.setDate(expires.getDate() + 1);
                cookies.set('token', data.data, {expires: expires});
            }).then(() => {
                window.location.href = window.location.origin;
            })
        } else if (token && token !== 'undefined') {
            handleUser();
        }
        // eslint-disable-next-line
    }, []);

    if (key) {
        return (
            <Loading />
        )
    } else if (!token || token === 'undefined') {
        window.location.href = membershipUrl() + 'login?src=' + window.location.host;
        return <div></div>
    }
    if (isLoadingAuth) {
        return (
            <Loading />
        )
    } else if (user.authenticated) {
        if (user.authorized) {
            return (
                <React.Fragment>{children}</React.Fragment>
            )
        } else {
            return (
                <AuthWall />
            )
        }
    }
    else {
        window.location.href = membershipUrl() + 'login?src=' + window.location.host;
        return <div></div>
    }
}