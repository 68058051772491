import React from 'react';
import { Icon, Paper, styled, Table as MUITable, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material'

import Button from '../atoms/Button';
import Input from '../atoms/Input';
import Link from '../atoms/Link';
import Text from '../atoms/Text';
import SortButton from './SortButton';

import { tableData } from '../../../lib/tableData';
import { addSpace } from '../../../lib/addSpace';
import { Alert } from '@mui/material';


const StyledTable = styled(TableContainer)(() => ({
  '& thead th': {
    backgroundColor: '#E6E7E8',
    textTransform: 'uppercase',
  }
}))
const StyledContainer = styled('div')(() => ({
  display: 'flex'
}));


export default function List({ type, listFolder, handlePortfolioFolder }) {

    const [openAlertDelete, setOpenAlertDelete] = React.useState(false);
    const [openAlertDeleteItem, setOpenAlertDeleteItem] = React.useState(false);
    
    const [delfolderid, setDelFolderId] = React.useState("");
    const [delitemtype, setDelItemType] = React.useState("");
    const [delentityid, setDelEntiryId] = React.useState("");

    const handleFolderDelete = (folderid) => {
        setDelFolderId(folderid);
        setOpenAlertDelete(true);
    }

    const handleFolderDeleteFinal = () => {
        const data = {
            "folderId": parseInt(delfolderid)
        }

        handlePortfolioFolder(data);
        setOpenAlertDelete(false);

    }


    if (type === "portfolio") {

        return (
            <>
                {openAlertDelete &&
                    <Alert icon={false} severity="warning" className='alrt'>
                        <span>Are you sure you want to delete the folder?</span>
                        <span>
                            <Button variant="text" onClick={() => handleFolderDeleteFinal()}>Yes</Button>
                            <Button variant="text" onClick={() => setOpenAlertDelete(false)}>No</Button>
                        </span>

                    </Alert>}

                
                {listFolder.map((elementFolder, indexFolder) => {
                    return (
                        <>
                            <div className='customefieldhe'>
                                <Text fontFamily='ff1' color='#fff' fontWeight='700' fontSize='1.15rem'>{elementFolder.folderName}</Text>
                                {elementFolder.folderId === 0 ? "" : <Button
                                    type="icon"
                                    tooltip="Remove Selected"
                                    iconProps={{
                                        sx: { color: "#fff" },
                                        baseClassName: "fa-solid",
                                        className: "fa-trash-can",
                                    }}
                                    handleClick={() => handleFolderDelete(elementFolder.folderId)}
                                />}
                            </div>
                            <StyledTable component={Paper} className={`${type}-page-table`}>
                                <MUITable aria-label="results-table">
                                    <TableBody>
                                        <TableRow><TableCell colSpan={5}><div style={{ padding: "12px 20px", textAlign: 'center' }}><Text>No data saved! Add them to your portfolio</Text></div></TableCell></TableRow>
                                    </TableBody>
                                </MUITable>
                            </StyledTable>
                            
                        </>
                    )
                }
                )}
            </>
        );
    }
}
  
