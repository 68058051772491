import React from 'react'
import { Icon, List, ListItem, styled } from '@mui/material'
import Link from '../atoms/Link';
import Text from '../atoms/Text';

const StyledListItem = styled(ListItem)(() => ({
    borderBottom: '1px solid #E6E7E8',
    '&:last-of-type': {
        borderBottom: 'none'
    }
}))

export default function NewsReleases({ list }) {

    return (
        <List>
            {list.map((item, index) => (
                <StyledListItem
                    key={`release-${index}`}
                    secondaryAction={<Link type='icon' openTab external link={item.url}>
                        <Icon sx={{ color: 'primary.main' }} baseClassName='fa-regular' className='fa-file-lines' />
                    </Link>}
                >
                    <div>
                        <Link type='release' openTab external link={item.url}>{item.title}</Link>
                        <Text fontSize='0.875rem'>{item.description}</Text>
                    </div>
                </StyledListItem>
                
            ))}
        </List>
    );
}
