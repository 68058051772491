export const tableData = (tableData, page) => {
    const data = tableData.map(result => {
      const filtered = {...result};
      delete filtered['gender'];
      delete filtered['age'];
      return filtered;
    })
    
    const headers = Object.keys(data[0])
    .filter(header => !header.includes('Id') && !header.includes('isPortfolioItem'))
    .map(header => {
      let value = header;
      let headerObj = {key: header};
      if (header.includes('peopleName')) {
        value = 'executive';
      } else if (header.includes('Name')) {
        value = header.split('Name')[0];
      }
      else if (header.includes('primaryCommodity')) {
          value = 'Primary Commodity';
      }
      else if (header.includes('commodity')) {
        value = 'Commodity Exposure';
      } else if (page === 'properties' && header.includes('owners')) {
        value = 'companies';
      }
      value = value.split(/(?=[A-Z])/).join(' ');
      headerObj['value'] = value;

      return headerObj;
    });
    const content = data.map(element => {
        let structuredElement = {};
        structuredElement.ids = Object.fromEntries(Object.entries(element)
        .filter(([key]) => key.includes('Id')));
        structuredElement.data = Object.fromEntries(Object.entries(element)
        .filter(([key]) => !key.includes('Id')))
        Object.entries(structuredElement.data)
        .map(([key, entry]) => {
          if (entry !== '' && (key === 'interest' || key === 'ownership' || key === 'optioned')) {
            structuredElement.data[key] = parseInt(entry) + '%';
            return true;
          }
          else {
            structuredElement.data[key] = entry;
            return true;
          }
        })

        return structuredElement;
    });

    if (headers.some(header => header.value === 'executive' || header.value === 'person')) {
      const temp = headers[1]
      headers[1] = headers[0];
      headers[0] = temp;
    }

    return { headers: headers, content: content };
}