import React, { useEffect } from 'react';

import { useApp } from '../AppContext';
import useDocumentTitle from '../components/features/hooks/useDocumentTitle';
import { useMinePersonnel } from '../components/features/mine-personnel/context';

import Loading from '../components/UI/atoms/Loading';
import Listings from '../components/UI/organisms/Listings';

import MinePersonnelFilter from '../components/UI/features/filters/MinePersonnelFilter';
// import { emptyObject } from '../lib/emptyObject';

export default function MinePersonnelListing() {
  const {
    query,
    results,
    resultsFolder,
    searchType,
    handleSearch,
    totalResults,
    removeFilter,
    handlePageSize,
    selectedFilters,
    handlePageNumber,
    handleSelectProperty,
    handleSearchSorted,
    handlePortfolioOnSearch,
    setFolders
  } = useMinePersonnel();
  const { isLoading } = useApp();

  useDocumentTitle('Mine Personnel Search | TNM Marco Polo');

  useEffect(() => {
        (async () => {
            handleSearch();
            setFolders();
        })();
    // eslint-disable-next-line
  }, [searchType]);

  return (
   <div>
      <MinePersonnelFilter />
      { isLoading ? 
        <Loading />
      :
        <Listings
          sortSelected={query.sorting}
          /*addToPortfolio={handlePortfolioOnSearch}*/
          handleSort={handleSearchSorted}
          results={results}
          /*resultsFolder={resultsFolder}*/
          type='properties'
          searchType='personnel'
          select={handleSelectProperty}
          handlePage={handlePageSize}
          pageSize={query.pageSize}
          pageNumber={query.pageNumber}
          filters={selectedFilters}
          handleDelete={removeFilter}
          handlePageNumber={handlePageNumber}
          totalResults={totalResults}
        />
      }
    </div>
  )
}
