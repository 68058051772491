const regularNumberLabels = ['changeAbsolute', 'changePercentage', 'volume', 'beta', 'dividendYield', 'peRatio'];

const tables = {
    'priceTable': [['close', 'open'], ['changeAbsolute', 'high'], ['changePercentage', 'low'], ['volume']],
    'fundamentalTable': [['oneYearHigh', 'eps'], ['oneYearLow', 'peRatio'], ['dividend', 'dividendYield'], ['marketCap', 'beta']]
}

// Code reference from stackoverflow
// https://stackoverflow.com/a/36734774
function setFormatNumber (value) {

    // 12 Zeroes for Trillions
    return Math.abs(Number(value)) >= 1.0e+12
    ? "$" + (Math.round((Math.abs(Number(value)) / 1.0e+12) * 100) / 100) + " T"

    // Nine Zeroes for Billions
    : Math.abs(Number(value)) >= 1.0e+9
    ? "$" + (Math.round((Math.abs(Number(value)) / 1.0e+9) * 100) / 100) + " B"

    // Six Zeroes for Millions 
    : Math.abs(Number(value)) >= 1.0e+6
    ? "$" + (Math.round((Math.abs(Number(value)) / 1.0e+6) * 100) / 100) + " M"

    // Three Zeroes for Thousands
    : Math.abs(Number(value)) >= 1.0e+3
    ? "$" + (Math.round((Math.abs(Number(value)) / 1.0e+3) * 100) / 100) + " K"

    // Hundreds
    : "$" + Math.abs(Number(value));
}

export const filterStockTable = (data, table, selectedStock) => {
    let filtered = [];
    if (table === 'stock') {
        filtered = data.map(item => {
            const filteredItem = Object.fromEntries(Object.entries(item)
            .filter(([key]) => key ==='symbol' || key === 'exchange' || key === 'graphId'));
            return filteredItem;
        });
    }
    else {
        data
        .filter(item => item.graphId === selectedStock.value)
        .map(item => {
            filtered = tables[table].map(row => {
                const rowArray = row.map(cell => {
                    let structuredRow = {};
                    let value;
                    if (item[cell] === null) {
                        value = '-';
                    }
                    else {
                        const roundNumber = Math.round(item[cell] * 100) / 100;
                        value = regularNumberLabels.some(label => label === cell) ? roundNumber.toLocaleString('en-US') : setFormatNumber(roundNumber);
                    }
                    structuredRow[cell] = value;
                    return structuredRow;
                })
                return rowArray;
            });
            
            return true;
        })
    }
    return filtered;
}